export const usersEn = {
  access: 'Access',
  account: 'Account',
  accountInformation: 'Account Information',
  active: 'Active',
  'Alphabetic characters only': 'Alphabetic characters only',
  anErrorOccurredWhileModifyingTheUser:
    'An error occurred while modifying the user.',
  'Assigned roles': 'Assigned Roles',
  'City issue': 'City Issue',
  'City of birth': 'City of Birth',
  cityOfBirth: 'City of Birth',
  'Confirm password': 'Confirm Password',
  'Country issue': 'Country Issue',
  CreateUser: 'Create User',
  'Date of issue': 'Date of Issue',
  dateOfBirth: 'Date of Birth',
  deleteUser: 'Delete user',
  'Department issue': 'Department Issue',
  'Department of birth': 'Department of Birth',
  departmentOfBirth: 'Department of Birth',
  disable: 'Disable',
  disabled: 'Disabled',
  disableUser: 'Disable User',
  EditUser: 'Edit User',
  Email: 'Email',
  enable: 'Enable',
  enabled: 'Enabled',
  enableUser: 'Enable User',
  enterUserNameEmailIdentification: 'Enter user name, email or identification',
  feminine: 'Feminine',
  'First name': 'First Name',
  'First surname': 'First Surname',
  firstName: 'First Name',
  firstSurname: 'First Surname',
  gender: 'Gender',
  Gender: 'Gender',
  Identification: 'Identification',
  'Identification information': 'Identification Information',
  'Identification number': 'Identification Number',
  'Identification type': 'Identification Type',
  'In this email you will receive your security codes and other communications':
    'In this email you will receive your security codes and other communications.',
  isRequired: 'is required',
  Item: 'Item',
  male: 'Male',
  modifyPassword: 'Modify password',
  'Name or identification': 'Name or identification',
  'Name rol': 'Name Rol',
  nameRol: 'Role Name',
  next: 'Next',
  numberMobile: 'Cell Phone Number ',
  originators: 'Originators',
  Password: 'Password',
  permissionsForOriginators: 'Permissions for Originators',
  'Personal information': 'Personal Information',
  'Place of birth': 'Place of Birth',
  placeOfBirth: 'Country of Birth ',
  previous: 'Previous',
  registerUser: 'Register User',
  roles: 'Roles',
  Roles: 'Roles',
  'Second name': 'Second Name',
  'Second surname': 'Second Surname',
  secondSurname: 'Second Surname',
  sorryCouldntModifyAdmin: "Sorry, couldn't modify admin.",
  sorryTheUserCannotCreated: 'Sorry, the user cannot be created.',
  sureWantDisableUser: 'Are you sure you want to disable this user?',
  sureWantEnableUser: 'Are you sure you want to enable this user?',
  sureWantDeleteUser: 'Are you sure you want to delete this user?',
  theEmailWillReceiveCodeChangePassword:
    'The email will receive a code to change the password.',
  theUserWasSuccessfullyCreated: 'The user was successfully created.',
  theUserWasSuccessfullyModified: 'The user was successfully modified.',
  thisActionRestrictedSuperUser: 'This action is restricted to a SuperUser.',
  userAlreadyInUse: 'The email already exists.',
  userDoesNotExistsOrNotProper:
    "The user doesn't exists or was not properly created.",
  userName: 'User Name',
  userNotFound: 'User not found',
  users: 'Users',
  Users: 'Users',
  UsersList: 'Users List',
  userSuccessfullyDeleted: 'User successfully deleted',
  userSuccessfullyDisabled: 'User successfully disabled',
  userSuccessfullyEnabled: 'User successfully enabled',
  Welcome: 'Welcome',
  withoutOriginator: 'Without Originator',
  theUserDoesNotExistNotWellCreated:
    'The user does not exist or is not well created.',
  Undefined: 'Undefined',
  mailAlreadyExists: 'Mail already exists.',
  identificationNumberAlreadyExists: 'Identification Number Already Exists',
  creationDate: 'Creation Date',
};
export const usersEs = {
  access: 'Accesos',
  account: 'Cuenta',
  accountInformation: 'Información de la cuenta',
  active: 'Activo',
  'Alphabetic characters only': 'Alphabetic characters only',
  anErrorOccurredWhileModifyingTheUser:
    'Ocurrió un error al modificar el usuario',
  'Assigned roles': 'Roles asignados',
  Birthdate: 'Cumpleaños',
  'City issue': 'Ciudad de expedición',
  'City of birth': 'Ciudad de nacimiento',
  cityOfBirth: 'Ciudad de nacimiento',
  'Confirm password': 'Confirmar contraseña',
  'Country issue': 'País de expedición',
  CreateUser: 'Crear Usuario',
  'Date of issue': 'Fecha de expedición',
  dateOfBirth: 'Fecha de nacimiento',
  deleteUser: 'Eliminar usuario',
  'Department issue': 'Departamento de expedición',
  'Department of birth': 'Departamento de nacimiento',
  departmentOfBirth: 'Departamento de nacimiento',
  disable: 'Deshabilitar',
  disabled: 'Deshabilitado',
  disableUser: 'Deshabilitar usuario',
  EditUser: 'Editar usuario',
  Email: 'Correo electrónico',
  enable: 'Habilitar',
  enabled: 'Habilitado',
  enableUser: 'Habilitar usuario',
  enterUserNameEmailIdentification:
    'Ingrese el nombre del usuario, correo electrónico o identificación',
  feminine: 'Femenino',
  // Form
  'First name': 'Primer nombre',
  'First surname': 'Primer apellido',
  firstName: 'Primer nombre',
  firstSurname: 'Primer apellido',
  gender: 'Género',
  Gender: 'Género',
  Identification: 'Identificación',
  'Identification information': 'Información de identificación',
  'Identification number': 'Número de identificación',
  'Identification type': 'Tipo de identificación',
  'In this email you will receive your security codes and other communications':
    'En este Email recibirás tus códigos de seguridad y otras comunicaciones',
  isRequired: 'es obligatorio',
  Item: 'Elemento',
  male: 'Masculino',
  modifyPassword: 'Modificar contraseña',
  'Name or identification': 'Nombre o identificación',
  'Name rol': 'Nombre del rol',
  nameRol: 'Nombre del rol',
  next: 'Siguiente',
  numberMobile: 'Número de celular',
  originators: 'Originadores',
  Password: 'Contraseña',
  permissionsForOriginators: 'Permisos para originadores',
  'Personal information': 'Información personal',
  'Place of birth': 'Pais de nacimiento',
  placeOfBirth: 'Pais de nacimiento',
  previous: 'Anterior',
  registerUser: 'Registrar usuario',
  roles: 'Roles',
  Roles: 'Roles',
  'Second name': 'Segundo nombre',
  'Second surname': 'Segundo apellido',
  secondSurname: 'Segundo apellido',
  sorryCouldntModifyAdmin: 'Lo siento, no pude modificar el administrador.',
  sorryTheUserCannotCreated: 'Lo siento, no se puede crear el usuario.',
  sureWantDisableUser: '¿Seguro que desea deshabilitar este usuario?',
  sureWantEnableUser: '¿Seguro que desea habilitar este usuario?',
  sureWantDeleteUser: '¿Seguro que desea eliminar este usuario?',
  theEmailWillReceiveCodeChangePassword:
    'Al correo electrónico llegará un código para cambiar la contraseña.',
  theUserWasSuccessfullyCreated: 'El usuario fue creado exitosamente.',
  theUserWasSuccessfullyModified: 'El usuario fue modificado con éxito.',
  thisActionRestrictedSuperUser:
    'Esta acción está restringida a un superusuario.',
  userAlreadyInUse: 'El correo ya existe.',
  userDoesNotExistsOrNotProper:
    'El usuario no existe o no se creó correctamente.',
  userName: 'Nombre de usuario',
  userNotFound: 'Usuario no encontrado',
  users: 'Usuarios',
  Users: 'Usuarios',
  UsersList: 'Lista de Usuarios',
  userSuccessfullyDeleted: 'Usuario eliminado exitosamente',
  userSuccessfullyDisabled: 'Usuario deshabilitado exitosamente',
  userSuccessfullyEnabled: 'Usuario habilitado con éxito',
  Welcome: 'Bienvenido',
  withoutOriginator: 'Sin originador',
  theUserDoesNotExistNotWellCreated:
    'El usuario no existe o no está bien creado.',
  Undefined: 'Indefinido',
  mailAlreadyExists: 'El correo ya existe.',
  identificationNumberAlreadyExists: 'El número de identificación ya existe.',
  creationDate: 'Fecha de creación',
};
