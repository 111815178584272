export const SET_SELECTED_CAMPAING = 'SET_SELECTED_CAMPAING';
export const SET_LIST_CAMPAIGNS = 'SET_LIST_CAMPAIGNS';

export function setListCampaigns(payload) {
  return {
    type: SET_LIST_CAMPAIGNS,
    payload
  };
}

export function setSelectedCampaign(campaign) {
  return {
    type: SET_SELECTED_CAMPAING,
    payload: campaign
  };
}

export const createCampaign = () => async () => {};

export const buildBody = (campaign) => {
  const body = {
    id: campaign?.id ?? 0,
    isActive: campaign?.isActive,
    campaignName: campaign?.campaignName,
    campaignType: campaign?.campaignType,
    message: campaign?.message,
    description: campaign?.description,
    idTemplateSendinblue: campaign?.idTemplateSendinblue,
    automaticCampaign: campaign?.automaticCampaign,
    executionFrequency: campaign?.executionFrequency,
    initialDate: campaign?.startDate,
    endDate: campaign?.endDate,
    contactFrequency: campaign?.contactFrequency,
    isSms: campaign?.isSms,
    isEmail: campaign?.isEmail,
    ruleCampaign: {
      campaignRuleLoanProducts: campaign?.campaignRuleLoanProducts,
      campaignRuleOriginators: campaign?.campaignRuleOriginators,
      campaignRuleCities: campaign?.campaignRuleCities,
      campaignRuleRegions: campaign?.campaignRuleRegions,
      ageRange: campaign.ageRange.join(','),
      genderType: campaign?.genderType.join(','),
      occupationType: campaign?.occupationType.join(','),
      channel: campaign?.channel.join(','),
      creditStatus: campaign?.creditStatus.join(','),
      applicationStatus: campaign?.applicationStatus.join(','),
      daysCreditRepaid: campaign?.daysCreditRepaid,
      daysEnrollmentActivity: campaign?.daysEnrollmentActivity,
      customerType: campaign?.customerType,
      enrollmentStatus: campaign?.enrollmentStatus,
      applicationsApproved: campaign?.applicationsApproved
    }
  };
  return body;
};
