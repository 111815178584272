import masterService from 'src/services/masterService';
import { finishLoading, setMessageSnackbar, startLoading } from './uiActions';

export const SET_MASTER_DATA = '@master/set-master-data';
export const SET_COMPANY_TYPES = '@master/set-company-types';

export const getMasterData = () => {
  return async dispatch => {
    dispatch(startLoading());
    try {
      const response = await masterService.GetMasterData();
      dispatch(setMasterData(response.data));
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }

  };
};

export const setMasterData = (data) => ({
  type: SET_MASTER_DATA,
  payload: data
});

export const getCompanyTypes = () => {
  return async dispatch => {
    dispatch(startLoading());
    try {
      const response = await masterService.getCompanyTypes();
      const companyTypes = {
        isSetted:true,
        companyTypes:response
      }
      dispatch(setCompanyTypes(companyTypes));
    } catch (e) {
      const companyTypes = {
        isSetted:true,
        companyTypes:[]
      }
      dispatch(setCompanyTypes(companyTypes));
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }

  };
};

export const setCompanyTypes = (companyTypes) => ({
  type: SET_COMPANY_TYPES,
  payload: companyTypes
});

