import { API_BASE_URL, routes } from 'src/config/';
import axios from 'src/utils/axios';
import axiosService from './axiosService';

class LoanProductService {
  GetLoanProducts = (OriginatorId) =>
    new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .get(`${API_BASE_URL}/api/LoanProducts/by-originator/${OriginatorId}`, {
          headers: {
            Authorization: AuthStr,
            appClientId: localStorage.getItem('appClientId')
          }
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  GetLoanProductsByFilter = (Filter) =>
    new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .get(`${API_BASE_URL}/api/LoanProducts/by-filters/${Filter}`, {
          headers: {
            Authorization: AuthStr,
            appClientId: localStorage.getItem('appClientId')
          }
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  CreateOrModifyLoanProduct = (loanGeneralDetails) =>
    new Promise((resolve, reject) => {
      const data = loanGeneralDetails;
      const AuthStr = axios.defaults.headers.common.Authorization;
      if (!loanGeneralDetails.id) {
        axios
          .post(`${API_BASE_URL}/api/LoanProducts`, data, {
            headers: {
              Authorization: AuthStr,
              'Content-Type': 'application/json',
              appClientId: localStorage.getItem('appClientId')
            }
          })
          .then((response) => {
            if (response.data) {
              resolve(response.data);
            } else {
              reject(response.data.error);
            }
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        axios
          .put(`${API_BASE_URL}/api/LoanProducts`, data, {
            headers: {
              Authorization: AuthStr,
              'Content-Type': 'application/json',
              appClientId: localStorage.getItem('appClientId')
            }
          })
          .then((response) => {
            if (response.data) {
              resolve(response.data.message);
            } else {
              reject(response.data.error);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    });

  DeleteLoanProduct = (loanId) =>
    new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .delete(`${API_BASE_URL}/api/LoanProducts/${loanId}`, {
          headers: {
            Authorization: AuthStr,
            'Content-Type': 'application/json',
            appClientId: localStorage.getItem('appClientId')
          }
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data.message);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  GetLoanProductById = (loanProductId) =>
    new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .get(`${API_BASE_URL}/api/LoanProducts/${loanProductId}`, {
          headers: {
            Authorization: AuthStr,
            appClientId: localStorage.getItem('appClientId')
          }
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  GetLoanProductChargeTypeRange = (loanProductId, chargeTypeId) =>
    new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .get(
          `${API_BASE_URL}/api/Loans/GetLoanProductChargeTypeRange/${loanProductId}/${chargeTypeId}`,
          {
            headers: { Authorization: AuthStr },
            appClientId: localStorage.getItem('appClientId')
          }
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  DeleteLoanProductRange = (rangeId, chargeId) =>
    new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .delete(
          `${API_BASE_URL}/api/Loans/DeleteLoanProductRange/${rangeId}/${chargeId}`,
          {
            headers: {
              Authorization: AuthStr,
              'Content-Type': 'application/json',
              appClientId: localStorage.getItem('appClientId')
            }
          }
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data.message);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  GetLoanProductValidations = async (loanProductId) =>
    axiosService.get(`${routes.loanProduct}/validations/${loanProductId}`);

  CreateOrUpdateLoanProductValidation = async (validation) =>
    axiosService.put(`${routes.loanProduct}/validations`, validation);

  GetLoanProductTasks = async (loanProductId) =>
    axiosService.get(`${routes.loanProduct}/tasks/${loanProductId}`);

  CreateOrUpdateLoanProductTask = async (task) =>
    axiosService.put(`${routes.loanProduct}/tasks`, task);

  DeleteLoanProductValidation = async (loanProductValidationId) =>
    axiosService.delete(
      `${routes.loanProduct}/validations/${loanProductValidationId}`
    );

  UpdateOrCreateLoanProductParameterTask = async (parameterTask) =>
    axiosService.put(`${routes.loanProduct}/tasksParameter`, parameterTask);

  GetLoanProductTasksParameter = async (loanProductId) =>
    axiosService.get(`${routes.loanProduct}/tasksParameter/${loanProductId}`);
}

const loanProductService = new LoanProductService();

export default loanProductService;
