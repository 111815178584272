import 'moment/locale/es';
import React, { useState } from 'react';
import { Router, useLocation } from 'react-router-dom';
import Routes, {
  UNPROTECTED_ROUTES,
  UNPROTECTED_ROUTES_WITH_PARAMS,
} from 'src/Routes';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import Auth from 'src/components/Auth';
import CookiesNotification from 'src/components/CookiesNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import ModalSessionExpired from 'src/views/auth/Components/modalSessionExpired';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ScrollReset from 'src/components/ScrollReset';
import SettingsNotification from 'src/components/SettingsNotification';
import { SnackbarProvider } from 'notistack';
import { createBrowserHistory } from 'history';
import { createMuiTheme } from 'src/theme';
import moment from 'moment';
import { useSelector } from 'react-redux';
import useSettings from 'src/hooks/useSettings';
import { useTranslation } from 'react-i18next';
import setYupLocale from './i18n/translations/yupLocaleMessages';

const history = createBrowserHistory();

function App() {
  const location = useLocation();
  const { pathname } = location;
  const { settings } = useSettings();
  const { i18n } = useTranslation();
  const isAuthorized = useSelector((state) => state.account.isAuthorized);

  const [openModal, setOpenModal] = useState(false);

  moment.locale(i18n.language);
  setYupLocale();

  const unprotectedRouteWithParams = UNPROTECTED_ROUTES_WITH_PARAMS.some((v) => pathname.includes(v));

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createMuiTheme(settings)}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SnackbarProvider maxSnack={1}>
            <Router history={history}>
              <Auth>
                <ScrollReset />
                <GoogleAnalytics />
                {/* <CookiesNotification /> */}
                {/* <SettingsNotification /> */}
                <Routes />
                {!UNPROTECTED_ROUTES.includes(pathname)
                  && !unprotectedRouteWithParams
                  && !isAuthorized && (
                    <ModalSessionExpired
                      onClose={setOpenModal}
                      open={!openModal}
                    />
                  )}
              </Auth>
            </Router>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
