import { SET_MASTER_DATA, SET_COMPANY_TYPES } from '../actions/masterActions';

const initialState = {
  employmentSituations: [],
  contractTypes: [],
  professions: [],
  companyTypes: {
    isSetted:false,
    companyTypes:[]
  }
};
export const masterReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case SET_MASTER_DATA:
      return {
        ...state,
        ...action.payload
      };
    case SET_COMPANY_TYPES:
      return {
        ...state,
        companyTypes:action.payload
      }
    default:
      return state;
  }
};
