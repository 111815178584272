import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Dialog,
  Grid,
  TextField,
  Typography,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { generateCodesPassword } from 'src/store/actions/usersActions';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  helperText: {
    textAlign: 'rigth',
    marginRight: 0,
  },
  authorBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  actionButton: {
    marginLeft: '12px',
  },
  textField: {
    marginBottom: '20px',
  },
  marginButtom: {
    marginBottom: '17px',
  },
}));

export default function VerifyEmailRecoveryPassword({ open, onClose }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleSubmitFormik = async (email) => {
    if (email !== '') {
      dispatch(generateCodesPassword(email, true));
    } else {
      enqueueSnackbar(`${t('email')} ${t('isRequired')}`, {
        variant: 'warning',
      });
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={() => {
        onClose(false);
      }}
      open={open}
    >
      <div className={clsx(classes.root)}>
        <Formik
          enableReinitialize
          initialValues={{
            email: '',
          }}
          onSubmit={async (values) => handleSubmitFormik(values.email)}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email(t('invalidFormat'))
              .max(255)
              .required(`${t('email')} ${t('isRequired')}`),
          })}
        >
          {({
 errors, handleBlur, handleChange, touched, values,
}) => (
            <Form>
              <Grid container>
                <Box display="flex" flexDirection="column" my={1} width="100%">
                  <Typography variant="h3">{t('verifyYourEmail')}</Typography>
                  <Typography
                    className={classes.textField}
                    color="textSecondary"
                    variant="h5"
                  >
                    {t('toResetPassword')}
                  </Typography>
                </Box>
                <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    autoFocus
                    className={classes.marginButtom}
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email ? errors.email : ''}
                    label={t('email')}
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                <Box className="w100" display="flex" justifyContent="center">
                  <Button
                    onClick={() => {
                      onClose(false);
                    }}
                    variant="outlined"
                  >
                    {t('Cancel')}
                  </Button>
                  <Box mr={2} />
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {t('verify')}
                  </Button>
                </Box>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </Dialog>
  );
}
