import axiosService from "src/services/axiosService";
import routes from 'src/config/api.routes';

class LocationsService {
  GetCountries = async () => await axiosService.get(routes.countries);

  GetRegions = async () => await axiosService.get(routes.regions);
  GetRegionsByCountry = async countryId => await axiosService.get(`${routes.regions}/countryCode/${countryId}`);

  GetCities = async () => await axiosService.get(routes.cities);
  GetCitiesByRegion = async regionId => await axiosService.get(`${routes.cities}/regionCode/${regionId}`);
}

const locationsService = new LocationsService();
export default locationsService;