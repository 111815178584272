import routes from 'src/config/api.routes';
import axiosService from './axiosService';

class OriginatorService {
  GetPaymentMethods = (originatorId) => axiosService.get(`${routes.originatorPaymentMethods}/${originatorId}`);

  GetIntegrations = () => axiosService.get(`${routes.availableIntegration}`);

  GetOrganizationSetting = () => axiosService.get(`${routes.organizationSetting}`);

  GetParametersAvailable = () => axiosService.get(`${routes.availableParameters}`);

  getOriginatorById = (id) => axiosService.get(`${routes.originators}/${id}`);

  GetAllOriginators = () => axiosService.get(routes.originators);

  getOriginators = async (search = '') => {
    const response = await axiosService.get(
      `${routes.originators}/getUserOriginator?search=${search}`,
    );
    return response;
  };

  createOriginator = (originatorDTO) => axiosService.post(routes.originators, JSON.stringify(originatorDTO));

  modifyOriginator = (originatorDTO) => axiosService.put(
      `${routes.originators}/${originatorDTO.Id}`,
      JSON.stringify(originatorDTO),
    );

  deleteOriginator = (originatorId) => axiosService.delete(`${routes.originators}/${originatorId}`);

  GetPlaidParameters = async () => {
    const response = await axiosService.get(routes.getPlaidParameters);
    return response.data?.result;
  };

  SavePlaidParameters = (plaidParameters) => axiosService.post(routes.savePlaidParameters, plaidParameters);

  GetACHQParameters = async () => {
    const response = await axiosService.get(routes.getACHQParameters);
    return response.data?.result;
  };

  SaveACHQParameters = (plaidParameters) => axiosService.post(routes.saveACHQParameters, plaidParameters);

  GetStripeParameters = async () => {
    const response = await axiosService.get(routes.getStripeParameters);
    return response.data?.result;
  };

  SaveStripeParameters = (stripeParameters) => axiosService.post(routes.saveStripeParameters, stripeParameters);

  GetOriginatorsxCustomer = (entityId) => axiosService.get(`${routes.customerOriginators}/${entityId}`);

  CreateOriginatorxCustomer = (newCustomerOriginatorDTO) => axiosService.post(
      `${routes.clients}/AddCustomer`,
      newCustomerOriginatorDTO,
    );

  DeleteOriginatorxCustomer = (id) => axiosService.delete(`${routes.clients}/DeleteCustomerOriginator/${id}`);

  GetDocusignParameters = async () => axiosService.get(`${routes.docusign}`);

  CreateOrUpdateDocusignParameters = async (dParams) => axiosService.post(`${routes.docusign}`, dParams);
}

const originatorService = new OriginatorService();

export default originatorService;
