import routes from 'src/config/api.routes';
import axiosService from './axiosService';

class LoanTransactionService {
  GetLoanTransactions = (loanId) =>
    axiosService.get(`/api/LoanTransactions/transactionsByLoanId/${loanId}`);

  DisburseLoan = async (disbTxDto) =>
    axiosService.post(
      `${routes.transactions}/CreateLoanDisbursement`,
      disbTxDto
    );

  ReverseLoanTransaction = async (loanTransactionId, comment) =>
    axiosService.delete(
      `${routes.transactions}/${loanTransactionId}/${comment}`
    );

  SetTransactionStatus = async (loanTransactionId, status) => {
    const putData = JSON.stringify(status);
    return axiosService.put(
      `${routes.transactions}/set_status/${loanTransactionId}`,
      putData
    );
  };

  GetLoanTransactionByStatus = (date) =>
    axiosService.get(`/api/LoanTransactions/transactionsByStatus/${date}`);
}

const loanTransactionService = new LoanTransactionService();

export default loanTransactionService;
