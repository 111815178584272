export const countryEn = {
    countriesName: {
        ad: 'Andorra',
        ae: 'United Arab Emirates',
        af: 'Afghanistan',
        ag: 'Antigua and Barbuda',
        ai: 'Anguilla',
        al: 'Albania',
        am: 'Armenia',
        an: 'Netherlands Antilles',
        ao: 'Angola',
        aq: 'Antarctica',
        ar: 'Argentina',
        as: 'American Samoa',
        at: 'Austria',
        au: 'Australia',
        aw: 'Aruba',
        ax: 'Azerbaijan',
        az: 'Azerbaijan',
        ba: 'Bosnia and Herzegovina',
        bb: 'Barbados',
        bd: 'Bangladesh',
        be: 'Belgium',
        bf: 'Burkina Faso',
        bg: 'Bulgaria',
        bh: 'Bahrain',
        bi: 'Burundi',
        bj: 'Benin',
        bl: 'Saint Barthelemy',
        bm: 'Bermuda',
        bn: 'Brunei',
        bo: 'Bolivia',
        br: 'Brazil',
        bs: 'Bahamas',
        bt: 'Bhutan',
        bv: 'Bouvet Island',
        bw: 'Botswana',
        by: 'Belarus',
        bz: 'Belize',
        ca: 'Canada',
        cc: 'Cocos Islands',
        cf: 'Central African Republic',
        cg: 'Congo',
        ch: 'Switzerland',
        ci: "Côte d'Ivoire",
        ck: 'Cook Islands',
        cl: 'Chile',
        cm: 'Cameroon',
        cn: 'China',
        co: 'Colombia',
        cr: 'Costa Rica',
        cu: 'Cuba',
        cv: 'Cape Verde',
        cx: 'Christmas Islands',
        cy: 'Cyprus',
        cz: 'Czech Republic',
        de: 'Germany',
        dj: 'Djibouti',
        dk: 'Denmark',
        dm: 'Dominica',
        do: 'Dominican Republic',
        dz: 'Algiers',
        ec: 'Ecuador',
        ee: 'Estonia',
        eg: 'Egypt',
        eh: 'Eritrea',
        er: 'Eritrea',
        es: 'Spain',
        et: 'Ethiopia',
        fi: 'Finland',
        fj: 'Fiji',
        fk: 'Falkland Islands',
        fm: 'Micronesia',
        fo: 'Faroe Islands',
        fr: 'France',
        ga: 'Gabon',
        gb: 'Grenada',
        gd: 'Grenada',
        ge: 'Georgia',
        gf: 'French Guiana',
        gg: 'Guernsey',
        gh: 'Ghana',
        gi: 'Gibraltar',
        gl: 'Greenland',
        gm: 'Gambia',
        gn: 'Guinea',
        gp: 'Guadeloupe',
        gq: 'Equatorial Guinea',
        gr: 'Greece',
        gs: 'South Georgia and South Sandwich Islands',
        gt: 'Guatemala',
        gu: 'Guam',
        gw: 'Guinea-Bissau',
        gy: 'Guyana',
        hk: 'Hong Kong',
        hm: 'Heard and McDonald Islands',
        hn: 'Honduras',
        hr: 'Croatia',
        ht: 'Haiti',
        hu: 'Hungary',
        id: 'Indonesia',
        ie: 'Ireland',
        il: 'Israel',
        im: 'Isle of Man',
        in: 'India',
        io: 'British Indian Ocean Territory',
        iq: 'Iraq',
        ir: 'Iran',
        is: 'Iceland',
        it: 'Italy',
        je: 'Jersey',
        jm: 'Jamaica',
        jo: 'Jordan',
        jp: 'Japan',
        ke: 'Kenya',
        kg: 'Kyrgyzstan',
        kh: 'Cambodia',
        ki: 'Kiribati',
        km: 'Comoros',
        kn: 'Saint Kitts and Nevis',
        kp: 'North Korea',
        kr: 'South Korea',
        kw: 'Kuwait',
        ky: 'Cayman Islands',
        kz: 'Kazakhstan',
        la: 'Laos',
        lb: 'Lebanon',
        lc: 'Saint Lucia',
        li: 'Liechtenstein',
        lk: 'Sri Lanka',
        lr: 'Liberia',
        ls: 'Lesotho',
        lt: 'Lithuania',
        lu: 'Luxembourg',
        lv: 'Latvia',
        ly: 'Libya',
        ma: 'Morocco',
        mc: 'Monaco',
        md: 'Moldova',
        me: 'Montenegro',
        mg: 'Madagascar',
        mh: 'Marshall Islands',
        mk: 'Macedonia',
        ml: 'Mali',
        mm: 'Myanmar',
        mn: 'Mongolia',
        mo: 'Macao',
        mq: 'Martinique',
        mr: 'Mauritania',
        ms: 'Montserrat',
        mt: 'Malta',
        mu: 'Mauritius',
        mv: 'Maldives',
        mw: 'Malawi',
        mx: 'Mexico',
        my: 'Malaysia',
        mz: 'Mozambique',
        na: 'Namibia',
        nc: 'New Caledonia',
        ne: 'Niger',
        nf: 'Norfolk Islands',
        ng: 'Nigeria',
        ni: 'Nicaragua',
        nl: 'Netherlands',
        no: 'Norway',
        np: 'Nepal',
        nr: 'Nauru',
        nu: 'Niue',
        nz: 'New Zealand',
        om: 'Oman',
        pa: 'Panama',
        pe: 'Peru',
        pf: 'French Polynesia',
        pg: 'Papua New Guinea',
        ph: 'Philippines',
        pk: 'Pakistan',
        pl: 'Poland',
        pm: 'Saint Pierre and Miquelon',
        pn: 'Pitcairn Islands',
        pr: 'Puerto Rico',
        ps: 'Palestine',
        pt: 'Portugal',
        pw: 'Palau Islands',
        py: 'Paraguay',
        qa: 'Qatar',
        re: 'Reunion',
        ro: 'Romania',
        rs: 'Serbia and Montenegro',
        ru: 'Russia',
        rw: 'Rwanda',
        sa: 'Saudi Arabia',
        sb: 'Solomon Islands',
        sc: 'Seychelles',
        sd: 'Sudan',
        se: 'Sweden',
        sg: 'Singapore',
        sh: 'St. Helena',
        si: 'Slovenia',
        sj: 'Svalbard and Jan Mayen Islands',
        sk: 'Slovakia',
        sl: 'Sierra Leone',
        sm: 'San Marino',
        sn: 'Senegal',
        so: 'Somalia',
        sr: 'Suriname',
        st: 'Sao Tome and Principe',
        sv: 'El Salvador',
        sy: 'Syria',
        sz: 'Swaziland',
        tc: 'Turks and Caicos Islands',
        td: 'Chad',
        tf: 'French Southern Territories',
        tg: 'Togo',
        th: 'Thailand',
        tj: 'Tajikistan',
        tk: 'Tokelau',
        tl: 'Timor-Leste',
        tm: 'Turkmenistan',
        tn: 'Tunisia',
        to: 'Tonga',
        tr: 'Turkey',
        tt: 'Trinidad and Tobago',
        tv: 'Tuvalu',
        tw: 'Taiwan',
        tz: 'Tanzania',
        ua: 'Ukraine',
        ug: 'Uganda',
        us: 'United States of America',
        uy: 'Uruguay',
        uz: 'Uzbekistan',
        va: 'Saint Vincent and the Grenadines',
        vc: 'Saint Vincent and the Grenadines',
        ve: 'Venezuela',
        vg: 'Virgin Islands, British Virgin Islands',
        vi: 'Virgin Islands, United States of America',
        vn: 'Vietnam',
        vu: 'Vanuatu',
        wf: 'Wallis and Futuna',
        ws: 'Samoa',
        ye: 'Yemen',
        yt: 'Mayotte',
        za: 'South Africa'
    }
}

export const countryEs = {
    countriesName: {
        ad: 'Andorra',
        ae: 'Emiratos Árabes Unidos',
        af: 'Afganistán',
        ag: 'Antigua y Barbuda',
        ai: 'Anguila',
        al: 'Albania',
        am: 'Armenia',
        an: 'Antillas Neerlandesas',
        ao: 'Angola',
        aq: 'Antártida',
        ar: 'Argentina',
        as: 'Samoa Americana',
        at: 'Austria',
        au: 'Australia',
        aw: 'Aruba',
        ax: 'Islas Áland',
        az: 'Azerbaiyán',
        ba: 'Bosnia y Herzegovina',
        bb: 'Barbados',
        bd: 'Bangladesh',
        be: 'Bélgica',
        bf: 'Burkina Faso',
        bg: 'Bulgaria',
        bh: 'Bahréin',
        bi: 'Burundi',
        bj: 'Benin',
        bl: 'San Bartolomé',
        bm: 'Bermudas',
        bn: 'Brunéi',
        bo: 'Bolivia',
        br: 'Brasil',
        bs: 'Bahamas',
        bt: 'Bhután',
        bv: 'Isla Bouvet',
        bw: 'Botsuana',
        by: 'Belarús',
        bz: 'Belice',
        ca: 'Canadá',
        cc: 'Islas Cocos',
        cf: 'República Centro-Africana',
        cg: 'Congo',
        ch: 'Suiza',
        ci: 'Costa de Marfil',
        ck: 'Islas Cook',
        cl: 'Chile',
        cm: 'Camerún',
        cn: 'China',
        co: 'Colombia',
        cr: 'Costa Rica',
        cu: 'Cuba',
        cv: 'Cabo Verde',
        cx: 'Islas Christmas',
        cy: 'Chipre',
        cz: 'República Checa',
        de: 'Alemania',
        dj: 'Yibuti',
        dk: 'Dinamarca',
        dm: 'Domínica',
        do: 'República Dominicana',
        dz: 'Argel',
        ec: 'Ecuador',
        ee: 'Estonia',
        eg: 'Egipto',
        eh: 'Sahara Occidental',
        er: 'Eritrea',
        es: 'España',
        et: 'Etiopía',
        fi: 'Finlandia',
        fj: 'Fiji',
        fk: 'Islas Malvinas',
        fm: 'Micronesia',
        fo: 'Islas Faroe',
        fr: 'Francia',
        ga: 'Gabón',
        gb: 'Reino Unido',
        gd: 'Granada',
        ge: 'Georgia',
        gf: 'Guayana Francesa',
        gg: 'Guernsey',
        gh: 'Ghana',
        gi: 'Gibraltar',
        gl: 'Groenlandia',
        gm: 'Gambia',
        gn: 'Guinea',
        gp: 'Guadalupe',
        gq: 'Guinea Ecuatorial',
        gr: 'Grecia',
        gs: 'Georgia del Sur e Islas Sandwich del Sur',
        gt: 'Guatemala',
        gu: 'Guam',
        gw: 'Guinea-Bissau',
        gy: 'Guayana',
        hk: 'Hong Kong',
        hm: 'Islas Heard y McDonald',
        hn: 'Honduras',
        hr: 'Croacia',
        ht: 'Haití',
        hu: 'Hungría',
        id: 'Indonesia',
        ie: 'Irlanda',
        il: 'Israel',
        im: 'Isla de Man',
        in: 'India',
        io: 'Territorio Británico del Océano Índico',
        iq: 'Irak',
        ir: 'Irán',
        is: 'Islandia',
        it: 'Italia',
        je: 'Jersey',
        jm: 'Jamaica',
        jo: 'Jordania',
        jp: 'Japón',
        ke: 'Kenia',
        kg: 'Kirguistán',
        kh: 'Camboya',
        ki: 'Kiribati',
        km: 'Comoros',
        kn: 'San Cristóbal y Nieves',
        kp: 'Corea del Norte',
        kr: 'Corea del Sur',
        kw: 'Kuwait',
        ky: 'Islas Caimán',
        kz: 'Kazajstán',
        la: 'Laos',
        lb: 'Líbano',
        lc: 'Santa Lucía',
        li: 'Liechtenstein',
        lk: 'Sri Lanka',
        lr: 'Liberia',
        ls: 'Lesotho',
        lt: 'Lituania',
        lu: 'Luxemburgo',
        lv: 'Letonia',
        ly: 'Libia',
        ma: 'Marruecos',
        mc: 'Mónaco',
        md: 'Moldova',
        me: 'Montenegro',
        mg: 'Madagascar',
        mh: 'Islas Marshall',
        mk: 'Macedonia',
        ml: 'Mali',
        mm: 'Myanmar',
        mn: 'Mongolia',
        mo: 'Macao',
        mq: 'Martinica',
        mr: 'Mauritania',
        ms: 'Montserrat',
        mt: 'Malta',
        mu: 'Mauricio',
        mv: 'Maldivas',
        mw: 'Malawi',
        mx: 'México',
        my: 'Malasia',
        mz: 'Mozambique',
        na: 'Namibia',
        nc: 'Nueva Caledonia',
        ne: 'Níger',
        nf: 'Islas Norkfolk',
        ng: 'Nigeria',
        ni: 'Nicaragua',
        nl: 'Países Bajos',
        no: 'Noruega',
        np: 'Nepal',
        nr: 'Nauru',
        nu: 'Niue',
        nz: 'Nueva Zelanda',
        om: 'Omán',
        pa: 'Panamá',
        pe: 'Perú',
        pf: 'Polinesia Francesa',
        pg: 'Papúa Nueva Guinea',
        ph: 'Filipinas',
        pk: 'Pakistán',
        pl: 'Polonia',
        pm: 'San Pedro y Miquelón',
        pn: 'Islas Pitcairn',
        pr: 'Puerto Rico',
        ps: 'Palestina',
        pt: 'Portugal',
        pw: 'Islas Palaos',
        py: 'Paraguay',
        qa: 'Qatar',
        re: 'Reunión',
        ro: 'Rumanía',
        rs: 'Serbia y Montenegro',
        ru: 'Rusia',
        rw: 'Ruanda',
        sa: 'Arabia Saudita',
        sb: 'Islas Solomón',
        sc: 'Seychelles',
        sd: 'Sudán',
        se: 'Suecia',
        sg: 'Singapur',
        sh: 'Santa Elena',
        si: 'Eslovenia',
        sj: 'Islas Svalbard y Jan Mayen',
        sk: 'Eslovaquia',
        sl: 'Sierra Leona',
        sm: 'San Marino',
        sn: 'Senegal',
        so: 'Somalia',
        sr: 'Surinam',
        st: 'Santo Tomé y Príncipe',
        sv: 'El Salvador',
        sy: 'Siria',
        sz: 'Suazilandia',
        tc: 'Islas Turcas y Caicos',
        td: 'Chad',
        tf: 'Territorios Australes Franceses',
        tg: 'Togo',
        th: 'Tailandia',
        tj: 'Tayikistán',
        tk: 'Tokelau',
        tl: 'Timor-Leste',
        tm: 'Turkmenistán',
        tn: 'Túnez',
        to: 'Tonga',
        tr: 'Turquía',
        tt: 'Trinidad y Tobago',
        tv: 'Tuvalu',
        tw: 'Taiwán',
        tz: 'Tanzania',
        ua: 'Ucrania',
        ug: 'Uganda',
        us: 'Estados Unidos de América',
        uy: 'Uruguay',
        uz: 'Uzbekistán',
        va: 'Ciudad del Vaticano',
        vc: 'San Vicente y las Granadinas',
        ve: 'Venezuela',
        vg: 'Islas Vírgenes Británicas',
        vi: 'Islas Vírgenes de los Estados Unidos de América',
        vn: 'Vietnam',
        vu: 'Vanuatu',
        wf: 'Wallis y Futuna',
        ws: 'Samoa',
        ye: 'Yemen',
        yt: 'Mayotte',
        za: 'Sudáfrica'
    }
}