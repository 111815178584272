import routes from 'src/config/api.routes';
import axiosService from './axiosService';

class OrganizationSettingService {
  GetOrganizationSetting = async () => {
    let response = await axiosService.get(routes.organizationSetting);
    return response.data?.result;
  };

  SaveOrganizationSetting = organizationSetting =>
    axiosService.post(routes.saveOrganizationSetting, organizationSetting);
}

const organizationService = new OrganizationSettingService();
export default organizationService;
