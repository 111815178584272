import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Dialog,
  Divider,
  Link,
  Typography,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { useTranslation } from 'react-i18next';
import LogoTwo from 'src/components/LogoTwo';
import useDashboardByRole from 'src/hooks/useDashboardByRole';
import LoginForm from '../LoginView/LoginForm';
import VerifyEmailRecoveryPassword from './VerifyEmailRecoveryPassword';

const useStyles = makeStyles((theme) => ({
  card: {
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%',
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  icon: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 124,
  },
}));

export default function ModalSessionExpired({ open, onClose }) {
  const classes = useStyles();
  const { t } = useTranslation();
  useDashboardByRole();
  const [openModal, setOpenModal] = useState(false);

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={() => {
        onClose(false);
      }}
      open={open}
    >
      <Box alignItems="center" display="flex" mb={5} />
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Avatar className={classes.icon}>
            <RouterLink to="/">
              <LogoTwo />
            </RouterLink>
          </Avatar>
          <Typography color="textPrimary" variant="h2">
            {t('sessionExpired').toUpperCase()}
          </Typography>
          <Typography color="textSecondary" variant="subtitle1">
            {t('enterDataContinueManagingYourCredits')}
          </Typography>
          <Box mt={3}>
            <Divider />
            <LoginForm />
          </Box>
          <Box my={2}>
            <Typography color="textSecondary" variant="subtitle1">
              <Link
                href="#"
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                {t('forgotYourPassword')}
              </Link>
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <VerifyEmailRecoveryPassword onClose={setOpenModal} open={openModal} />
    </Dialog>
  );
}
