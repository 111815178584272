export const loanRequestEn = {
  'Accept terms and conditions': 'Accept terms and conditions',
  addComment: 'Add a Comment',
  decisionModelTitle: 'Decision model personalized response',
  decisionModelId: 'Decision Model ID',
  NoTransactionsFound: 'No transactions found',
  addCommentOnly: 'Add Comment',
  addField: 'Add Field',
  addFiles: 'Add Files',
  addProduct: 'Add Product',
  allianzInsurance: 'Allianz Insurance',
  amount: 'Amount',
  ANTES: 'BEFORE',
  applyDownPayment: 'Apply Down Payment',
  Approve: 'Approve',
  approveEditLoans: 'Approve',
  areYouSureYouWantDeleteTheRecord:
    'Are you sure you want to delete the record?',
  associatedRequest: 'Associate Application',
  associatedRequests: 'Associated Applications',
  atExpiration: 'Bullet',
  attachedFiles: 'Attached Files',
  attachment: 'Attachments',
  autoInsurance: 'Auto Insurance',
  availableAmount: 'Available Amount',
  axaColpatriaInsurance: 'Axa Colpatria Insurance',
  beforeApprovingLoanRequestYouMustChooseTheTypeRequest:
    'Before approving loan application you must choose the type application.',
  beneficialOwner: 'Onerous Beneficiary',
  beneficiary: 'Beneficiary',
  biweekly: 'Biweekly',
  blackList: 'BlackList',
  blackListDefault: 'NO BLACKLIST RESULT',
  brand: 'Brand',
  cancel: 'Cancel',
  canonMonthlyVehicle: 'Monthly Vehicle Fee',
  capitalizeGracePeriod: 'Capitalize Grace Period',
  category: 'Category',
  changesInRequest: 'Write in detail the changes you made in the application',
  charactersLeft: 'characters Left',
  checkEmailCellPhoneEnterCodesThatWeSendYouSometimesTheMailReachesSpam:
    'Check your email and your cell phone and enter the codes that we send you. Sometimes the mail reaches spam.',
  clean: 'Clean',
  clientNotFound: 'Client not found',
  coDebtor: 'Co-Debtor',
  coDebtorIsNotRegistered: 'The co-debtor is not registered',
  colmenaInsurance: 'Colmena Insurance',
  COMENTARIO: 'COMMENTARY',
  comment: 'Comment',
  commentFirst: 'Complete observations first',
  comments: 'Comments',
  commercialInformation: 'Commercial Info',
  commercialInformationDefault: 'NO INFO COMMERCIAL RESULT',
  connections: 'Connections',
  'Continue to': 'Continue to',
  cosignerIdentificationNumber: 'Cosigner identification number',
  'CREADO EN': 'CREATED IN',
  'Create Loan request': 'Create loan application',
  createdByStr: 'Created by',
  createNewLoanRequest: 'Create new',
  creationDate: 'Creation date',
  customerDocuments: 'Borrower documents',
  'Customized response': 'Customized response experian',
  daysForClosure: 'Days for Closure',
  decisionEngine: 'decision Engine',
  decisionEngineDefault: 'NO DECISION ENGINE RESULT',
  deleteAdditionalInformation: 'Delete additional information',
  deleteProductInformation: 'Delete product information',
  describeSubTitleApprove: 'Describe why the registry should be approved',
  descriptionIsRequired: 'Description is required',
  DESPÚES: 'LATER',
  disbursementDate: 'Disbursement Date',
  docuware: 'Docuware',
  docuwareDefault: 'NO DOCUWARE RESULT',
  dontHavePermissionsCreateRequest:
    'You do not have permission to create applications in this store',
  downPayment: 'Down Payment',
  DueDate: 'Due Date',
  'Edit loan request': 'Edit loan application',
  editAdditionalRecords: 'Edit additional records',
  editField: 'Edit Field',
  EditLoanRequest: 'Edit Loan Application',
  editLoansTitle: 'Approve Loans',
  effectiveDate: 'Effective Date',
  enterAdditionalProducts: 'Enter Additional Product',
  enterAdditionalRecords: 'Enter Additional Records',
  enterCustomerIdNumber: 'Identification',
  entityIdNotFound: 'Entity ID not found',
  equipmentValue: 'Equipment Value',
  errorCreateProductInfo: 'Error create product information',
  errorDeleteProductInfo: 'Error delete product information',
  errorEditProductsInfo: 'Error edit product information',
  errorGetLoanRequest: 'Error get loan application',
  errorObtainsPaymentPlan: 'Error getting payment plan.',
  errorObtainsProductsInfo: 'Error obtains product information',
  exceedsQuota: 'The requested amount exceeds the available quota',
  fileUploadSuccessfully: 'File upload successfully',
  'First modify the status':
    'To make this modification, you must also modify the status of the application, however your comment was stored',
  fourteen: 'Fortnightly',
  fullName: 'Full Name',
  gracePeriod: 'Grace Periods',
  gracePeriodApplyFor: 'Grace Period Apply for',
  gracePeriodNotValid: 'Grace period values are not valid',
  hasGracePeriod: 'Has Grace Period',
  healthInsurance: 'Health Insurance',
  historiaCredito: 'Credit History',
  homeInsurance: 'Home Insurance',
  identification: 'Identification',
  identificationDebtorPrincipal: 'Identification Debtor Principal',
  identificationNotFound: 'Identification not found',
  identificationNumber: 'Identification Number',
  info: 'Info',
  infoAdditionalNotFound: 'Information additional not found',
  infoProductsNotFound: 'Product information not found',
  informationAdditional: 'Additional Information ',
  informationProductInsurances: 'Insurance Information',
  informationProductsVehicle: 'Vehicle Information ',
  insurance: 'Insurance',
  insuranceCompany: 'Insurance Company',
  interestRate: 'Interest Rate',
  'Invalid Model': 'Invalid Model',
  invalidDays: 'Invalid number of days',
  invalidIdProductsInfo: 'Invalid Id product information',
  invalidLoanId: 'Invalid loan Id',
  invalidLoanRequestId: 'Invalid loan application Id.',
  invalidOriginator: 'Invalid Originator',
  invalidProduct: 'Invalid Product',
  invalidQuotas: 'Invalid number of installments',
  invalidRequest: 'Invalid application',
  lifeInsurance: 'Life Insurance',
  'Loan not found': 'Loan not found',
  'Loan request details': 'Loan Application Details',
  'Loan request not found': 'Loan application not found',
  'Loan request payment plan': 'Payment Plan',
  LoanProduct: 'Product',
  loanProductId: 'Loan Product',
  LoanProductParametersNotFound: 'Loan product parameters not found',
  loanRequest: 'Loan Applications',
  loanRequestDetails: 'Loan Application Details',
  loanRequestIdNotFound: 'Loan application not found.',
  loanRequestList: 'Loan Application List',
  logs: 'Logs',
  maxAmount: 'Max Amount',
  maxCreditAmount: 'Maximum credit Amount',
  maxValue: 'Max Value',
  minAmount: 'Min Amount',
  minimumAmount: 'Minimum Amount',
  minvalue: 'Min Value',
  'Modification not selected': 'Modification not selected',
  modificationActivity: 'Modification Activity',
  'Modified by': 'Modified by',
  'Modified loan': 'Modified loan',
  monthly: 'Monthly',
  'More information': 'More Information',
  'More options': 'More Options',
  nameDebtorPrincipal: 'Name Debtor Principal',
  naturalPersonDefault: 'NO NATURAL PERSON RESULT',
  needBankInfo:
    'We need to know the bank information. Go back to the Personal Info and fill in the missing fields',
  needContactInfo:
    'We need to know the contact information. Go back to the Personal Info and fill in the missing fields',
  'New loan request': 'New loan application',
  newLoanRequest: 'New loan application',
  nextDate: 'Next Date',
  'No customized response': 'No customized response',
  'No perfil response': 'No perfil response',
  none: 'None',
  noRecordsFound: 'No Records Found',
  notAllowNegative: 'negative no allowed',
  notificationsCouldNotBeSent: 'Notifications could not be sent.',
  originatorId: 'Originator',
  originatorNotFound:
    'The customer is not registered with the selected originator.',
  outdatedInformation:
    'It has been several months since you last reviewed your information, please check that nothing has changed',
  parameters: 'Parameters',
  requestParameters: 'Key Terms',
  paymentFrequency: 'Payment Frequency',
  PaymentFrequency: 'Payment Frequency',
  paymentInstallments: 'Payment Installments',
  paymentStartDate: 'Payment Start Date',
  PaymentStartDate: 'Payment Start Date',
  paymentTerm: 'Payment Term',
  penalRate: 'EA Penal Rate',
  perfil: 'Profile',
  'Perform scoring': 'Perform Scoring',
  personalAccidentInsurance: 'Personal accident insurance',
  policyName: 'Policy Name',
  policyNumber: 'Policy Number',
  policyType: 'Type of Policy',
  preQualificationStatus: 'Pre-qualification status',
  principal: 'Principal',
  problemsParameters: 'Problems with input parameters NewLoanRequestDTO',
  product: 'Product',
  productInfoDontExists: "Product information doesn't exist",
  productRequiresCoDebtor:
    'The requested product requires co-debtor information',
  productType: 'Product Type',
  prospecta: 'Prospecta',
  prospectaDefault: 'NO PROSPECTA RESULT',
  rate: 'Effective Interest Rate',
  nir: 'Nominal Interest Rate',
  referenceVehicle: 'Vehicle Reference',
  Reject: 'Reject',
  renting: 'Renting',
  'Request date': 'Application Date',
  'Request hour': 'Application Hour',
  'Request successfully created': 'Application successfully created',
  requestAssociated: 'Associated Application',
  requestDate: 'Application Date',
  requestHour: 'Application Hour',
  requestId: 'Loan Application ID',
  requestInitiated: 'Your application has been created and is already started',
  requestSignature: 'Application Signature',
  requestStatus: 'Application Status',
  requestType: 'Type of Application',
  Return: 'Return',
  reviewFilesValidationsLoanRequest: 'Validations have required files',
  reviewValidationsLoanRequest:
    'Validations are required before the application is approved.',
  riskcentersservices: 'Select the information you need to consult',
  runCredit: 'Run Credit',
  save: 'Save',
  saveAndRequestSignature: 'Save and application signature',
  saveEditLoans: 'save',
  scoringValue: 'Scoring Value',
  seePaymentPlan: 'See Payment Plan',
  select: 'Select',
  selectCategoryAndSubcategory:
    'Yoy must select a category and subcategory for these documents',
  signedDocuments: 'Signed Documents',
  solidariaColombiaInsurance: 'Solidatia Colombia Insurance',
  sorryPageOfLoansRequest:
    "We're sorry. It almost never happens to us, but it seems"
    + 'that this time your application has fallen by the wayside! :('
    + 'Check our requirements and other terms to try to identify what went wrong. Remember you can always contact us!',
  sourceInformation: 'Source of Information',
  status: 'Status',
  Status: 'Status',
  subCategory: 'Subcategory',
  'Successful edition. ': 'Successful edition',
  'Successful edition. Notifications not sent':
    'Successful edition. Notifications not sent',
  suraInsurance: 'Sura Insurance',
  term: 'Term',
  termAcceptance: 'T&C Acceptance',
  'The ID contains no information or records':
    'The ID contains no information or records',
  theApplicationCannotBeApproved:
    'The application cannot be approved because there are associated applications that have not yet been approved.',
  theApplicationToWhichAssociatedNotMainApplication:
    'The application to which you want to be associated is not a main application.',
  theApplicationYouWantoJoinDoesNotExist:
    'The application you want to join does not exist.',
  theMainApplicationWhichAssociatedDoesNotExist:
    'The main application to which it is to be associated does not exist',
  'There is already active loan':
    'There is already an active loan for the application',
  'There is already active loan, non-reversed transactions':
    'There is already an active loan for the application with non-reversed transactions',
  theRecordDontPosibleDelete:
    'The record cannot be deleted because there is an approved loan for the customer.',
  theRequestCouldNotBeCreatedPleaseContactUs:
    'The application could not be created, please contact us.',
  theRequestForSignaturesWasSuccessfullySent:
    'The application for signatures was successfully sent.',
  thereWasErrorSendingSignatureRequestPleaseTryAgainLater:
    'There was an error sending the signature application, please try again later.',
  transunion: 'Transunion',
  transUnionAnalysis: 'Análisis TransUnion',
  travelInsurance: 'Travel Insurance',
  typeProduct: 'Product Type',
  uncategorizedDocuments: 'Uncategorized Documents',
  uploadedInformation: 'Uploaded Information',
  validateCapturesFailed:
    'You must complete the photographic record, Captures option.',
  validator: 'Validator',
  validatorDefault: 'NO VALIDATOR RESULT',
  valuePolicyVatIncluded: 'Policy Value Including VAT',
  vehicle: 'Vehicle',
  vinNumber: 'Vin Number',
  'We need the value of interest to modify':
    'We need the value of the interest you wish to modify',
  'We need the value of term to modify':
    'We need the value of the term you wish to modify',
  weekly: 'Weekly',
  youDoNotHavePermissionCreateApplicationsStore:
    'You do not have permission to create applications in this store',
  legalRepresentativeNotFound: 'Legal representative not found',
  recipientsNotFound: 'Recipients not found',
  docusignIntegrationDisabled: 'Docusign integration disabled',
  docusignConnectionFailed: 'Docusign connection failed',
  waived: 'Waived',
  ItisNotPossibleDeleteFileRequestProcess:
    'It is not possible to delete the requested file because the loan request is in process.',
  approveAndSign: 'Approve and Sign',
  paymentInstallmentsMustContainOneNumber:
    'The payment installments must contain at least one number',
  channel: 'Channel',
  decisionModel: 'DECISION ENGINE',
  'We couldnt find any information about your credit history':
    'We couldnt find any information about your credit history',
  notLoanSelected: 'No loan selected',
  fileWasGeneratedSuccessfully: 'File was generated successfully',
  fileWasDelete: 'File was deleted successfully',
  campaignSuccessCreated: 'Campaign successfully created',
  otherBranches: 'Other Branches',
  nominalInterestRate: 'Nominal Interest Rate',
  productDoesNotHavePaymentSequence:
    'The product does not have a payment sequence, you must parameterize it.',
  theRequestWasGeneratedSuccesfully: 'The request was generated succesfully.',
  updateLoanRequestRegister: 'Record update on request.',
  deleteLoanRequestRegister: 'Record delete on request.',
  createLoanRequestRegister: 'Record create on request.',
  selectInterestOption: 'Select a interest option',
};

export const loanRequestEs = {
  NoTransactionsFound: 'No se encontraron transacciones',
  decisionModel: 'MOTOR DE DECISION',
  decisionModelId: 'Id modelo de decisión',
  'Accept terms and conditions': 'Aceptar términos y condiciones',
  addComment: 'Agrega un comentario',
  addCommentOnly: 'Agregar comentario',
  addField: 'Agregar campo',
  addFiles: 'Añadir documentos',
  addProduct: 'Agregar producto',
  allianzInsurance: 'Allianz seguros',
  amount: 'Monto',
  ANTES: 'ANTES',
  applyDownPayment: 'Aplicar pago de cuota inicial',
  Approve: 'Aprobar',
  approveEditLoans: 'aprobar',
  areYouSureYouWantDeleteTheRecord:
    '¿Está seguro de que desea eliminar el registro?',
  associatedRequest: 'Solicitud asociada',
  associatedRequests: 'Solicitudes asociadas',
  atExpiration: 'Al vencimiento',
  attachedFiles: 'Archivos adjuntos',
  attachment: 'Adjuntos',
  autoInsurance: 'Seguro de auto',
  availableAmount: 'Monto disponible',
  axaColpatriaInsurance: 'Axa colpatria seguros',
  beforeApprovingLoanRequestYouMustChooseTheTypeRequest:
    'Antes de aprobar la solicitud de préstamo debe elegir el tipo de solicitud.',
  beneficialOwner: 'Beneficiario oneroso',
  beneficiary: 'Beneficiario',
  biweekly: 'Quincenal',
  blackList: 'Listas de Control',
  blackListDefault: 'SIN RESPUESTA DE LISTAS DE CONTROL',
  brand: 'Marca',
  cancel: 'Cancelar',
  canonMonthlyVehicle: 'Canon mensual del vehículo',
  capitalizeGracePeriod: 'Capitalizar el periodo de gracia',
  category: 'Categoría',
  changesInRequest:
    'Escribe detalladamente los cambios que hiciste en la solicitud',
  charactersLeft: 'Caracteres disponibles',
  checkEmailCellPhoneEnterCodesThatWeSendYouSometimesTheMailReachesSpam:
    'Revisa tu correo y tu celular e ingresa los códigos que te enviamos. Algunas veces el correo llega a spam.',
  clean: 'Limpiar',
  clientNotFound: 'Cliente no encontrado',
  coDebtor: 'Co-Deudor',
  coDebtorIsNotRegistered: 'El codeudor no se encuentra registrado',
  colmenaInsurance: 'Colmena seguros',
  COMENTARIO: 'COMENTARIO',
  comment: 'Comentario',
  commentFirst: 'Completar las observaciones primero',
  comments: 'Comentarios',
  commercialInformation: 'Info Comercial',
  commercialInformationDefault: 'SIN RESPUESTA DE INFO COMERCIAL',
  connections: 'Conexiones',
  'Continue to': 'Continuar',
  cosignerIdentificationNumber: 'Número de identificación del codeudor',
  'CREADO EN': 'CREADO EN',
  'Create Loan request': 'Crear solicitud de préstamo',
  createdByStr: 'Creado por',
  createNewLoanRequest: 'Crear nueva',
  creationDate: 'Fecha de creación',
  customerDocuments: 'Documentos del cliente',
  'Customized response': 'Respuesta personalizada experian',
  daysForClosure: 'Dias para el cierre',
  decisionEngine: 'Motor de decision',
  decisionEngineDefault: 'SIN RESPUESTA DEL MOTOR DE DECISION',
  deleteAdditionalInformation: 'Eliminar información adicional',
  deleteProductInformation: 'Eliminar información del producto',
  describeSubTitleApprove: 'Describa porqué el registro debe ser aprobado',
  descriptionIsRequired: 'La descripción es requerida.',
  DESPÚES: 'DESPÚES',
  disbursementDate: 'Fecha de desembolso',
  docuware: 'Docuware',
  docuwareDefault: 'SIN RESPUESTA DE DOCUWARE',
  dontHavePermissionsCreateRequest:
    'No tienes permisos para crear solicitudes en este comercio',
  downPayment: 'Pago inicial',
  DueDate: 'Fecha de vencimiento',
  'Edit loan request': 'Editar solicitud de préstamo',
  editAdditionalRecords: 'Editar registros adicionales',
  editField: 'Editar campo',
  EditLoanRequest: 'Editar solicitud de préstamo',
  editLoansTitle: 'Aprobar préstamo',
  effectiveDate: 'Fecha de inicio de vigencia',
  enterAdditionalProducts: 'Ingresa producto adicional',
  enterAdditionalRecords: 'Ingresar registros adicionales',
  enterCustomerIdNumber: 'Identificación',
  entityIdNotFound: 'Id de entidad no encontrada',
  equipmentValue: 'Valor del equipo',
  errorCreateProductInfo: 'Error creando información de producto',
  errorDeleteProductInfo: 'Error eliminando la información de producto',
  errorEditProductsInfo: 'Error editando la información del producto',
  errorGetLoanRequest: 'Error al obtener la solicitud de préstamo.',
  errorObtainsPaymentPlan: 'Error al obtener el plan de pago.',
  errorObtainsProductsInfo: 'Error al obtener la información del producto',
  exceedsQuota: 'El monto solicitado supera el cupo disponible',
  fileUploadSuccessfully: 'Carga de archivos exitosa',
  'First modify the status':
    'Para realizar esta modificación, debe modificar también el estado de la solicitud. Sin embargo tu comentario fue almacenado',
  fourteen: 'Catorcenal',
  fullName: 'Nombre completo',
  gracePeriod: 'Periodos de gracia',
  gracePeriodApplyFor: 'Periodo de gracia aplica para',
  gracePeriodNotValid: 'Los valores del periodo de gracia no son válidos',
  hasGracePeriod: 'Tiene periodo de gracia',
  healthInsurance: 'Seguro de salud',
  historiaCredito: 'Historial crediticio',
  homeInsurance: 'Seguro de vivienda',
  identification: 'Identificación',
  identificationDebtorPrincipal: 'Identificación deudor principal',
  identificationNotFound: 'Identificación no encontrada',
  identificationNumber: 'Número de identificación',
  info: 'General',
  infoAdditionalNotFound: 'Información adicional no encontrada',
  infoProductsNotFound: 'La información del producto no se encuentra',
  informationAdditional: 'Información adicional',
  informationProductInsurances: 'Información de productos de seguros',
  informationProductsVehicle: 'Información de productos de vehículos',
  insurance: 'Póliza',
  insuranceCompany: 'Aseguradora',
  interestRate: 'Tasa de interés EA ',
  // Messages Response
  'Invalid Model': 'Modelo inválido',
  invalidDays: 'Número de dias invalido',
  invalidIdProductsInfo: 'Id de producto incorrecto',
  invalidLoanId: 'Id. de préstamo inválido.',
  invalidLoanRequestId: 'Id de solicitud de préstamo incorrecto',
  invalidOriginator: 'Originador invalido',
  invalidProduct: 'Producto invalido',
  invalidQuotas: 'Número de cuotas invalido',
  invalidRequest: 'Solicitud no válida',
  lifeInsurance: 'Seguro de vida',
  'Loan not found': 'Préstamo no encontrado',
  'Loan request details': 'Detalles de solicitud de préstamo',
  'Loan request not found': 'Solicitud de préstamo no encontrada',
  'Loan request payment plan': 'Plan de pagos',
  LoanProduct: 'Producto',
  loanProductId: 'Producto de préstamo',
  LoanProductParametersNotFound:
    'Parámetros del producto de préstamo no encontrado',
  loanRequest: 'Solicitudes de préstamo',
  loanRequestDetails: 'Detalles de solicitud de prestamo',
  loanRequestIdNotFound: 'No se encontró la solicitud de prestamo.',
  loanRequestList: 'Lista de solicitudes de préstamo',
  logs: 'Logs',
  maxAmount: 'Monto máximo',
  maxCreditAmount: 'Monto máximo de crédito',
  maxValue: 'Valor máximo',
  minAmount: 'Monto mínimo',
  minimumAmount: 'Monto mínimo',
  minValue: 'Valor mímino',
  'Modification not selected': 'Modificación no seleccionada',
  modificationActivity: 'Actividad de modificaciones',
  'Modified by': 'Modificado por',
  'Modified loan': 'Préstamo modificado',
  monthly: 'Mensual',
  'More information': 'Mas información',
  'More options': 'Más opciones',
  nameDebtorPrincipal: 'Nombre deudor principal',
  naturalPersonDefault: 'SIN RESPUESTA DE PERSONA NATURAL',
  needBankInfo:
    'Necesitamos conocer la información bancaria. Vuelve a la Info Personal y llena los campos faltantes',
  needContactInfo:
    'Necesitamos conocer la información de contacto. Vuelve a la Info Personal y llena los campos faltantes',
  'New loan request': 'Nueva solicitud de préstamo',
  newLoanRequest: 'Nueva solicitud de préstamo',
  nextDate: 'Siguiente fecha',
  'No customized response': 'Sin respuesta personalizada',
  'No perfil response': 'Sin respuesta de perfil',
  none: 'Ninguno',
  noRecordsFound: 'No se encontraron registros',
  notAllowNegative: 'no se permiten negativos',
  notificationsCouldNotBeSent: 'Las notificaciones no pudieron ser enviadas.',
  originatorId: 'Originador',
  originatorNotFound:
    'El cliente no está registrado con el originador seleccionado.',
  outdatedInformation:
    'Ya han pasado varios meses desde la última vez que revisaste tu información, por favor revisa que no haya cambiado nada',
  parameters: 'Parámetros',
  requestParameters: 'Párametros',
  paymentFrequency: 'Frecuencia de pago',
  PaymentFrequency: 'Frecuencia de pago',
  paymentInstallments: 'Cuotas de pago',
  paymentStartDate: 'Fecha de pago primera cuota',
  PaymentStartDate: 'Fecha de pago primera cuota',
  paymentTerm: 'Plazo de pago',
  penalRate: 'Tasa de mora',
  perfil: 'Perfil',
  'Perform scoring': 'Realizar puntuación',
  personalAccidentInsurance: 'Seguro de accidente personal',
  policyName: 'Nombre de la póliza',
  policyNumber: 'Número de la póliza',
  policyType: 'Tipo de póliza',
  preQualificationStatus: 'Estado de precalificación',
  principal: 'Principal',
  problemsParameters: 'Problemas con parametros de entrada NewLoanRequestDTO',
  product: 'Producto',
  productInfoDontExists: 'La información de producto no existe',
  productRequiresCoDebtor:
    'El producto solicitado requiere información de codeudor',
  productType: 'Tipo de producto',
  prospecta: 'Prospecta',
  prospectaDefault: 'SIN RESPUESTA DE PROSPECTA',
  rate: 'Tasa EA',
  nir: 'Tasa Nominal Anual',
  referenceVehicle: 'Referencia del vehículo',
  Reject: 'Negar',
  renting: 'Renting',
  'Request date': 'Fecha de solicitud',
  'Request hour': 'Hora de solicitud',
  'Request successfully created': 'Solicitud creada con éxito',
  requestAssociated: 'Solicitud asociada',
  requestDate: 'Fecha de solicitud',
  requestHour: 'Hora solicitud',
  requestId: 'ID de la solicitud de préstamo',
  requestInitiated: 'Tu solicitud ha sido creada y ya está Iniciada',
  requestSignature: 'Solicitud de firma',
  requestStatus: 'Estado de la solicitud',
  requestType: 'Tipo de solicitud',
  Return: 'Regresar',
  reviewFilesValidationsLoanRequest:
    'Las validaciones tienen archivos requeridos',
  reviewValidationsLoanRequest:
    'Las validaciones son requeridas antes de aprobar la solicitud.',
  riskcentersservices: 'Selecciona la información que necesitas consultar',
  runCredit: 'Consultar',
  save: 'Guardar',
  saveAndRequestSignature: 'Guardar y solicitar firma',
  saveEditLoans: 'Guardar',
  scoringValue: 'Valor de puntuación',
  seePaymentPlan: 'Ver plan de pagos',
  select: 'Seleccione',
  selectCategoryAndSubcategory:
    'Debes seleccionar una categoría y subcategoría para estos documentos',
  signedDocuments: 'Documentos firmados',
  solidariaColombiaInsurance: 'Aseguradora solidaria de colombia',
  sorryPageOfLoansRequest:
    'Lo sentimos. Casi nunca nos pasa, pero parece que esta vez tu solicitud se ha quedado en el camino!'
    + ' :( Revisa nuestros requisitos y demás términos para tratar de identificar que ha salido mal.'
    + 'Recuerda que siempre puedes contactarnos!',
  sourceInformation: 'Fuente de Información',
  status: 'Estado',
  Status: 'Estado',
  subcategory: 'Subcategoría',
  'Successful edition. ': 'Edición Exitosa',
  'Successful edition. Notifications not sent':
    'Edición exitosa. Notificaciones no enviadas',
  suraInsurance: 'Sura seguros',
  term: 'Plazo',
  termAcceptance: 'Aceptación de T&C',
  'The ID contains no information or records':
    'El ID no contiene información ni registros',
  theApplicationCannotBeApproved:
    'La solicitud no puede ser aprobada porque existen solicitudes asociadas que aún no han sido aprobadas.',
  theApplicationToWhichAssociatedNotMainApplication:
    'La solicitud a la que quiere asociarse no es de tipo principal.',
  theApplicationYouWantoJoinDoesNotExist:
    'La solicitud a la que quieres asociarte no existe.',
  theMainApplicationWhichAssociatedDoesNotExist:
    'la solicitud principal a la que se quiere asociar no existe',
  'There is already active loan':
    'Ya existe un préstamo activo para la solicitud',
  'There is already active loan, non-reversed transactions':
    'Ya existe un préstamo activo para la solicitud con transacciones no reversadas',
  theRecordDontPosibleDelete:
    'El registro no se puede eliminar porque tiene un préstamo aprobado para el cliente.',
  theRequestCouldNotBeCreatedPleaseContactUs:
    'No fue posible crear la solicitud, comunícate con nosotros.',
  theRequestForSignaturesWasSuccessfullySent:
    'La solicitud de firmas fue enviada exitosamente',
  thereWasErrorSendingSignatureRequestPleaseTryAgainLater:
    'Ocurrió un error en el envío de solicitud de firmas, por favor inténtelo mas tarde.',
  transunion: 'Transunion',
  transUnionAnalysis: 'Análisis TransUnion',
  travelInsurance: 'Seguro de viaje',
  typeProduct: 'Tipo de producto',
  uncategorizedDocuments: 'Documentos sin categorizar',
  uploadedInformation: 'Información cargada',
  validateCapturesFailed:
    'Debes completar el registro fotográfico, Opción Capturas.',
  validator: 'Validador',
  validatorDefault: 'SIN RESPUESTA DE VALIDADOR',
  valuePolicyVatIncluded: 'Valor de la póliza Iva incluido',
  vehicle: 'Vehículo',
  vinNumber: 'Número de VIN',
  'We need the value of interest to modify':
    'Necesitamos el valor del interés que deseas modificar',
  'We need the value of term to modify':
    'Necesitamos el valor del plazo que deseas modificar',
  weekly: 'Semanal',
  youDoNotHavePermissionCreateApplicationsStore:
    'No tiene permiso para crear aplicaciones en esta tienda.',
  legalRepresentativeNotFound: 'Representante legal no encontrado',
  recipientsNotFound: 'Destinatarios no encontrados',
  docusignIntegrationDisabled: 'Integración con Docusign desactivada',
  docusignConnectionFailed: 'Fallo en la conexión con Docusign',
  waived: 'Desistido',
  ItisNotPossibleDeleteFileRequestProcess:
    'No es posible eliminar el archivo solicitado porque la solicitud de préstamo está en curso.',
  approveAndSign: 'Aprobar y firmar',
  paymentInstallmentsMustContainOneNumber:
    'Las cuotas de pago deben contener por lo menos un número',
  channel: 'Canal',
  decisionModelTitle: 'Motor de decisión respuesta personalizada',
  'We couldnt find any information about your credit history':
    'No se encontró información sobre su historial de crédito',
  notLoanSelected: 'No se ha seleccionado ningún préstamo',
  fileWasGeneratedSuccessfully: 'El archivo fue generado exitosamente',
  fileWasDelete: 'El archivo fue eliminado exitosamente',
  campaignSuccessCreated: 'La campaña fue creada exitosamente',
  otherBranches: 'Otros ramos',
  nominalInterestRate: 'Tasa Nominal Anual',
  productDoesNotHavePaymentSequence:
    'El producto no tiene secuencia de pagos, debe parametrizarlo.',
  theRequestWasGeneratedSuccesfully:
    'La solicitud fue generada satisfactoriamente.',
  updateLoanRequestRegister: 'Actualizacion de registro en la solicitud.',
  deleteLoanRequestRegister: 'Eliminacion de registro en la solictud.',
  createLoanRequestRegister: 'Creacion de registro en la solicitud.',
  selectInterestOption: 'Selecciona una opción de interés',
};
