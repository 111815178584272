import guarantorService from 'src/services/guarantorService';
import { setSelectedTab } from './companyActions';
import { finishLoading, setMessageSnackbar, startLoading } from './uiActions';
export const SET_GUARANTOR_PERSONAL_INFORMATION = '@guarantor/set-personal-information';
export const SET_FINANCIAL_INFO_ASSETS = '@guarantor/set-financial-info-assets';
export const SET_FINANCIAL_INFO_LIABILITIES = '@guarantor/set-financial-info-liabilities';
export const SET_TOTAL_LIABILITIES = '@guarantor/set-financial-info-total-liabilities';
export const SET_LIABILITIES_CREDIT_CARDS = '@guarantor/set-liabilities-credit-cards';
export const SET_LIABILITIES_UNPAID_TAXES = '@guarantor/set-liabilities-unpaid-taxes';
export const SET_LIABILITIES_LOANS = '@guarantor/set-liabilities-outstanding-personal-loans';
export const SET_LIABILITIES_OTHERS = '@guarantor/set-liabilities-other-liabilities';
export const CLEAN_GUARANTOR_REDUCER = '@guarantor/clean-reducer';

//// Personal Information
export const cleanGuarantorReducer = () => ({
  type: CLEAN_GUARANTOR_REDUCER
});
export const getGuarantor = guarantorId => {
  return async dispatch => {
    dispatch(startLoading());
    try {
      const response = await guarantorService.GetGuarantor(guarantorId);
      dispatch(setGuarantorPersonalInformation(response));
    } catch (error) {
      dispatch(setGuarantorPersonalInformation(null));
    } finally {
      dispatch(finishLoading());
    }
  };
}
export const setGuarantorPersonalInformation = guarantor => {
  return {
    type: SET_GUARANTOR_PERSONAL_INFORMATION,
    personalInformation: guarantor
  };
};
export const CreateGuarantor = (entityId, rpid, body, Redirect) => async dispatch => {
  try {
    dispatch(startLoading());
    const response = await guarantorService.CreateGuarantor(
      entityId,
      rpid,
      body
    );
    dispatch(setSelectedTab('RP'));
    dispatch(setMessageSnackbar(response.message, 'success'));
    rpid === 0 && Redirect();
    rpid !== 0 && dispatch(getGuarantor(rpid));
  } catch (error) {
    dispatch(setMessageSnackbar(error.message, 'error'));
  } finally {
    dispatch(finishLoading());
  }
};
export const deleteBusinessOfRelatedParty = (businessId, rpid) => async dispatch => {
  try {
    dispatch(startLoading());
    const response = await guarantorService.DeleteBusinessOfGuarantor(businessId);
    dispatch(getGuarantor(rpid));
    dispatch(setMessageSnackbar(response.message, 'success'));
  } catch (error) {
    dispatch(setMessageSnackbar(error, 'error'));
  }
  finally {
    dispatch(finishLoading());
  }

}


//// Assets
export const getAssetsOfGuarantor = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(startLoading());
      const guarantorId = getState().guarantor.personalInformation.id;
      const response = await guarantorService.GetAssetsOfGuarantor(guarantorId);
      dispatch(setAssetsOfGuarantor({ loaded: true, ...response }));
    } catch (e) {
      dispatch(setAssetsOfGuarantor({ loaded: true }));
    } finally {
      dispatch(finishLoading());
    }
  };
};
const setAssetsOfGuarantor = assetsDTO => {
  const others = assetsDTO?.relatedPartiesOtherAssets?.map(other => ({
    id: other.id,
    typeOfAsset: other.type,
    value: other.value,
    loanAsset: other.loanSecuredByAsset,
    lenderName: other.lenderName,
    loanCurrentBalance: other.loanCurrentBalance,
    monthlyPayment: other.loanMonthlyPayment
  }));
  const { generalAssets } = assetsDTO;
  let totalAssets = 0;
  generalAssets && (
    totalAssets = generalAssets.cashAmount +
    generalAssets.savingAccountsBalance +
    generalAssets.retirementAccountsBalance +
    generalAssets.receivableAmount +
    generalAssets.totalAutomobile +
    generalAssets.totalOtherAssets +
    generalAssets.totalProperty +
    generalAssets.totalStockAndBonds +
    generalAssets.totalLifeInsurance
  );
  const assets = {
    loaded: assetsDTO.loaded,
    automobiles: assetsDTO.relatedPartiesAssetsAutomobile,
    others,
    lifeInsurance: assetsDTO.relatedPartiesAssetsLifeInsurance,
    realState: assetsDTO?.realStates,
    generalAssets: assetsDTO?.generalAssets,
    stocksAndBonds: assetsDTO?.stocksAndBonds,
    totalAssets
  }
  return {
    type: SET_FINANCIAL_INFO_ASSETS,
    assets
  };
};
export const updateAssetsOfGuarantor = assets => {
  return async (dispatch, getState) => {
    try {
      dispatch(startLoading());
      const guarantorId = getState().guarantor.personalInformation.id;
      const response = await guarantorService.UpdateAssetsGuarantor(guarantorId, assets);
      dispatch(setMessageSnackbar(response.message, 'success'));
      dispatch(getAssetsOfGuarantor());
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };
}
// Assets - Life Insurance
export const updateLifeInsuranceOfGuarantor = lifeInsurance => {
  return async (dispatch, getState) => {
    try {
      dispatch(startLoading());
      const guarantorId = getState().guarantor.personalInformation.id;
      const lifeInsuranceDTO = {
        ...lifeInsurance,
        RelatedPartyId: guarantorId
      };
      const response = await guarantorService.UpdateLifeInsuranceOfGuarantor(lifeInsuranceDTO);
      dispatch(getAssetsOfGuarantor());
      dispatch(setMessageSnackbar(response, 'success'));
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
      dispatch(finishLoading());
    }
  }
}
// Assets - Automobiles
export const updateAutomobilesOfGuarantor = automobiles => async (dispatch, getState) => {
  try {
    dispatch(startLoading());
    const guarantorId = getState().guarantor.personalInformation.id;
    const automobilesDTO = automobiles.map(auto => ({
      Id: auto.id,
      RelatedPartyId: guarantorId,
      PresentValue: auto.presentValue,
      Year: auto.year,
      Make: auto.make,
      Model: auto.model,
      Value: auto.value,
      AutoLoan: auto.autoLoan,
      LenderName: auto.lenderName,
      LoanCurrentBalance: auto.loanCurrentBalance,
      LoanMonthlyPayment: auto.loanMonthlyPayment,
      AutoLease: auto.autoLease,
      LessorName: auto.lessorName,
      LeaseRemainingMonths: auto.leaseRemainingMonths,
      LeaseMonthlyPayment: auto.leaseMonthlyPayment
    }));
    const response = await guarantorService.UpdateAutomobilesOfGuarantor(automobilesDTO);
    dispatch(getAssetsOfGuarantor());
    dispatch(setMessageSnackbar(response, 'success'));
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
    dispatch(finishLoading());
  }
};
export const deleteAutomobileAssetsGuarantor = automobileId => async (dispatch, getState) => {
  try {
    dispatch(startLoading());
    const guarantorId = getState().guarantor.personalInformation.id;
    const response = await guarantorService.DeleteAutomobile(guarantorId, automobileId);
    dispatch(getAssetsOfGuarantor());
    dispatch(setMessageSnackbar(response, 'success'));
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
  }
  finally {
    dispatch(finishLoading());
  }
}
// Assets - Others
export const updateOtherAssetsOfGuarantor = assets => async (dispatch, getState) => {
  try {
    dispatch(startLoading());
    const guarantorId = getState().guarantor.personalInformation.id;
    const assetsDTO = assets.map(asset => ({
      RelatedPartyId: guarantorId,
      OtherAssets: [{
        Id: asset.id,
        Type: asset.typeOfAsset,
        Value: asset.value,
        LoanSecuredByAsset: asset.loanAsset,
        LenderName: asset.lenderName,
        LoanCurrentBalance: asset.loanCurrentBalance,
        LoanMonthlyPayment: asset.monthlyPayment
      }]
    }));

    const response = await guarantorService.UpdateOtherAssetsOfGuarantor(assetsDTO);
    dispatch(getAssetsOfGuarantor());
    dispatch(setMessageSnackbar(response, 'success'));
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
    dispatch(finishLoading());
  }
};
export const deleteOtherAssetOfGuarantor = assetId => async (dispatch, getState) => {
  try {
    dispatch(startLoading());
    const guarantorId = getState().guarantor.personalInformation.id;
    const response = await guarantorService.DeleteOtherAsset(guarantorId, assetId);
    dispatch(getAssetsOfGuarantor());
    dispatch(setMessageSnackbar(response, 'success'));
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
    dispatch(finishLoading());
  }
}
// Assets - Properties
export const updatePropertiesOfGuarantor = properties => async (dispatch, getState) => {
  try {
    dispatch(startLoading());
    const guarantorId = getState().guarantor.personalInformation.id;
    const response = await guarantorService.UpdatePropertiesOfGuarantor(guarantorId, properties);
    dispatch(getAssetsOfGuarantor());
    dispatch(setMessageSnackbar(response, 'success'));
  } catch (error) {
    dispatch(setMessageSnackbar(error, 'error'));
    dispatch(finishLoading());
  }
};
export const deletePropertyOfGuarantor = propertyId => async (dispatch, getState) => {
  try {
    dispatch(startLoading());
    const guarantorId = getState().guarantor.personalInformation.id;
    const response = await guarantorService.DeleteProperty(guarantorId, propertyId);
    dispatch(getAssetsOfGuarantor());
    dispatch(setMessageSnackbar(response, 'success'));
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
    dispatch(finishLoading());
  }
}
// Assets - StockBonds
export const updateStocksBondsOfGuarantor = stocksBonds => async (dispatch, getState) => {
  dispatch(startLoading());
  try {
    const guarantorId = getState().guarantor.personalInformation.id;
    const response = await guarantorService.UpdateStocksBondsOfGuarantor(guarantorId, stocksBonds);
    dispatch(getAssetsOfGuarantor());
    dispatch(setMessageSnackbar(response, 'success'));
  } catch (error) {
    dispatch(setMessageSnackbar(error, 'error'));
    dispatch(finishLoading());
  }
};
export const deleteStockBondOfGuarantor = stockBondId => async (dispatch, getState) => {
  try {
    dispatch(startLoading());
    const guarantorId = getState().guarantor.personalInformation.id;
    const response = await guarantorService.DeleteStockBond(guarantorId, stockBondId);
    dispatch(getAssetsOfGuarantor());
    dispatch(setMessageSnackbar(response, 'success'));
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
    dispatch(finishLoading());
  }
}


/// Liabilities
export const updateGeneralLiabilitiesOfGuarantor = liabilities => {
  return async (dispatch, getState) => {
    try {
      dispatch(startLoading());
      const guarantorId = getState().guarantor.personalInformation.id;
      const { generalLiabilities } = getState().guarantor.financialInformation.liabilities;
      const liabilitiesDTO = {
        id: generalLiabilities?.id,
        relatedPartyId: guarantorId,
        pledgeCollateral: liabilities.pledgeCollateral,
        typeOfCollateral: liabilities.typeOfCollateral
      }
      const response = await guarantorService.UpdateGeneralLiabilitiesOfGuarantor(liabilitiesDTO);
      dispatch(setMessageSnackbar(response, 'success'));
      dispatch(getLiabilitiesOfGuarantor());
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };
};
export const getLiabilitiesOfGuarantor = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(startLoading());
      const guarantorId = getState().guarantor.personalInformation.id;
      const response = await guarantorService.GetLiabilitiesOfGuarantor(guarantorId);
      const liabilities = {
        generalLiabilities: {
          id: response.id,
          relatedPartyId: response.relatedPartyId,
          totalCreditCardBalance: response.totalCreditCardBalance ?? 0,
          totalOutstandingInstallmentLoans: response.totalOutstandingInstallmentLoans ?? 0,
          totalUnpaidTaxes: response.totalUnpaidTaxes ?? 0,
          totalOtherLiabilities: response.totalOtherLiabilities ?? 0,
          pledgeCollateral: response.pledgeCollateral ?? '',
          typeOfCollateral: response.typeOfCollateral ?? ''
        },
        outstandingLoans: response.outstandingLoans ?? [],
        otherLiabilities: response.otherLiabilities ?? [],
        creditCards: response.creditCards ?? [],
        unpaidTaxes: response.unpaidTaxes ?? []
      };
      dispatch(setLiabilitiesOfGuarantor({ loaded: true, ...liabilities }));
      dispatch(finishLoading());
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
      dispatch(setLiabilitiesOfGuarantor({ loaded: true }));
      dispatch(finishLoading());
    }
  };
};
export const setLiabilitiesOfGuarantor = liabilities => {
  if (liabilities.generalLiabilities) {
    liabilities.generalLiabilities.totalCreditCardBalance = liabilities.creditCards.reduce((acc, x) => acc + x.currentBalanceLoan, 0);
    liabilities.generalLiabilities.totalOtherLiabilities = liabilities.otherLiabilities.reduce((acc, x) => acc + x.currentBalanceLoan, 0);
    liabilities.generalLiabilities.totalOutstandingInstallmentLoans = liabilities.outstandingLoans.reduce((acc, x) => acc + x.currentBalanceLoan, 0);
    liabilities.generalLiabilities.totalUnpaidTaxes = liabilities.unpaidTaxes.reduce((acc, x) => acc + x.currentBalance, 0);
  }
  return {
    type: SET_FINANCIAL_INFO_LIABILITIES,
    liabilities
  };
};
const setTotalLiabilitiesOfGuarantor = () => {
  return (dispatch, getState) => {
    const liabilities = getState().guarantor.financialInformation.liabilities;    
    const generalLiabilities = {
      totalCreditCardBalance: liabilities.creditCards.reduce((acc, x) => acc + x.currentBalanceLoan, 0),
      totalOtherLiabilities: liabilities.otherLiabilities.reduce((acc, x) => acc + x.currentBalanceLoan, 0),
      totalOutstandingInstallmentLoans: liabilities.outstandingLoans.reduce((acc, x) => acc + x.currentBalanceLoan, 0),
      totalUnpaidTaxes: liabilities.unpaidTaxes.reduce((acc, x) => acc + x.currentBalance, 0)
    }
    dispatch({
      type: SET_TOTAL_LIABILITIES,
      generalLiabilities
    });
  }
}
// Liabilities- UnpaidTaxes
export const updateUnpaidTaxesLiabilitiesOfGuarantor = body => {
  return async dispatch => {
    try {
      dispatch(startLoading());
      const response = await guarantorService.UpdateUnpaidTaxesLiabilitiesOfGuarantor(
        body
      );
      dispatch(setMessageSnackbar(response.message, 'success'));
      dispatch(getLiabilitiesOfGuarantor());
    } catch (e) {
      dispatch(setMessageSnackbar(e.message, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };
};
export const deleteUnpaidTaxesLiabilitiesOfGuarantor = unpaidTaxId => {
  return async dispatch => {
    try {
      dispatch(startLoading());
      const response = await guarantorService.DeleteUnpaidTaxesLiabilitiesOfGuarantor(unpaidTaxId);
      dispatch(setUnpaidTaxesLiabilitiesOfGuarantor(response.data));
      dispatch(setTotalLiabilitiesOfGuarantor());
      dispatch(setMessageSnackbar(response, 'success'));
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };
}
const setUnpaidTaxesLiabilitiesOfGuarantor = unpaidTaxes => {
  return {
    type: SET_LIABILITIES_UNPAID_TAXES,
    unpaidTaxes
  }
}
// Liabilities - Credit Card Balances
export const updateCreditCardBalancesOfGuarantor = body => {
  return async dispatch => {
    try {
      dispatch(startLoading());
      const response = await guarantorService.UpdateCreditCardBalancesOfGuarantor(
        body
      );
      dispatch(getLiabilitiesOfGuarantor());
      dispatch(setMessageSnackbar(response, 'success'));
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };
};
export const deleteCreditCardBalanceOfGuarantor = cardId => {
  return async dispatch => {
    try {
      dispatch(startLoading());
      const response = await guarantorService.DeleteCreditCardBalanceOfGuarantor(cardId);
      dispatch(setCreditCardsBalance(response.data));
      dispatch(setTotalLiabilitiesOfGuarantor());
      dispatch(setMessageSnackbar(response, 'success'));
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };
};
const setCreditCardsBalance = creditCards => {
  return {
    type: SET_LIABILITIES_CREDIT_CARDS,
    creditCards
  }
}
// Liabilities - Outstanding Personal Loans
export const updateOutstandingPersonalLoansOfGuarantor = body => {
  return async dispatch => {
    try {
      dispatch(startLoading());
      const response = await guarantorService.UpdateOutstandingLoansOfGuarantor(
        body
      );
      dispatch(getLiabilitiesOfGuarantor());
      dispatch(setMessageSnackbar(response.message, 'success'));
    } catch (e) {
      dispatch(setMessageSnackbar(e.message, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };
};
export const deleteOutstandingPersonalLoanOfGuarantor = loanId => {
  return async dispatch => {
    try {
      dispatch(startLoading());
      const response = await guarantorService.DeleteOutstandingLoansOfGuarantor(loanId);
      dispatch(setOutstandingPersonalLoans(response.data));
      dispatch(setTotalLiabilitiesOfGuarantor());
      dispatch(setMessageSnackbar(response, 'success'));
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };
};
const setOutstandingPersonalLoans = outstandingLoans => {
  return {
    type: SET_LIABILITIES_LOANS,
    outstandingLoans
  }
};
// Liabilities - Others
export const updateOtherLiabilitiesOfGuarantor = body => {
  return async dispatch => {
    try {
      dispatch(startLoading());
      const response = await guarantorService.UpdateOtherLiabilitiesOfGuarantor(body);
      dispatch(getLiabilitiesOfGuarantor());
      dispatch(setMessageSnackbar(response.message, 'success'));
    } catch (e) {
      dispatch(setMessageSnackbar(e.message, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };
};
export const deleteOtherLiabilitiesOfGuarantor = otherId => {
  return async dispatch => {
    try {
      dispatch(startLoading());
      const response = await guarantorService.DeleteOtherLiabilitiesOfGuarantor(otherId);
      dispatch(setOtherLiabilitiesOfGuarantor(response.data));
      dispatch(setTotalLiabilitiesOfGuarantor());
      dispatch(setMessageSnackbar(response, 'success'));
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };
};
const setOtherLiabilitiesOfGuarantor = otherLiabilities => {
  return {
    type: SET_LIABILITIES_OTHERS,
    otherLiabilities
  }
}