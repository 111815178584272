import authService from 'src/services/authService';
import accountService from 'src/services/accountService';
import { finishLoading, setMessageSnackbar, startLoading } from './uiActions';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const UPDATE_PROFILE = '@account/update-profile';
export const LOGOUT_EXPIRED_SESSION = '@account/logout-expired-session';

export function login(email, password) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });
      const user = await authService.loginWithEmailAndPassword(
        email,
        password,
        '1',
      );
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user,
        },
      });
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function setUserData(user) {
  return (dispatch) => dispatch({
      type: SILENT_LOGIN,
      payload: {
        user,
      },
    });
}

export function logout() {
  return async (dispatch) => {
    authService.logout();

    dispatch({
      type: LOGOUT,
    });
  };
}

export function logoutExpiredSession() {
  return async (dispatch) => dispatch({
      type: LOGOUT_EXPIRED_SESSION,
    });
}

export function register() {
  return true;
}

// Owners
const setUser = (user) => ({
    type: UPDATE_PROFILE,
    payload: { user },
  });

export const updateProfile = (update) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const formDto = { ...update, token: localStorage.getItem('accessToken') };
    const response = await accountService.UpdateAccount(formDto);
    const date = new Date();

    dispatch(
      setUser({
        ...response.data,
        timezone: date,
        avatar: '/static/images/avatars/fotoprofile.png',
      }),
    );
    authService.setSession(response.data.tokenrefresh);
    dispatch(setMessageSnackbar('Profile updated', 'success'));
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
  } finally {
    dispatch(finishLoading());
  }
};
