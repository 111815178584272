import React from 'react';
import { OnErrorLogo } from '../utils/urlPath';

function LogoTwo(props) {
  return (
    <img
      alt="LogoTwo"
      height="60px"
      onError={(e) => OnErrorLogo(e)}
      src="/static/logo-white.svg"
      width="100px"
      {...props}
    />
  );
}

export default LogoTwo;
