import locationsService from "src/services/locationsService";

export const SET_LIST_LOCATIONS = "@location/set-list-locations";

export function setLocations(index, locations) {
  return {
    type: SET_LIST_LOCATIONS,
    index,
    locations
  }
}

export function getLocations() {
  return async (dispatch) => {
    try {
      const countries = await locationsService.GetCountries();
      dispatch(setLocations("countries", countries.data));
    } catch (error) {
      throw error;
    }
  }
}

export function getRegionsByCountry(countryId) {
  return async (dispatch) => {
    const regions = await locationsService.GetRegionsByCountry(countryId);
    dispatch(setLocations("regions", regions.data));
  };
}

export function getCitiesByRegion(regionId) {
  return async (dispatch) => {
    const cities = await locationsService.GetCitiesByRegion(regionId);
    dispatch(setLocations("cities", cities.data));
  };
}

export function getCities() {
  return async (dispatch) => {
    const cities = await locationsService.GetCities();
    dispatch(setLocations("cities", cities.data));
  };
}
export const getRegionsByCountryCode = async (countryId, successCallback) => {
  await locationsService.GetRegionsByCountry(countryId)
  .then((response) => {
    successCallback(response.data);
  });
};

export const getCitiesByRegionCode = async (regionId, successCallback) => {
  await locationsService.GetCitiesByRegion(regionId)
  .then((response) => {
    successCallback(response.data);
  });
}
