/* eslint-disable react/no-array-index-key */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import bytesToSize from 'src/utils/bytesToSize';
import { useTranslation } from 'react-i18next';
import { Trash as TrashIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {},
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(6),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      opacity: 0.5,
      cursor: 'pointer',
    },
  },
  dragActive: {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5,
  },
  image: {
    width: 130,
  },
  info: {
    marginTop: theme.spacing(1),
  },
  list: {
    maxHeight: 320,
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

function FilesDropzone({
  className,
  submitBtn,
  files,
  setFiles,
  multiple,
  acceptedTypes,
  ...rest
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleDrop = useCallback((acceptedFiles) => {
    if (multiple) {
      setFiles((prevFiles) => [...prevFiles].concat(acceptedFiles));
    } else {
      setFiles(acceptedFiles);
    }
  }, []);

  const handleRemoveAll = () => {
    setFiles([]);
  };

  const deleteOneCurrentAttachment = (index) => {
    files.splice(index, 1);
    setFiles([...files]);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: acceptedTypes,
    multiple,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <div
        className={clsx({
          [classes.dropZone]: true,
          [classes.dragActive]: isDragActive,
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <div>
          <img
            alt="Select file"
            className={classes.image}
            src="/static/images/undraw_add_file2_gvbb.svg"
          />
        </div>
        <div>
          <Typography gutterBottom variant="h3">
            {t('selectFiles')}
          </Typography>
          <Typography gutterBottom variant="h5">
            {t('maxFileSize')}
          </Typography>
          <Box mt={2}>
            <Typography color="textPrimary" variant="body1">
              {t('selectFilesDesc')}
            </Typography>
          </Box>
        </div>
      </div>
      {files && files.length > 0 && (
        <>
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <List className={classes.list}>
              {files.map((file, i) => (
                <ListItem key={i} divider={i < files.length - 1}>
                  <ListItemIcon>
                    <FileCopyIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={file.name}
                    primaryTypographyProps={{ variant: 'h5' }}
                    secondary={bytesToSize(file.size)}
                  />
                  <Tooltip title={t('delete')}>
                    <IconButton
                      onClick={() => deleteOneCurrentAttachment(i)}
                      size="large"
                    >
                      <SvgIcon fontSize="small" size="medium">
                        <TrashIcon />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                </ListItem>
              ))}
            </List>
          </PerfectScrollbar>
          <div className={classes.actions}>
            <Button onClick={handleRemoveAll} size="small">
              {t('removeAll')}
            </Button>
            {submitBtn && (
              <Button color="secondary" size="small" variant="contained">
                {t('uploadFiles')}
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
}

FilesDropzone.propTypes = {
  className: PropTypes.string,
  submitBtn: PropTypes.bool,
  files: PropTypes.array,
  setFiles: PropTypes.func,
  multiple: PropTypes.bool,
  acceptedTypes: PropTypes.string,
};

export default FilesDropzone;
