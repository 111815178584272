/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
    LOANPRODUCT_ID,
    LOANPRODUCT_REQUEST_FAILURE,
    ORIGINATOR_ID,
    ORIGINATOR_REQUEST_FAILURE,
    SET_LOAN_PRODUCT_VALIDATIONS,
    SET_LOAN_PRODUCT_TASKS,
    SET_LOAN_PRODUCT_DETAILS,
    SET_LOAN_PRODUCT_TASK_PARAMETER,
    SET_LOAN_PRODUCT_CONDITIONS_CHARGE,
    SET_LOAN_PRODUCT_CHARGE_FORM
} from 'src/store/actions/loanProductActions';

const initialState = {
    loanProductId: null,
    loanProductOriginatorId: null,
    loanProductDetails: null,
    loanProductTaskParameter: null,
    loanProductConditionsCharge: [],
    loanProductChargeForm: {}
};

const loanProductReducer = (state = initialState, action) => {
    switch (action.type) {
      case LOANPRODUCT_ID: {
        return produce(state, (draft) => {
          draft.loanProductId = action.payload.loanProduct;
        });
      }

      case LOANPRODUCT_REQUEST_FAILURE: {
        // console.log(ORIGIN_REQUEST_FAILURE)
        return produce(state, () => {
          // Maybe store error
        });
      }

      case ORIGINATOR_ID: {
        return produce(state, (draft) => {
          draft.loanProductOriginatorId = action.payload.loanProductOriginatorId;
        });
      }

      case ORIGINATOR_REQUEST_FAILURE: {
        // console.log(ORIGIN_REQUEST_FAILURE)
        return produce(state, () => {
          // Maybe store error
        });
      }

      case SET_LOAN_PRODUCT_VALIDATIONS: {
        return {
          ...state,
          validations: action.validations
        };
      }
      case SET_LOAN_PRODUCT_TASKS: {
        return {
          ...state,
          tasks: action.payload
        };
      }
      case SET_LOAN_PRODUCT_DETAILS: {
        return {
          ...state,
          loanProductDetails: action.payload.loanProductDetails
        };
      }
      case SET_LOAN_PRODUCT_CONDITIONS_CHARGE: {
        return {
          ...state,
          loanProductConditionsCharge: action.loanProductConditionsCharge,
        };
      }
      case SET_LOAN_PRODUCT_CHARGE_FORM: {
        return {
          ...state,
          loanProductChargeForm: action.loanProductChargeForm,
        };
      }
      case SET_LOAN_PRODUCT_TASK_PARAMETER: {
        return {
          ...state,
          loanProductTaskParameter: action.payload.loanProductTaskParameter
        };
      }
      default: {
        return state;
      }
    }
};

export default loanProductReducer;
