import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ROLES_ACCESS, includesAny, includesOne } from 'src/constants';

export default function useDashboardByRole() {
  const history = useHistory();
  const { user } = useSelector((state) => state.account);
  const validateRole = useCallback(() => {
    if (user) {
      const ADMIN_ACCESS = user.roles ? includesAny(ROLES_ACCESS.ADMIN_ROLES_ACCESS, user.roles) : false;
      if (ADMIN_ACCESS) {
        history.push('/app');
      }

      const ACCOUNTING_DEPT = user.roles ? includesOne(user.roles, ROLES_ACCESS.ACCOUNTING_DEPT) : false;
      if (ACCOUNTING_DEPT) {
        history.push('/app/accounting/dashboard');
      }
    }
  }, [user]);

  validateRole();
}
