/* eslint-disable no-param-reassign */
import {
  SET_LOAN_FILTER,
  initialStateLoanFilter
} from '../actions/loanFilterAction';

const loanFilterReducer = (state = initialStateLoanFilter, action) => {
  switch (action.type) {
    case SET_LOAN_FILTER: {
      const { loanFilter } = action;
      return { ...state, ...loanFilter };
    }
    default: {
      return initialStateLoanFilter;
    }
  }
};

export default loanFilterReducer;
