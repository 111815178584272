import { SET_ORGANIZATION_SETTING } from '../actions/organizationActions';

const initialState = {
  organizationSettings: []
};

const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORGANIZATION_SETTING: {
      return {
        ...state,
        organizationSettings: action.organizationSetting
      };
    }
    default:
      return state;
  }
};

export default organizationReducer;