import axios from 'src/utils/axios';
// Axios with headers
import { API_BASE_URL } from 'src/config/';
import routes from 'src/config/api.routes';
import axiosService from './axiosService';

class DebtCollectionService {
  GetFactorRules = (filter) =>
    new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .get(`${API_BASE_URL}/api/FactorRules${filter}`, {
          headers: {
            Authorization: AuthStr,
            appClientId: localStorage.getItem('appClientId')
          }
        })
        .then((response) => {
          if (response.data) {
            const db = {
              result: {
                data: response.data.processedObject,
                message: response.data.message
              }
            };
            resolve(db);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          console.error(`Error response:${error}`);
          reject(error);
        });
    });

  GetLoansToFactor = async (filter, factoreRuleId) =>
    await axiosService.get(
      `/api/Factor/getLoansToFactor/${factoreRuleId}/${filter}`
    );

  GetLoansToFactorByIdentification = (identificationNumber) =>
    new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .get(
          `${API_BASE_URL}/api/Factor/getFactorDetailsIdentificationNumber/${identificationNumber}`,
          {
            headers: {
              Authorization: AuthStr,
              appClientId: localStorage.getItem('appClientId')
            }
          }
        )
        .then((response) => {
          if (response.data) {
            const db = {
              result: {
                data: response.data.data,
                message: response.data.message,
                elements: response.data.data.elements
              }
            };
            resolve(db);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          console.error(`Error response:${error}`);
          reject(error);
        });
    });

  GetListTemplateSendinBlue = (originatorId) =>
    axiosService.get(`/api/Factor/${originatorId}`);

  GetFactorRule = (factorRuleId) =>
    axiosService.get(`/api/FactorRules/${factorRuleId}`);

  CreateFactorRule = (values, factorRuleId) =>
    new Promise((resolve, reject) => {
      const data = JSON.stringify(values);
      const AuthStr = axios.defaults.headers.common.Authorization;

      if (!factorRuleId) {
        axios
          .post(`${API_BASE_URL}/api/FactorRules`, data, {
            headers: {
              Authorization: AuthStr,
              'Content-Type': 'application/json',
              appClientId: localStorage.getItem('appClientId')
            }
          })
          .then((response) => {
            if (response) {
              resolve(response?.data);
            }
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        axios
          .put(`${API_BASE_URL}/api/FactorRules/${factorRuleId}`, data, {
            headers: {
              Authorization: AuthStr,
              'Content-Type': 'application/json',
              appClientId: localStorage.getItem('appClientId')
            }
          })
          .then((response) => {
            if (response) {
              resolve(response?.data);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    });

  DeleteFactorRule = async (factorRuleId) =>
    await axiosService.delete(`${routes.factorRules}/${factorRuleId}`);

  GetFactorRulesByOriginatorId = async (originatorId) =>
    await axiosService.get(
      `${routes.factorRules}/by-originator/${originatorId}`
    );

  CreateDebtCollectionManagement = async (loanDebtCollectionManagementDTO) =>
    await axiosService.post(
      `${routes.loanDebtCollectionManagement}`,
      loanDebtCollectionManagementDTO
    );

  GetDebtCollectionManagements = async (loanId) =>
    await axiosService.get(`${routes.loanDebtCollectionManagement}/${loanId}`);

  CreateDebtCollectionManagement = async (loanDebtCollectionManagementDTO) =>
    await axiosService.post(
      `${routes.loanDebtCollectionManagement}`,
      loanDebtCollectionManagementDTO
    );

  GetDebtCollectionManagements = async (loanId) =>
    await axiosService.get(`${routes.loanDebtCollectionManagement}/${loanId}`);
}

const debtCollectionService = new DebtCollectionService();

export default debtCollectionService;
