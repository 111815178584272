import originatorService from 'src/services/originatorService';
import { finishLoading, setMessageSnackbar, startLoading } from './uiActions';

export const ORIGIN_REQUEST = '@originator/Origin-request';
export const ORIGIN_REQUEST_FAILURE = '@originator/Origin-failure';
export const ORIGIN_SUCCESS = '@originator/Origin-success';

export const SET_ORIGIN_SELECTED = '@originator/Origin-selected';
export const SET_ALL_ORIGINATORS = '@originator/All-orginators';
export const RESET_ORIGINATOR = '@originator/Reset-orginators';
export const SET_ORIGINATORS_CUSTOMER = '@originator/orginators-customer';

export function originators() {
  return async (dispatch) => {
    try {
      const response = await originatorService.getOriginators();
      dispatch({
        type: ORIGIN_SUCCESS,
        payload: {
          originator: response.data
        }
      });
    } catch (error) {
      dispatch(setMessageSnackbar(error, 'error'));
      dispatch({ type: ORIGIN_REQUEST_FAILURE });
      throw error;
    }
  };
}

export function getAllOriginators() {
  return async (dispatch) => {
    const response = await originatorService.GetAllOriginators();
    dispatch({
      type: SET_ALL_ORIGINATORS,
      originators: response.data
    });
  };
}

export const setOriginatorsCustomer = (originatorsCustomer) => {
  return {
    type: SET_ORIGINATORS_CUSTOMER,
    originatorsCustomer
  };
};

export function getOriginatorsCustomer(entityId) {
  return async (dispatch) => {
    const response = await originatorService.GetOriginatorsxCustomer(entityId);
    dispatch(setOriginatorsCustomer(response.data));
  };
}

export const createOriginatorxCustomer =
  (entityId, originatorId, personId, companyId) => async (dispatch) => {
    try {
      dispatch(startLoading());
      const newCustomerOriginator = {
        OriginatorId: originatorId,
        PersonId: personId,
        CompanyId: companyId
      };
      const response = await originatorService.CreateOriginatorxCustomer(
        newCustomerOriginator
      );
      dispatch(setMessageSnackbar(response, 'success'));
      dispatch(setOriginatorsCustomer([]));
      dispatch(getOriginatorsCustomer(entityId));
    } catch (error) {
      dispatch(setMessageSnackbar(error, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };

export const deleteOriginatorxCustomer = (entityId, id) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await originatorService.DeleteOriginatorxCustomer(id);
    dispatch(setMessageSnackbar(response, 'success'));
    dispatch(setOriginatorsCustomer([]));
    dispatch(getOriginatorsCustomer(entityId));
  } catch (error) {
    dispatch(setMessageSnackbar(error, 'error'));
  } finally {
    dispatch(finishLoading());
  }
};

export function resetOriginator() {
  return (dispatch) => {
    dispatch({
      type: RESET_ORIGINATOR,
      originator: []
    });
  };
}

export function getOriginatorById(id) {
  return async (dispatch) => {
    const data = await originatorService.getOriginatorById(id);
    dispatch({
      type: SET_ORIGIN_SELECTED,
      originator: data.data
    });
  };
}

export function setOriginatorSelected(originator) {
  return {
    type: SET_ORIGIN_SELECTED,
    originator
  };
}
