/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  ORIGIN_REQUEST,
  ORIGIN_REQUEST_FAILURE,
  ORIGIN_SUCCESS,
  SET_ALL_ORIGINATORS,
  SET_ORIGIN_SELECTED,
  RESET_ORIGINATOR,
  SET_ORIGINATORS_CUSTOMER
} from 'src/store/actions/originatorActions';

const initialState = {
  originator: [],
  originators: [],
  originatorsCustomer: [],
  originatorSelected: {
    id: 0,
    codOriginator: 0,
    funder: 0,
    nameOriginator: '',
    idResponsible: 0,
    paymenthMethods: [],
    customerSegments: []
  }
};

const OriginatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORIGIN_REQUEST: {
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.originator = null;
      });
    }
    case ORIGIN_SUCCESS: {
      if (action.payload.originator) {
        return produce(state, (draft) => {
          draft.originator = [...action.payload.originator];
        });
      }
    }
    case ORIGIN_REQUEST_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case SET_ORIGIN_SELECTED: {
      return { ...state, originatorSelected: action.originator };
    }
    case SET_ALL_ORIGINATORS: {
      return { ...state, originators: action.originators };
    }
    case RESET_ORIGINATOR: {
      return { ...state, originator: action.originator };
    }
    case SET_ORIGINATORS_CUSTOMER: {
      return { ...state, originatorsCustomer: action.originatorsCustomer };
    }
    default: {
      return state;
    }
  }
};

export default OriginatorReducer;
