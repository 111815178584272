export const customerNewEn = {
  academicLevel: 'Education Level',
  academicStatus: 'Study Status',
  'Account number': 'Account Number',
  'Account type': 'Account Type',
  Address: 'Address',
  Authentication: 'Authentication',
  Bank: 'Bank',
  'Photo with ID': 'Photo With Your ID',
  'Photo of ID': 'Photo of Your ID',
  'Photo back of ID': 'Backup Photo of Your ID',
  'Open camera': 'Open Camera',
  'Photo ID description': "Remember that photo's data must be legible",
  Webcam: 'Webcam',
  // Personal
  customerInfo: 'Customer Info',
  firstName: 'First Name',
  firstLastName: 'First Lastname',
  secondLastName: 'Second Lastname',
  'Birth date': 'Birth Date',
  birthDate: 'Birth Date',
  'Bank info': 'Bank Info',
  'Bank name': 'Bank Name',
  birthDateValidation:
    'You must be at least 18 years old and under 70 years old',
  business: 'Business',
  Captures: 'Captures',
  ciiuCode: 'CIIU Code',
  City: 'City',
  commercialAdvisor: 'Commercial Advisor',
  Company: 'Company',
  CompanyAddress: 'Company Address',
  companyName: 'Company Name',
  CompanyPhone: 'Company Phone',
  compensationFund: 'Affiliation with a Compensation Fund',
  Contact: 'Contact',
  'Contact info': 'Contact Info',
  ContractType: 'Contract Type',
  contributingMembers: 'Household Members Contributing to the Loan',
  contributions: 'Contributions to Family Members or Dependents',
  Country: 'Country',
  createCustomer: 'Create Borrower',
  Current: 'Current Account',
  Department: 'Department',
  eighteenYearsValidation: 'You must be at least 18 years old',
  email: 'Email',
  employee: 'Employee',
  EmploymentSituation: 'Employment Situation',
  'Expeditión date': 'Expedition Date',
  expeditionDateValidation:
    'Date entered should be 18 years later than your birth date',
  'Family reference': 'Familiy Reference',
  Female: 'Female',
  fixedMonthlyIncomes: 'Fixed Income',
  foodExpenses: 'Food Expenses',
  housingDeeds: 'Housing Deeds',
  Identification: 'Identification',
  'Identification info': 'Identification Info',
  'Identification number': 'Identification Number',
  expeditionDate: 'Expedition Date',
  Male: 'Male',
  // Profesión
  professionInfo: 'Profession Info',
  'Occupation type': 'Occupation Type',
  'Identification type': 'Identification Type',
  Income: 'Monthly Income',
  independent: 'Independent',
  installmentsTotalCredits: 'Total Outstanding Loan Installments',
  legalPerson: 'Legal Person',
  liabilities: 'Debt Ratio (Liabilities)',
  mainEconomicActivity: 'Economic Activity',
  monthlyPaymentDebts: 'Total Amount of Debts',
  naicsCode: 'NAICS Code',
  Name: 'Name',
  nameLastName: 'Name and Lastname',
  naturalPerson: 'Natural Person',
  otherFrequent: 'Other Frequent',
  otherIncome: 'Other Recurring Income',
  permanentMembers: 'Total Permanent Household Members',
  'Personal reference': 'Personal Reference',
  Phone: 'Phone',
  Profession: 'Profession',
  registerPersonLegal: 'Register Legal Person',
  Relation: 'Relation',
  rentAdministration: 'Rent/ Administration',
  retired: 'Retired',
  Saving: 'Saving Account',
  secondName: 'Second Name',
  seventyYearsValidation: 'You must be under 70 years old',
  sourcesFunds: 'Resource Source',
  spouseFullName: "Spouse's Full Name",
  spousePhoneNumber: "Spouse's Cell Phone Number",
  TimeInJob: 'Time in Job',
  totalMonthlyExpenses: 'Total Expense',
  transportation: 'Transportation Expenses',
  unemployed: 'Unemployed',
  variableMonthlyIncomes: 'Variable Income',
  'Work reference': 'Work Reference',
  WorkInformation: 'Work Information',
  lengthIdentificationNumberFormat:
    'The identification number cannot be longer than {{lengthMask}} characters.',
  fatherMother: 'Father/Mother',
  brotherSister: 'Brother/Sister',
  sonDaughter: 'Son/Daughter',
  cousin: 'Cousin',
  uncleAunt: 'Uncle/Aunt',
  husbandWife: 'Husband/Wife',
  directChief: 'Direct Chief',
  humanResources: 'Human Resources',
  partner: 'Partner',
  client: 'Client',
  supplier: 'Supplier',
  banks: 'Banks',
  bank: 'Bank',
  addAccount: 'Add Account',
  bankInformation: 'Bank Information',
  addNewAccount: 'Add New Account',
  deleteAccount: 'Delete Account',
  sureRemoveAccount: 'Are you sure you want to delete the account?',
  noBanksToShow: 'No banks to show',
};

export const customerNewEs = {
  academicLevel: 'Nivel de estudios',
  academicStatus: 'Estado de estudios',
  'Account number': 'Número de cuenta',
  'Account type': 'Tipo de cuenta',
  Address: 'Dirección',
  Authentication: 'Autenticación',
  Bank: 'Banco',
  'Photo with ID': 'Foto con tu cédula',
  'Photo of ID': 'Foto a tu cédula',
  'Photo back of ID': 'Foto cédula respaldo',
  'Open camera': 'Abrir Camara',
  'Photo ID description': 'Recuerda que en la foto los datos sean legibles',
  Webcam: 'Cámara web',
  // Personal
  customerInfo: 'Información de cliente',
  firstName: 'Primer nombre',
  firstLastName: 'Primer apellido',
  secondLastName: 'Segundo apellido',
  'Birth date': 'Fecha de nacimiento',
  birthDate: 'Fecha de nacimiento',
  'Bank info': 'Información bancaria',
  'Bank name': 'Nombre del banco',
  birthDateValidation: 'Debes ser mayor a 18 años y menor a 70',
  business: 'Empresa',
  Captures: 'Capturas',
  ciiuCode: 'Código CIIU',
  City: 'Ciudad',
  commercialAdvisor: 'Asesor Comercial',
  Company: 'Empresa',
  CompanyAddress: 'Dirección de la empresa',
  CompanyName: 'Nombre de la empresa',
  CompanyPhone: 'Teléfono de la empresa',
  compensationFund: 'Afiliación Caja de Compensación',
  Contact: 'Contacto',
  'Contact info': 'Información de contacto',
  ContractType: 'Tipo de contrato',
  contributingMembers: 'Miembros del hogar que aporten al crédito',
  contributions: 'Aportes a familiares o personas a cargo',
  Country: 'País',
  createCustomer: 'Crear cliente',
  Current: 'Corriente',
  Department: 'Departamento',
  eighteenYearsValidation: 'Debes ser mayor a 18 años',
  email: 'Correo',
  employee: 'Empleado',
  EmploymentSituation: 'Situación laboral',
  expeditionDateValidation:
    'La fecha ingresada debería ser 18 años posterior a su fecha de nacimiento',
  'Family reference': 'Referencia familiar',
  fixedMonthlyIncomes: 'Ingresos Fijos',
  foodExpenses: 'Alimentación',
  housingDeeds: 'Escrituras de vivienda',
  Identification: 'Identificación',
  'Identification info': 'Información de identificación',
  'Identification number': 'Número de identificación',
  'Expeditión date': 'Fecha de expedición',
  expeditionDate: 'Fecha de expedición',
  Female: 'Femenino',
  // Profesión
  professionInfo: 'Información profesional',
  'Occupation type': 'Tipo de ocupación',
  'Identification type': 'Tipo de identificación',
  Income: 'Ingresos mensuales',
  independent: 'Independiente',
  installmentsTotalCredits: 'Total cuotas créditos vigentes',
  legalPerson: 'Persona jurídica',
  liabilities: 'Relación de las deudas (Pasivos)',
  mainEconomicActivity: 'Actividad Económica',
  Male: 'Masculino',
  monthlyPaymentDebts: 'Cuota total de las deudas',
  naicsCode: 'Código NAICS',
  Name: 'Nombre',
  nameLastName: 'Nombre y Apellido',
  naturalPerson: 'Persona natural',
  otherFrequent: 'Otros frecuentes',
  otherIncome: 'Otros ingresos recurrentes',
  permanentMembers: 'Total miembros permanentes del hogar',
  'Personal reference': 'Referencia personal',
  Phone: 'Teléfono',
  Profession: 'Profesión',
  registerPersonLegal: 'Registrar compañia',
  Relation: 'Relación',
  rentAdministration: 'Arriendo/ Administración',
  retired: 'Retirado',
  Saving: 'Ahorros',
  secondName: 'Segundo nombre',
  seventyYearsValidation: 'Debes ser menor a 70 años',
  sourcesFunds: 'Fuente de recursos',
  spouseFullName: 'Nombre completo del conyugue',
  spousePhoneNumber: 'Número de celular del conyugue',
  TimeInJob: 'Tiempo Trabajando',
  totalMonthlyExpenses: 'Total egresos',
  transportation: 'Transporte',
  unemployed: 'Desempleado',
  variableMonthlyIncomes: 'Ingresos variables',
  'Work reference': 'Referencia laboral',
  WorkInformation: 'Información laboral',
  lengthIdentificationNumberFormat:
    'El numero de identificación no puede tener mas de {{lengthMask}} caracteres',
  fatherMother: 'Padre/Madre',
  brotherSister: 'Hermano(a)',
  sonDaughter: 'Hijo(a)',
  cousin: 'Primo(a)',
  uncleAunt: 'Tío(a)',
  husbandWife: 'Esposo(a)',
  directChief: 'Jefe Directo',
  humanResources: 'Recursos Humanos',
  partner: 'Socio',
  client: 'Cliente',
  supplier: 'Proveedor',
  banks: 'Bancos',
  bank: 'Banco',
  addAccount: 'Añadir cuenta',
  bankInformation: 'Información bancaria',
  addNewAccount: 'Agregar nueva cuenta',
  deleteAccount: 'Eliminar cuenta',
  sureRemoveAccount: 'Estás seguro de eliminar la cuenta?',
  noBanksToShow: 'No hay bancos para mostrar',
};
