import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Form, Formik } from 'formik';
import FilesDropzone from 'src/components/FilesDropzone';

const useStyles = makeStyles(() => ({
  marginOut: { 'margin-left': -16, 'margin-right': -16 },
}));

export default function ModalEvidences({ filesTask, setFilesTask }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const ListViewFiles = () => (
    <Grid item xs={12}>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h5">{t('evidences')}</Typography>
        </CardContent>
        <Divider />
        <FilesDropzone
          files={filesTask}
          multiple
          setFiles={setFilesTask}
          submitBtn={false}
        />
      </Card>
    </Grid>
  );

  return (
    <Formik
      initialValues={{
        files: [],
      }}
    >
      {() => (
        <Form className={classes.marginOut}>
          <Box m={2}>
            <Grid container spacing={3}>
              <ListViewFiles />
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
