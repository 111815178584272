import { SET_IDENTIFICATION_TYPES } from "../actions/generalActions";

const initialState = {
  identificationTypes: []
}


const generalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IDENTIFICATION_TYPES: {
      return {
        ...state,
        identificationTypes: action.identificationTypes
      }
    }
    default:
      return state;
  }
}

export default generalReducer;