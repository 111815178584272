import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { es, en } from './translations/index';
import { commonEn, commonEs } from './translations/common';
import {
  navBarEn,
  navBarEs
} from './translations/layouts/DashboardLayout/NavBar';
import {
  customerListEn,
  customerListEs
} from './translations/views/management/customerList';
import {
  customerNewEn,
  customerNewEs
} from './translations/views/management/customerNew';
import {
  customerEditEn,
  customerEditEs
} from './translations/views/management/customerEdit';
import {
  customerDetailsEn,
  customerDetailsEs
} from './translations/views/management/customerDetails';
import {
  customerDeletedEn,
  customerDeletedEs
} from './translations/views/management/customerDeleted';
import {
  customerImportEn,
  customerImportEs
} from './translations/views/management/customerImport';
import {
  loanDetailsEn,
  loanDetailsEs
} from './translations/views/management/loans';
import {
  debtCollectionListEn,
  debtCollectionListEs
} from './translations/views/management/debtCollectionList';
import {
  loanProductsEn,
  loanProductsEs
} from './translations/views/configuration/loanProducts';
import { countryEn, countryEs } from './translations/common/country';
import {
  debtCollectionsFactorRulesListEn,
  debtCollectionsFactorRulesListEs
} from './translations/views/management/debtCollectionsFactorRulesList';
import {
  loanRequestEn,
  loanRequestEs
} from './translations/views/management/loanRequest';
import {
  originatorsEn,
  originatorsEs
} from './translations/views/configuration/originators';
import {
  settingsEn,
  settingsEs
} from './translations/views/configuration/settings';
import { usersEn, usersEs } from './translations/views/configuration/users';
import {
  fundersEn,
  fundersEs
} from './translations/views/configuration/funders';
import { tasksEn, tasksEs } from './translations/views/management/tasks';
import { reportsEn, reportsEs } from './translations/views/management/reports';
import {
  campaignsEn,
  campaignsEs
} from './translations/views/management/campaigns';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translations: {
        ...en,
        ...es,
        ...commonEn,
        ...navBarEn,
        ...customerListEn,
        ...customerNewEn,
        ...customerEditEn,
        ...customerDetailsEn,
        ...customerDeletedEn,
        ...customerImportEn,
        ...reportsEn,
        ...debtCollectionListEn,
        ...loanProductsEn,
        ...debtCollectionsFactorRulesListEn,
        ...loanDetailsEn,
        ...loanRequestEn,
        ...originatorsEn,
        ...settingsEn,
        ...usersEn,
        ...fundersEn,
        ...tasksEn,
        ...countryEn,
        ...campaignsEn
      }
    },
    es: {
      translations: {
        ...commonEs,
        ...navBarEs,
        ...customerListEs,
        ...customerNewEs,
        ...customerEditEs,
        ...customerDetailsEs,
        ...customerDeletedEs,
        ...customerImportEs,
        ...reportsEs,
        ...debtCollectionListEs,
        ...loanProductsEs,
        ...debtCollectionsFactorRulesListEs,
        ...loanDetailsEs,
        ...loanRequestEs,
        ...originatorsEs,
        ...settingsEs,
        ...usersEs,
        ...fundersEs,
        ...tasksEs,
        ...countryEs,
        ...campaignsEs
      }
    }
  },
  fallbackLng: 'es',
  debug: true,
  // {t("Change")}
  // import { useTranslation } from 'react-i18next';
  // const { t, i18n } = useTranslation();
  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',
  returnObjects: true,
  joinArrays: true,
  keySeparator: false, // we use content as keys
  interpolation: {
    formatSeparator: ','
  },
  react: {
    wait: true
  }
});

export default i18n;
