export const campaignsEn = {
  campaignType: 'Campaign Type',
  corporate: 'Corporate',
  loyalty: 'Loyalty',
  positioning: 'Positioning',
  promotional: 'Promotional',
  remarketing: 'Remarketing',
  campaignName: 'Campaign Name',
  automatic: 'Automatic',
  totalCustomersFiltered: 'Total Customers Filtered',
  thereIsNoInformationOnCampaigns: 'There is no information on campaigns',
  overview: 'Overview',
  detailsCampaign: 'Details Campaign',
  automaticCampaign: 'Automatic Campaign',
  executionFrequency: 'Execution Frequency',
  contactFrequency: 'Contact Frequency',
  idTemplateSendinblue: 'Email Template ID',
  messageCampaign: 'SMS Campaign Message',
  age: 'Age',
  genderType: 'Gender type',
  approvedLoans: 'Number of Loans Approved',
  occupation: 'Occupation',
  enrollmentStatus: 'Customer Enrollment Status',
  creditStatus: 'Credit Status',
  daysPaidLoan: 'Days Since Last Credit Paid',
  daysActivityEnrollment: 'Number of days since last enrollment activity',
  residenceRegion: 'Region of Residence',
  residenceCity: 'City of Residence',
  executionDate: 'Execution Date',
  numberUniqueCustomers: 'Number of Unique Customers',
  numberCustomersFiltered: 'Number of Customers Filtered',
  campaign: 'Campaign',
  filterPreview: 'Filter Preview',
  editCampaign: 'Edit Campaign',
  createCampaign: 'Create Campaign',
  years: 'Years',
  alphabeticCharactersOnly: 'Alphabetic Characters Only',
  unfinished: 'Unfinished',
  culminated: 'Culminated',
  isSmsCampaign: 'Is SMS Campaign',
  isEmailCampaign: 'Is Email Campaign',
  nameOfClient: 'Name of Client',
  identificationOdClient: 'Identification of Client',
  statusOfLastLoan: 'Status of Last Loan',
  actions: 'Actions',
  clientFilters: 'Client Filters',
};

export const campaignsEs = {
  campaignType: 'Tipo de campaña',
  corporate: 'Corporativos',
  loyalty: 'Fidelización',
  positioning: 'Posicionamiento',
  promotional: 'Promocionales',
  remarketing: 'Remarketing',
  campaignName: 'Nombre de campaña',
  automatic: 'Automático',
  totalCustomersFiltered: 'Total clientes filtrados',
  thereIsNoInformationOnCampaigns: 'No existe información de campañas',
  overview: 'Vista general',
  detailsCampaign: 'Detalles de la campaña',
  automaticCampaign: 'Campaña automática',
  executionFrequency: 'Frecuencia de ejecución',
  contactFrequency: 'Frecuencia de contacto',
  idTemplateSendinblue: 'ID de la plantilla de correo electrónico',
  messageCampaign: 'Mensaje campaña SMS',
  age: 'Edad',
  genderType: 'Tipo de género',
  approvedLoans: 'Cantidad de préstamos aprobados',
  occupation: 'Ocupación',
  enrollmentStatus: 'Estado del enrolamiento del cliente',
  creditStatus: 'Estado del crédito',
  daysPaidLoan: 'Días desde el último crédito pagado',
  daysActivityEnrollment:
    'Cantidad de días desde la última actividad de enrolamiento',
  residenceRegion: 'Región de residencia',
  residenceCity: 'Ciudad de residencia',
  executionDate: 'Fecha ejecución',
  numberUniqueCustomers: 'Cantidad clientes únicos',
  numberCustomersFiltered: 'Cantidad clientes filtrados',
  campaign: 'Campaña',
  filterPreview: 'Pre-visualización de filtros',
  editCampaign: 'Editar campaña',
  createCampaign: 'Crear campaña',
  years: 'Años',
  alphabeticCharactersOnly: 'Sólo caracteres alfabéticos',
  unfinished: 'No culminado',
  culminated: 'Culminado',
  isSmsCampaign: 'Es campaña SMS',
  isEmailCampaign: 'Es campaña de correo electrónico',
  nameOfClient: 'Nombre del cliente',
  identificationOdClient: 'Identificación del cliente',
  statusOfLastLoan: 'Estado del último préstamo',
  actions: 'Acciones',
  clientFilters: 'Clientes filtrados',
};
