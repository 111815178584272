import funderService from 'src/services/fundersService';

export const SET_LIST_FUNDERS = '@funder/set-list-funders'

export function getFunders() {
    return async (dispatch) => {
        try {
            const response = await funderService.GetAllFunders();            
            dispatch(setListFunders(response.data));
        } catch (error) {            
            throw error;
        }
    };
}

export function setListFunders(funders) {
    return {
        type: SET_LIST_FUNDERS,
        funders
    }
}