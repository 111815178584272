import {
  CLEAN_COMPANY,
  SET_ASSETS_AUTOMOBILES,
  SET_ASSETS_LIFEINSURANCE,
  SET_ASSETS_OTHERS,
  SET_ASSETS_PROPERTIES,
  SET_ASSETS_STOCKBONDS,
  SET_COMPANY_DETAILS,
  SET_COMPANY_FINANCIALINFO,
  SET_COMPANY_LOANS,
  SET_COMPANY_LOAN_REQUESTS,
  SET_FINANCIAL_INFO_ASSETS,
  SET_FINANCIAL_INFO_BUSINESS_DEBT,
  SET_FINANCIAL_INFO_GENERAL_ASSETS,
  SET_FINANCIAL_INFO_LOAN_PROCEEDS,
  SET_FINANCIAL_INFO_SOURCE_INCOMES,
  SET_GENERAL_INFORMATION_BANKS,
  SET_GENERAL_INFORMATION_COMPANY,
  SET_GENERAL_INFORMATION_CONTACTS,
  SET_GENERAL_INFORMATION_OWNERS,
  SET_GUARANTORS,
  SET_GUARANTOR_LEGAL_QUESTIONS,
  SET_GUARANTOR_SOURCE_INCOMES,
  SET_LEGAL_QUESTIONS,
  SET_OWNER_DETAILS,
  SET_SELECTEDTAB,
  SET_SELECTED_STEP,
  SET_SELECTED_STEP_DETAILS,
  SET_TOTAL_ASSETS_OF_COMPANY,
} from '../actions/companyActions';

const initialState = {
  id: 0,
  entityId: 0,
  originatorId: '',
  generalInformation: {
    company: {
      companyName: '',
      typeOfBussines: '',
      identificationType: '',
      identificationNumber: '',
      website: '',
      natureOfBussines: '',
      addressId: null,
      address: '',
      dateStablished: new Date(),
      zipCode: '',
      appartmentUnit: '',
      city: '',
      cityName: '',
      region: '',
      regionName: '',
      numberOfYearsOperating: '',
      numberOfCurrentEmployees: '',
      projectNumberNewEmployees: '',
      additionalInformation: '',
    },
  },
  guarantors: null,
  guarantorSelected: {
    personalInformation: null,
    financialInformation: {
      assets: {
        loaded: false,
      },
      sourcesIncome: {
        isSetted: false,
        sourcesIncome: [],
      },
    },
    legalQuestions: {
      isSetted: false,
      liensOrJudgments: {
        hasLiensOrJudgments: false,
        liensOrJudgmentsAgainstWhom: '',
        liensOrJudgmentsAmount: '',
        liensOrJudgmentsLien: '',
        liensOrJudgmentsDescription: '',
      },
      lawsuits: {
        hasLawsuits: false,
        lawsuitsAgainstWhom: '',
        lawsuitsDescription: '',
      },
      bankruptcy: {
        hasFieldBankruptcy: false,
        bankruptcyOutcome: '',
        bankruptcyDescription: '',
      },
    },
  },
  financialInformation: {
    loanProceeds: { id: 0 },
    assets: { loaded: false },
    liabilities: {},
    sourcesIncome: {
      isSetted: false,
      sourcesIncome: [],
    },
    expensesAndIncome: null,
  },
  legalQuestions: {
    isSetted: false,
    liensOrJudgments: {
      hasLiensOrJudgments: false,
      liensOrJudgmentsAgainstWhom: '',
      liensOrJudgmentsAmount: '',
      liensOrJudgmentsLien: '',
      liensOrJudgmentsDescription: '',
    },
    lawsuits: {
      hasLawsuits: false,
      lawsuitsAgainstWhom: '',
      lawsuitsDescription: '',
    },
    bankruptcy: {
      hasFieldBankruptcy: false,
      bankruptcyOutcome: '',
      bankruptcyDescription: '',
    },
    subsidiaries: {
      hasSubsidiaries: false,
      subsidiariesNames: '',
    },
    relationship: {
      hasRelationshipWithOriginator: false,
      relationshipNames: '',
    },
  },
  authorization: {},
  company: {
    generalInformation: null,
    ownerSelected: null,
    selectedTab: null,
  },
  loans: {
    isSetted: false,
    loans: [],
  },
  loanRequest: {
    isSetted: false,
    loanRequests: [],
  },
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY_DETAILS:
      return {
        ...state,
        ...action.companyDTO,
        generalInformation: action.payload,
      };
    case SET_GENERAL_INFORMATION_COMPANY: {
      return {
        ...state,
        id: action.id,
        originatorId: action.originatorId,
        entityId: action.entityId,
        generalInformation: {
          ...state.generalInformation,
          company: action.company,
        },
      };
    }
    case SET_GENERAL_INFORMATION_CONTACTS: {
      return {
        ...state,
        generalInformation: {
          ...state.generalInformation,
          contacts: action.contacts,
        },
      };
    }
    case SET_GENERAL_INFORMATION_OWNERS:
      return {
        ...state,
        generalInformation: {
          ...state.generalInformation,
          owners: action.owners,
        },
      };
    case SET_GENERAL_INFORMATION_BANKS: {
      return {
        ...state,
        generalInformation: {
          ...state.generalInformation,
          banks: action.banks,
        },
      };
    }
    case SET_FINANCIAL_INFO_LOAN_PROCEEDS: {
      return {
        ...state,
        financialInformation: {
          ...state.financialInformation,
          loanProceeds: action.loanProceeds,
        },
      };
    }
    case SET_COMPANY_FINANCIALINFO: {
      return {
        ...state,
        financialInformation: {
          ...state.financialInformation,
          expensesAndIncome: action.payload,
        },
      };
    }
    case SET_FINANCIAL_INFO_BUSINESS_DEBT: {
      return {
        ...state,
        financialInformation: {
          ...state.financialInformation,
          businessDebts: action.businessDebts,
        },
      };
    }
    case SET_FINANCIAL_INFO_ASSETS: {
      return {
        ...state,
        financialInformation: {
          ...state.financialInformation,
          assets: action.assets,
        },
      };
    }
    case SET_FINANCIAL_INFO_GENERAL_ASSETS: {
      return {
        ...state,
        financialInformation: {
          ...state.financialInformation,
          assets: {
            ...state.financialInformation.assets,
            ...action.assets,
          },
        },
      };
    }
    case SET_ASSETS_AUTOMOBILES: {
      return {
        ...state,
        financialInformation: {
          ...state.financialInformation,
          assets: {
            ...state.financialInformation.assets,
            automobiles: action.automobiles,
          },
        },
      };
    }
    case SET_ASSETS_OTHERS: {
      return {
        ...state,
        financialInformation: {
          ...state.financialInformation,
          assets: {
            ...state.financialInformation.assets,
            others: action.others,
          },
        },
      };
    }
    case SET_ASSETS_LIFEINSURANCE: {
      return {
        ...state,
        financialInformation: {
          ...state.financialInformation,
          assets: {
            ...state.financialInformation.assets,
            lifeInsurance: action.lifeInsurance,
          },
        },
      };
    }
    case SET_ASSETS_STOCKBONDS: {
      return {
        ...state,
        financialInformation: {
          ...state.financialInformation,
          assets: {
            ...state.financialInformation.assets,
            stocksAndBonds: action.stocksAndBonds,
          },
        },
      };
    }
    case SET_ASSETS_PROPERTIES: {
      return {
        ...state,
        financialInformation: {
          ...state.financialInformation,
          assets: {
            ...state.financialInformation.assets,
            realState: action.realState,
          },
        },
      };
    }
    case SET_FINANCIAL_INFO_SOURCE_INCOMES: {
      return {
        ...state,
        financialInformation: {
          ...state.financialInformation,
          sourcesIncome: action.sourceIncome,
        },
      };
    }
    case SET_OWNER_DETAILS:
      return {
        ...state,
        ownerSelected: action.payload,
      };
    case SET_SELECTEDTAB: {
      return {
        ...state,
        selectedTab: action.payload,
      };
    }
    case SET_SELECTED_STEP: {
      return {
        ...state,
        selectedStep: action.selectedStep,
      };
    }
    case SET_LEGAL_QUESTIONS: {
      return {
        ...state,
        legalQuestions: {
          ...state.legalQuestions,
          ...action.legalQuestions,
        },
      };
    }
    case CLEAN_COMPANY: {
      return {
 ...initialState, entityId: 0, id: 0, originatorId: '',
};
    }
    case SET_GUARANTORS: {
      return {
        ...state,
        guarantors: action.payload,
      };
    }
    case SET_GUARANTOR_LEGAL_QUESTIONS: {
      return {
        ...state,
        guarantorSelected: {
          ...state.guarantorSelected,
          legalQuestions: action.legalQuestions,
        },
      };
    }
    case SET_GUARANTOR_SOURCE_INCOMES: {
      return {
        ...state,
        guarantorSelected: {
          ...state.guarantorSelected,
          financialInformation: {
            ...state.guarantorSelected.financialInformation,
            sourcesIncome: {
              ...state.guarantorSelected.financialInformation.sourcesIncome,
              ...action.sourceIncomeGuarantor,
            },
          },
        },
      };
    }
    case SET_TOTAL_ASSETS_OF_COMPANY: {
      return {
        ...state,
        financialInformation: {
          ...state.financialInformation,
          assets: {
            ...state.financialInformation.assets,
            totalAssets: action.totalAssets,
          },
        },
      };
    }
    case SET_COMPANY_LOAN_REQUESTS: {
      return {
        ...state,
        loanRequest: action.loanRequest,
      };
    }
    case SET_COMPANY_LOANS: {
      return {
        ...state,
        loans: action.loans,
      };
    }
    case SET_SELECTED_STEP_DETAILS: {
      return {
        ...state,
        selectedStepDetails: action.selectedStepDetails,
      };
    }

    default:
      return state;
  }
};

export default companyReducer;
