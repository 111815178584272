import routes from 'src/config/api.routes';
import axiosService from './axiosService';

class UsersService {
  GetAllUsers = async (filters) => axiosService.get(routes.users + (filters ?? ''));

  GetUsersByName = async (name, filters) => axiosService.get(`${routes.users}/by-name/${name}${filters}`);

  GetUserById = async (id) => axiosService.get(`${routes.users}/${id}`);

  UpdateUser = async (userDTO) => axiosService.put(`${routes.users}/putUser`, userDTO);

  CreateUser = async (NewUserDTO) => axiosService.post(`${routes.users}/postNewUser`, NewUserDTO);

  DisableUser = async (email, comment) => axiosService.put(`${routes.users}/disableUser/${email}/${comment}`);

  EnableUser = async (email, comment) => axiosService.put(`${routes.users}/enableUser/${email}/${comment}`);

  DeleteUser = async (email, comment) => axiosService.delete(`${routes.users}/deleteUser/${email}/${comment}`);

  getAdministrativeUsers = async () => axiosService.get(`${routes.users}/admin-users`);

  GenerateCodesPassword = async (Email) => axiosService.post(
      `${routes.account}/forgotPassword/true`,
      JSON.stringify(Email),
    );

  CreatePassword = async (InfoCreatePassword) => axiosService.post(`${routes.account}/resetPassword`, InfoCreatePassword);
}

const usersService = new UsersService();
export default usersService;
