export const customerListEn = {
  Actions: 'Actions',
  Assigned: 'Assigned',
  business: 'Business',
  clientHasBeenDeletedButFilesInCloud:
    'The client has been deleted, but your files in the cloud persist',
  Company: 'Company',
  createNew: 'Create New',
  creationDate: 'Creation Date',
  customerCannotBeRemoved:
    'The borrower cannot be removed, there is an active loan',
  customerCannotBeRemovedLoanRequest:
    'Sorry it is not possible to remove borrower because there are active requests',
  customerList: 'Borrower List',
  customerNoFoundOrInfoIncomplete:
    'The borrower does not exist with the identification document you entered',
  customer: 'Borrower',
  Dashboard: 'Dashboard',
  'data type': 'Data Type',
  'Date type': 'Date Type',
  deleteCustomerSorry:
    'You are not allowed to perform this borrower deletion action. Please contact us',
  clientWasSuccessfullyRemoved: 'The client was successfully removed.',
  Export: 'Export',
  fiscalInformation: 'Fiscal Information',
  Identification: 'Identification',
  Import: 'Import',
  incomeAndExpenses: 'INCOME AND EXPENSES',
  IncomeandExpenses: 'Income and Expenses',
  legalRepresentative: 'Is a Legal Representative? ',
  isPrincipalContact: 'Is Principal Contact? ',
  liabilities: 'Liabilities',
  mainActivity: 'Main Activity',
  Management: 'Management',
  monthlyExpenses: 'Monthly Expenses',
  monthlyIncome: 'Monthly Income',
  Name: 'Name',
  nameSearch: 'Enter Borrower Name or Email',
  None: 'None',
  notAllowedToPerformThisAction:
    'You are not allowed to perform this action. Please contact us',
  Originator: 'Originator',
  Person: 'Person',
  Phone: 'Phone',
  position: 'Title / Position',
  requestHasBeenCreated:
    'The application has been created and is being processed',
  Search: 'Search',
  'Search customers': 'Search Customers',
  'Sequence contains more than one element':
    'Sequence contains more than one element',
  totalAssets: 'Total Assets',
  totalEquity: 'Total Equity',
  totalLiabilities: 'Total Liabilities',
  'UNREGISTERED IDENTIFICATION': 'UNREGISTERED IDENTIFICATION',
  userDoesNotExistsOrNotProper:
    "The user doesn't exists or was not properly created.",
  informationSuccessfullyUploaded: 'Information successfully uploaded.',
};

export const customerListEs = {
  Actions: 'Acciones',
  Assigned: 'Asignado',
  business: 'Business',
  clientHasBeenDeletedButFilesInCloud:
    'El cliente ha sido eliminado, pero sus archivos en la nube persisten',
  company: 'Empresa',
  createNew: 'Crear nuevo',
  creationDate: 'Fecha de creación',
  customer: 'Clientes',
  customerCannotBeRemoved:
    'El cliente no puede ser eliminado, tiene un préstamo activo',
  customerCannotBeRemovedLoanRequest:
    'Lo sentimos no es posible eliminar cliente porque tiene solicitudes activas.',
  customerList: 'Listado de clientes',
  customerNoFoundOrInfoIncomplete:
    'El cliente no puede ser encontrado o su información está incompleta',
  Dashboard: 'Tablero',
  'data type': 'Tipo de Dato',
  'Date type': 'Tipo de dato',
  deleteCustomerSorry:
    'No tienes permitido ejecutar está acción de eliminar cliente. Por favor comunicate con nosotros',
  clientWasSuccessfullyRemoved: 'El cliente fue eliminado exitosamente.',
  Export: 'Exportar',
  fiscalInformation: 'Informacion Tributaria',
  Identification: 'Identificación',
  Import: 'Importar',
  incomeAndExpenses: 'INGRESOS Y EGRESOS',
  IncomeandExpenses: 'Ingresos y Egresos',
  legalRepresentative: 'Es un representante legal?',
  isPrincipalContact: 'Es contacto principal? ',
  liabilities: 'Pasivos',
  mainActivity: 'Actividad Principal',
  Management: 'Administración',
  monthlyExpenses: 'Egresos Mensuales',
  monthlyIncome: 'Ingresos mensuales',
  Name: 'Nombre',
  nameSearch: 'Ingrese nombre o correo del cliente',
  None: 'Ninguno',
  notAllowedToPerformThisAction:
    'No tienes permitido ejecutar está acción. Por favor comunicate con nosotros',
  Originator: 'Originador',
  Person: 'Persona',
  Phone: 'Teléfono',
  position: 'Cargo',
  requestHasBeenCreated: 'La solicitud ha sido creada y está siendo procesada',
  Search: 'Buscar',
  'Search customers': 'Buscar Cliente',
  'Sequence contains more than one element':
    'La secuencia contiene más de un elemento',
  'Sort By': 'Ordenar por',
  totalAssets: 'Total activos',
  totalEquity: 'Total patrimonio',
  totalLiabilities: 'Total Pasivos',
  'UNREGISTERED IDENTIFICATION': 'Identificación no registrada',
  userDoesNotExistsOrNotProper:
    'El usuario no existe o no se creó correctamente.',
  informationSuccessfullyUploaded: 'Información cargada exitosamente.',
};
