import { 
  SET_FACTOR_RULES, SET_FACTOR_RULE, SET_LOANS_TO_FACTOR, SET_DEBT_COLLECTION_MANAGEMENTS
 } from '../actions/debtCollectionActions';

const initialState = {
  factorRule: {
    isSetted: false,
    factorRule: {}
  },
  factorRules: {
    isSetted: false,
    factorRules: [],
    count: 0
  },
  loansToFactor: {
    isSetted: false,
    loansToFactor: [],
    count: 0
  },
  loanDebtCollectionManagements: {
    isSetted: false,
    debtCollectionManagements: []
  }
};
const debtCollectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FACTOR_RULES: {
      return {
        ...state,
        factorRules: action.payload
      };
    }
    case SET_FACTOR_RULE: {
      return {
        ...state,
        factorRule: action.payload
      };
    }
    case SET_LOANS_TO_FACTOR: {
      return {
        ...state,
        loansToFactor: action.payload
      };
    }
    case SET_DEBT_COLLECTION_MANAGEMENTS: {
      return {
        ...state,
        loanDebtCollectionManagements: action.payload
      };
    }

    default:
      return state;
  }
};

export default debtCollectionReducer;
