import routes from 'src/config/api.routes';
import axiosService from './axiosService';

class MasterService {
  GetMasterData = async () => (
    axiosService.get(`${routes.master}`)
  );

  GetEmploymentSituations = async () => (
    axiosService.get(`${routes.master}/employment-situations/`)
  );

  GetContractTypes = async () => (
    axiosService.get(`${routes.master}/contract-types/`)
  );

  GetProfessions = async () => (
    axiosService.get(`${routes.master}/professions/`)
  );

  getCompanyTypes = async () => (
    axiosService.get(`${routes.companyTypes}`)
  );

}

const masterService = new MasterService();

export default masterService;