/* eslint-disable no-param-reassign */
import { SET_LIST_FUNDERS } from 'src/store/actions/funderActions';

const initialState = {
  funderList: []
};

const FounderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LIST_FUNDERS: {
      return { ...state, funderList: action.funders }
    }
    default: {
      return state;
    }
  }
};

export default FounderReducer;