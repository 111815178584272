import axios from './axiosService';
import routes from 'src/config/api.routes';

class GuarantorService {

  //// Personal Information
  GetGuarantor = async guarantorId => (
    await axios.get(`${routes.relatedParties}/${guarantorId}/personal-info-by-id`)
  );
  CreateGuarantor = async (entityId, guarantorId, body) => (
    await axios.post(`${routes.relatedParties}/${entityId}/create-personal-info/${guarantorId}`, body)
  );
  DeleteBusinessOfGuarantor = async businessId => (
    await axios.delete(`${routes.relatedParties}/delete-related-party-business/${businessId}`)
  );

  //// Liabilities
  GetLiabilitiesOfGuarantor = async guarantorId => (
    await axios.get(`${routes.company}/guarantor/${guarantorId}/financial-info/liabilities`)
  );
  UpdateGeneralLiabilitiesOfGuarantor = async body =>
    await axios.post(
      `${routes.company}/guarantor/financial-info/general-liabilities`,
      body
    );

  DeleteOutstandingLoansOfGuarantor = async loanId => (
    await axios.delete(`${routes.company}/guarantor/financial-info/liabilities/outstanding-loans/${loanId}`)
  );
  UpdateOutstandingLoansOfGuarantor = async loans => (
    await axios.post(`${routes.company}/guarantor/financial-info/liabilities/outstanding-loans`, loans)
  );

  DeleteOtherLiabilitiesOfGuarantor = async otherId => (
    await axios.delete(`${routes.company}/guarantor/financial-info/liabilities/other-liabilities/${otherId}`)
  );
  UpdateOtherLiabilitiesOfGuarantor = async body => (
    await axios.post(`${routes.company}/guarantor/financial-info/liabilities/other-liabilities`, body)
  );

  DeleteUnpaidTaxesLiabilitiesOfGuarantor = async unpaidTaxId => (
    await axios.delete(`${routes.company}/guarantor/financial-info/liabilities/unpaid-taxes/${unpaidTaxId}`)
  );
  UpdateUnpaidTaxesLiabilitiesOfGuarantor = async unpaidTaxes => (
    await axios.post(`${routes.company}/guarantor/financial-info/liabilities/unpaid-taxes`, unpaidTaxes)
  );

  DeleteCreditCardBalanceOfGuarantor = async cardId => (
    await axios.delete(`${routes.company}/guarantor/financial-info/liabilities/credit-cards/${cardId}`)
  );
  UpdateCreditCardBalancesOfGuarantor = async cardBalances => (
    await axios.post(`${routes.company}/guarantor/financial-info/liabilities/credit-cards`, cardBalances)
  );

  //// Assets
  GetAssetsOfGuarantor = async guarantorId => (
    await axios.get(`${routes.company}/guarantor/${guarantorId}/financial-info/assets`)
  );
  UpdateAssetsGuarantor = async (guarantorId, assets) => (
    await axios.post(`${routes.relatedParties}/${guarantorId}/general-assets`, assets)
  );

  UpdateLifeInsuranceOfGuarantor = async lifeInsurance => (
    await axios.put(`${routes.company}/guarantor/financial-info/assets/life-insurance`, lifeInsurance)
  );

  UpdateAutomobilesOfGuarantor = async automobiles => (
    await axios.put(`${routes.company}/guarantor/financial-info/assets/automobiles`, automobiles)
  );
  DeleteAutomobile = async (guarantorId, automobileId) => (
    await axios.delete(`${routes.company}/guarantor/${guarantorId}/financial-info/assets/automobiles/${automobileId}`)
  );

  UpdateOtherAssetsOfGuarantor = async assets => (
    await axios.put(`${routes.company}/guarantor/financial-info/assets/others`, assets)
  );
  DeleteOtherAsset = async (guarantorId, assetId) => (
    await axios.delete(`${routes.company}/guarantor/${guarantorId}/financial-info/assets/others/${assetId}`)
  );

  UpdateStocksBondsOfGuarantor = async (guarantorId, stocksBonds) => (
    await axios.post(`${routes.relatedParties}/${guarantorId}/stocks-and-bonds`, stocksBonds)
  );
  DeleteStockBond = async (guarantorId, stockBondId) => (
    await axios.delete(`${routes.company}/guarantor/${guarantorId}/financial-info/assets/stock-bond/${stockBondId}`)
  );

  UpdatePropertiesOfGuarantor = async (guarantorId, properties) => (
    await axios.post(`${routes.relatedParties}/${guarantorId}/real-state`, properties)
  );
  DeleteProperty = async (guarantorId, propertyId) => (
    await axios.delete(`${routes.company}/guarantor/${guarantorId}/financial-info/assets/property/${propertyId}`)
  );

}
const guarantorService = new GuarantorService();
export default guarantorService;
