import {
  SET_LOAN_VALIDATIONS,
  SET_LOAN_ENTITY,
  SET_LOAN_TRANSACTIONS,
  SET_DISBURSEMENT_OPEN
} from '../actions/loanActions';

const initialState = {
  details: {
    loaded: false
  },
  disburse: {
    open: false
  },
  loanTransactions: null
};

const loanReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOAN_VALIDATIONS: {
      return {
        ...state,
        validations: action.validations
      };
    }
    case SET_LOAN_ENTITY: {
      return {
        ...state,
        details: {
          ...action.loanEntity
        }
      };
    }
    case SET_LOAN_TRANSACTIONS: {
      return {
        ...state,
        loanTransactions: action.transactions
      };
    }
    case SET_DISBURSEMENT_OPEN: {
      return {
        ...state,
        disburse: {
          open: action.open
        }
      };
    }
    default: {
      return state;
    }
  }
};

export default loanReducer;
