/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import {
  colors as colorsMaterial,
  createTheme,
  responsiveFontSizes,
} from '@mui/material';
import * as locales from '@mui/material/locale';
import { useTranslation } from 'react-i18next';
import typography from './typography';
import { softShadows, strongShadows } from './shadows';
import { THEMES } from '../constants';

const colors = {
  ...colorsMaterial,
  custom: {
    grey: {
      1: '#F6F7F7',
    },
  },
};

const baseConfig = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
  },
};

const themeConfigs = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600],
          },
        },
      },
    },
    palette: {
      mode: 'light',
      action: {
        active: colors.blueGrey[600],
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
      },
      primary: {
        main: colors.indigo[600],
      },
      secondary: {
        main: '#5850EC',
      },
      error: {
        main: '#7f0000',
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
      },
      button: {
        highlight: colors.custom.grey[1],
      },
    },
    shadows: softShadows,
  },
  {
    name: THEMES.AZUL,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600],
          },
        },
      },
    },
    palette: {
      mode: 'light',
      action: {
        active: colors.blueGrey[600],
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
      },
      primary: {
        main: '#00408e',
      },
      secondary: {
        main: '#00408e',
      },
      error: {
        main: '#7f0000',
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
      },
      button: {
        highlight: colors.custom.grey[1],
      },
    },
    shadows: softShadows,
  },
  {
    name: THEMES.ONE_DARK,
    palette: {
      mode: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)',
      },
      background: {
        default: '#282C34',
        dark: '#1c2025',
        paper: '#282C34',
      },
      primary: {
        main: '#2f4a9c',
      },
      secondary: {
        main: '#2f4a9c',
      },
      error: {
        main: '#7f0000',
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb',
      },
    },
    shadows: strongShadows,
  },
  {
    name: THEMES.UNICORN,
    palette: {
      mode: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)',
      },
      background: {
        default: '#2a2d3d',
        dark: '#222431',
        paper: '#2a2d3d',
      },
      primary: {
        main: '#a67dff',
      },
      secondary: {
        main: '#a67dff',
      },
      error: {
        main: '#7f0000',
      },
      text: {
        primary: '#f6f5f8',
        secondary: '#9699a4',
      },
    },
    shadows: strongShadows,
  },
  {
    name: THEMES.MICREDI,
    palette: {
      mode: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)',
      },
      background: {
        default: '#FFFFFF',
        dark: '#FFFFFF',
        paper: '#FFFFFF',
      },
      primary: {
        main: '#4CE079',
      },
      secondary: {
        main: '#30894B',
      },
      error: {
        main: '#7f0000',
      },
      text: {
        primary: '#2A2D3D',
        secondary: '#2A2D3D',
      },
    },
    shadows: strongShadows,
  },
  {
    name: THEMES.ACELERA,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600],
          },
        },
      },
    },
    palette: {
      mode: 'light',
      action: {
        active: colors.blueGrey[600],
      },
      background: {
        default: colors.common.white,
        dark: '#fff',
        paper: colors.common.white,
      },
      primary: {
        main: '#2f4a9c',
      },
      secondary: {
        main: '#2f4a9c',
        logo: '#fff',
      },
      error: {
        main: '#7f0000',
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
      },
      button: {
        highlight: colors.custom.grey[1],
      },
    },
    shadows: softShadows,
  },
];

export function createMuiTheme(settings = {}) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { i18n } = useTranslation();
  let themeConfig = themeConfigs.find((theme) => theme.name === settings.theme);
  if (!themeConfig) {
    [themeConfig] = themeConfigs;
  }

  let theme = createTheme(
    _.merge({}, baseConfig, themeConfig, { direction: settings.direction }),
    Object.entries(locales).find((locale) => locale[0].startsWith(i18n.sublanguage)),
  );

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
