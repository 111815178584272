export const fundersEn = {
  'Create new funder': 'Create new Funder',
  CreateFunder: 'Create Funder',
  'Error creating funder': 'Error creating funder',
  Funders: 'Funders',
  FundersList: 'Funders List',
  'Identification of the responsible party':
    'Identification of the Responsible Party',
  'Information successfully uploaded': 'Information successfully uploaded',
  'Successful registration': 'Successful registration',
  'The Entity ID is required in order to create a funder':
    'The Entity ID is required in order to create a funder',
};

export const fundersEs = {
  'Create new funder': 'Crear nuevo financiador',
  CreateFunder: 'Crear financiador',
  'Error creating funder': 'Error al crear el financiador',
  Funders: 'Financiadores',
  FundersList: 'Lista de Financiadores',
  'Identification of the responsible party': 'Identificación del responsable',
  'Information successfully uploaded': 'Información cargada con éxito',
  'Successful registration': 'Registro exitoso',
  'The Entity ID is required in order to create a funder':
    'El ID de la entidad es necesario para crear un financiador',
};
