import companyService from 'src/services/companyService';
import { finishLoading, setMessageSnackbar, startLoading } from './uiActions';

export const SET_COMPANY_DETAILS = '@customer/set-company-details';
export const SET_OWNER_DETAILS = '@company/set-owner-details';
export const SET_SELECTEDTAB = '@company/set-header-details';
export const CLEAN_COMPANY = '@company/clean-company';
export const SET_SELECTED_STEP = '@company/set-selected-step';
export const SET_LEGAL_QUESTIONS = '@company/set-legal-questions';
export const SET_GENERAL_INFORMATION_COMPANY = '@company/set-general-information-company';
export const SET_GENERAL_INFORMATION_CONTACTS = '@company/set-general-information-contacts';
export const SET_GENERAL_INFORMATION_OWNERS = '@company/set-general-information-owners';
export const SET_GUARANTORS = '@company/set-guarantors';
export const SET_GUARANTOR_LEGAL_QUESTIONS = '@company/set-guarantor-legal-questions';
export const SET_FINANCIAL_INFO_LOAN_PROCEEDS = '@company/set-financial-info-loan-proceeds';
export const SET_FINANCIAL_INFO_BUSINESS_DEBT = '@company/set-financial-info-business-debt';
export const SET_FINANCIAL_INFO_SOURCE_INCOMES = '@company/set-financial-info-source-incomes';
export const SET_FINANCIAL_INFO_ASSETS = '@company/set-financial-info-all-assets';
export const SET_FINANCIAL_INFO_GENERAL_ASSETS = '@company/set-financial-info-general-assets';
export const SET_ASSETS_AUTOMOBILES = '@company/set-assets-automobiles';
export const SET_ASSETS_OTHERS = '@company/set-assets-others';
export const SET_ASSETS_LIFEINSURANCE = '@company/set-assets-lifeInsurance';
export const SET_ASSETS_STOCKBONDS = '@company/set-assets-stockBonds';
export const SET_ASSETS_PROPERTIES = '@company/set-assets-properties';
export const SET_GUARANTOR_SOURCE_INCOMES = '@company/set-guarantors-source-incomes';
export const SET_TOTAL_ASSETS_OF_COMPANY = '@company/set-total-assets-of-company';
export const SET_COMPANY_LOAN_REQUESTS = '@company/set-company-loan-requests';
export const SET_COMPANY_LOANS = '@company/set-company-loans';
export const SET_COMPANY_FINANCIALINFO = '@company/set-company-financial-info';
export const SET_GENERAL_INFORMATION_BANKS = '@company/set-general-information-banks';
export const SET_SELECTED_STEP_DETAILS = '@company/set-selected-step-details';

export const setCompanyDetails = (company) => {
  const addressDTO = company?.companyDto?.addressDto.filter((a) => a.isPrimary);
  const companyDTO = {
    id: company?.companyDto?.id,
    entityId: company?.id,
    originatorId: company?.companyDto?.companyIdentificationDTO?.originatorId,
    generalInformationProd: {
      company: {
        companyName: company?.companyDto.name ?? '',
        typeOfBussines: company?.companyDto.businessType ?? '',
        identificationType:
          company?.companyDto.companyIdentificationDTO?.identificationDTO
            .identificationType ?? '',
        identificationNumber:
          company?.companyDto.companyIdentificationDTO?.identificationDTO
            .identificationNumber ?? '',
        website: company?.companyDto.website ?? '',
        natureOfBussines: company?.companyDto.businessNature ?? '',
        addressId: addressDTO?.length && addressDTO[0].id,
        address: addressDTO?.length && addressDTO[0].address,
        dateStablished: company?.companyDto.dateEstablished ?? new Date(),
        zipCode: addressDTO?.length && addressDTO[0].postalCode,
        appartmentUnit: addressDTO?.length && addressDTO[0].address2,
        city:
          company?.companyDto.companyIdentificationDTO?.identificationDTO
            .issueCityCode ?? '',
        region:
          company?.companyDto.companyIdentificationDTO?.identificationDTO
            .regionCode ?? '',
        numberOfYearsOperating: company?.companyDto.numberOfYearsOperating,
        numberOfCurrentEmployees: company?.companyDto.currentEmployees ?? '',
        projectNumberNewEmployees: company?.companyDto.projectedEmployees ?? '',
        additionalInformation: company?.companyDto.additionalInformation,
      },
      contacts: company?.companyDto.companyContactDto,
      owners: [],
    },
    financialInformation: {
      loanProceeds: company?.companyDto?.companyLoanProceedsUsesDto,
      businessDebts: company?.companyDto?.companyDebtDto ?? [],
    },
    legalQuestions: {
      liensOrJudgments: {
        hasLiensOrJudgments:
          company?.companyDto?.legalQuestionsDto?.liensOrJudgments,
        liensOrJudgmentsAgainstWhom:
          company?.companyDto?.legalQuestionsDto?.liensOrJudgmentsAgainstTo,
        liensOrJudgmentsAmount:
          company?.companyDto?.legalQuestionsDto?.liensOrJudgmentsAmount,
        liensOrJudgmentsLien:
          company?.companyDto?.legalQuestionsDto?.liensOrJudgmentsType,
        liensOrJudgmentsDescription:
          company?.companyDto?.legalQuestionsDto?.liensOrJudgmentsDescription,
      },
      lawsuits: {
        hasLawsuits: company?.companyDto?.legalQuestionsDto?.lawsuits,
        lawsuitsAgainstWhom:
          company?.companyDto?.legalQuestionsDto?.lawsuitsAgainstTo,
        lawsuitsDescription:
          company?.companyDto?.legalQuestionsDto?.lawsuitsDescription,
      },
      bankruptcy: {
        hasFieldBankruptcy: company?.companyDto?.legalQuestionsDto?.bankruptcy,
        bankruptcyOutcome:
          company?.companyDto?.legalQuestionsDto?.bankruptcyOutcome,
        bankruptcyDescription:
          company?.companyDto?.legalQuestionsDto?.bankruptcyOutcomeDesc,
      },
      subsidiaries: {
        hasSubsidiaries: company?.companyDto?.legalQuestionsDto?.subsidiaries,
        subsidiariesNames:
          company?.companyDto?.legalQuestionsDto?.subsidiariesNames,
      },
      relationship: {
        hasRelationshipWithOriginator:
          company?.companyDto?.legalQuestionsDto?.relationshipWithOriginator,
        relationshipNames:
          company?.companyDto?.legalQuestionsDto
            ?.relationshipWithOriginatorPerson,
      },
    },
  };
  return {
    type: SET_COMPANY_DETAILS,
    payload: company,
    companyDTO,
  };
};
export const getOwnerDetails = (data) => ({
  type: SET_OWNER_DETAILS,
  payload: data,
});

/// MANAGE UI TABS EDIT COMPANY
export const setSelectedTab = (data) => ({
  type: SET_SELECTEDTAB,
  payload: data,
});
export const setSelectedStep = (selectedStep) => ({
  type: SET_SELECTED_STEP,
  selectedStep,
});
export const setSelectedStepDetails = (selectedStepDetails) => ({
  type: SET_SELECTED_STEP_DETAILS,
  selectedStepDetails,
});
export const cleanCompanyDetails = () => ({ type: CLEAN_COMPANY });

/// General Information
// Company
export const setFinancialInformationOfCompany = (data) => ({
  type: SET_COMPANY_FINANCIALINFO,
  payload: data,
});
const setGeneralInformationCompany = (response) => {
  const {
    id: entityId,
    company,
    identification,
    address,
    originatorId,
    customer,
  } = response;
  const addressDTO = address?.find((a) => a.isPrimary);
  const companyDTO = {
    companyName: company?.name ?? '',
    typeOfBussines: company?.businessType ?? '',
    website: company?.website ?? '',
    natureOfBussines: company?.businessNature ?? '',
    ciiuCode: company?.ciiuCode ?? '',
    mainActivity: company?.mainActivity ?? '',
    numberOfYearsOperating: company?.numberOfYearsOperating ?? '',
    numberOfCurrentEmployees: company?.currentEmployees ?? '',
    projectNumberNewEmployees: company?.projectedEmployees ?? '',
    dateStablished: company?.dateEstablished,
    identificationType: identification?.identificationType ?? '',
    identificationTypeName: identification?.identificationTypes?.name ?? '',
    identificationNumber: identification?.identificationNumber ?? '',
    country: identification?.issueCountry ?? '',
    city: addressDTO?.cityCode ?? '',
    cityName: addressDTO?.cityName ?? '',
    region: addressDTO?.regionCode ?? '',
    regionName: addressDTO?.regionName ?? '',
    additionalInformation: customer?.additionalObservation ?? '',
    addressId: addressDTO?.id ?? null,
    address: addressDTO?.address1 ?? '',
    zipCode: addressDTO?.postalCode ?? '',
    appartmentUnit: addressDTO?.address2 ?? '',
  };
  return {
    type: SET_GENERAL_INFORMATION_COMPANY,
    company: companyDTO,
    id: company?.id ?? null,
    entityId,
    originatorId,
  };
};
export const getGeneralInformationCompany = (entityId) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await companyService.GetCompany(entityId);
    dispatch(setGeneralInformationCompany(response));
    dispatch(finishLoading());
  } catch (e) {
    dispatch(setGeneralInformationCompany({}));
    dispatch(finishLoading());
  }
};
export const UpdateGeneralInformationCompany = (values, success, failed) => async (dispatch, getState) => {
    try {
      dispatch(startLoading());
      const company = {
        Name: values.companyName,
        EntityId: getState().company.entityId,
        BusinessType: values.typeOfBussines,
        BusinessNature: values.natureOfBussines,
        DateEstablished: values.dateStablished,
        Website: values.website,
        NumberOfYearsOperating: values.numberOfYearsOperating,
        CurrentEmployees: values.numberOfCurrentEmployees,
        ProjectedEmployees: values.projectNumberNewEmployees,
        additionalInformation: values.additionalInformation,
        AddressDto: {
          Id: getState().company.generalInformation.company.addressId,
          CityCode: values.city,
          City: values.cityName,
          RegionCode: values.region,
          Region: values.regionName,
          CountryCode: values.country,
          Country: values.countryName,
          PostalCode: values.zipCode,
          IsPrimary: true,
          Address: values.address,
          Address2: values.appartmentUnit,
        },
        CompanyIdentificationDTO: {
          Name: values.companyName,
          LegalRepresentativeId: null,
          OriginatorId: values.originator,
          IdentificationDTO: {
            IdentificationType: values.identificationType,
            IdentificationNumber: values.identificationNumber,
            IssueCountryName: values.countryName,
            IssueCountry: values.country,
            RegionCode: values.region,
            Region: values.regionName,
            IssueCityCode: values.city,
            IssueCity: values.cityName,
          },
        },
      };

      const response = await companyService.UpdateCompany(company);
      dispatch(getGeneralInformationCompany(response.entity.id));
      success(response);
    } catch (e) {
      failed(e);
      dispatch(finishLoading());
    }
  };
export const getFinancialInformationOfCompany = (companyId) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await companyService.GetExpensesAndIncome(companyId);
    dispatch(setFinancialInformationOfCompany(response.data));
  } catch (e) {
    dispatch(setFinancialInformationOfCompany({}));
  } finally {
    dispatch(finishLoading());
  }
};
export const postFinancialInformationOfCompany = (body) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await companyService.UpdateExpensesAndIncome(body);
    dispatch(getFinancialInformationOfCompany(body.companyId));
    dispatch(setMessageSnackbar(response, 'success'));
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
  } finally {
    dispatch(finishLoading());
  }
};

const setContactsOfCompany = (contacts) => ({
  type: SET_GENERAL_INFORMATION_CONTACTS,
  contacts,
});

export const getContactsOfCompany = (failed) => async (dispatch, getState) => {
  try {
    dispatch(startLoading());
    const { id } = getState().company;
    const response = await companyService.GetContactsOfCompany(id);
    dispatch(setContactsOfCompany(response.data));
    dispatch(finishLoading());
  } catch (e) {
    dispatch(setContactsOfCompany([]));
    failed(e);
    dispatch(finishLoading());
  }
};

// Contacts
export const updateContactsOfCompany = (contacts, success, failed) => async (dispatch, getState) => {
    try {
      dispatch(startLoading());
      const { id } = getState().company;
      const contactsDTO = contacts.map((contact) => ({
        CompanyId: id,
        Id: contact.id,
        Name: contact.name,
        Active: true,
        isLegalRepresentative: contact.isLegalRepresentative,
        position: contact.position,
        Country: contact.country,
        NumericCountryCode: contact.country === 'US' ? '+1' : '+57',
        Region: contact.region,
        City: contact.city,
        IdentificationType: contact.identificationType,
        IdentificationNumber: contact.identificationNumber,
        CellPhone: contact.cellPhone,
        WorkPhone: contact.workPhone,
        Email: contact.email,
        Address: contact.address,
        IsPrimary: contact.isPrimary,
      }));
      const response = await companyService.UpdateContactsOfCompany(
        contactsDTO,
      );
      success(response);
      dispatch(getContactsOfCompany());
    } catch (e) {
      failed(e);
      dispatch(finishLoading());
    }
  };
// Owners
const setOwners = (owners) => {
  const ownersDTO = owners.map((owner) => {
    const addressDTO = owner?.entity?.address?.length > 0 && owner?.entity?.address[0];
    const identificationDTO = owner?.entity?.identification;
    return {
      id: owner.id,
      IdentificationId: identificationDTO?.id,
      identificationType: identificationDTO?.identificationType,
      identificationNumber: identificationDTO?.identificationNumber,
      nameOfOwner: owner?.name ?? '',
      title: owner?.title ?? '',
      percentageOfOwnership: owner?.ownershipPercentage ?? '',
      phone: owner?.phone ?? '',
      email: owner?.email ?? '',
      entityMainOwners: owner?.entityMainOwners ?? '',
      entityType: owner?.entityType ?? '',
      ownerType: owner?.shareholderType ?? '',
      addressId: addressDTO?.id,
      address: addressDTO?.address1 ?? '',
      zipCode: addressDTO?.postalCode ?? '',
      country: addressDTO?.countryCode ?? '',
      region: addressDTO?.regionCode ?? '',
      regionName: addressDTO?.regionName ?? '',
      city: addressDTO?.cityCode ?? '',
      cityName: addressDTO?.cityName ?? '',
      timeLived: addressDTO?.yearsInAddress ?? '',
      monthlyRent: addressDTO?.monthlyRentAmount ?? '',
      business: owner.shareholderBusinesses.map((business) => (business.id ? business : {
              businessName: business.businessName,
              ownershipPercentage: business.ownershipPercentage,
            })),
    };
  });
  return {
    type: SET_GENERAL_INFORMATION_OWNERS,
    owners: ownersDTO,
  };
};
export const getOwners = () => async (dispatch, getState) => {
  try {
    dispatch(startLoading());
    const companyId = getState().company.id;
    const owners = await companyService.GetOwners(companyId);
    dispatch(setOwners(owners));
    dispatch(finishLoading());
  } catch (e) {
    dispatch(setOwners([]));
    dispatch(setMessageSnackbar(e, 'error'));
    dispatch(finishLoading());
  }
};

export const updateOwners = (owners) => async (dispatch, getState) => {
  try {
    dispatch(startLoading());
    const { id: CompanyId, entityId, originatorId } = getState().company;
    const ownersDTO = owners.map((owner) => ({
      CompanyId,
      entityId,
      originatorId,
      Id: owner.id,
      Name: owner.nameOfOwner,
      Title: owner.title,
      OwnershipPercentage: owner.percentageOfOwnership,
      Phone: owner.phone,
      Email: owner.email,
      EntityType: owner.entityType,
      OwnerType: owner.ownerType,
      OwnerBusinessDTO: owner.business.filter(
        (b) => b.bussinessName !== '' && b.ownershipPercentage !== '',
      ),
      Identification: {
        id: owner.IdentificationId || 0,
        identificationType: owner.identificationType,
        identificationNumber: owner.identificationNumber,
      },
      AddressDTO: {
        Id: owner.addressId || 0,
        Address: owner.address,
        PostalCode: owner.zipCode,
        CountryCode: owner.country,
        RegionCode: owner.region,
        RegionName: owner.regionName,
        CityCode: owner.city,
        CityName: owner.cityName,
        YearsInAddress: owner.timeLived,
        MonthlyRentAmount: owner.monthlyRent,
      },
    }));
    const response = await companyService.UpdateOwners(CompanyId, ownersDTO);
    dispatch(getOwners(CompanyId));
    dispatch(setMessageSnackbar(response, 'success'));
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
    dispatch(finishLoading());
  }
};

export const deleteOwner = (ownerId) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await companyService.DeleteOwner(ownerId);
    dispatch(setOwners(response.data));
    dispatch(setMessageSnackbar(response, 'success'));
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
  } finally {
    dispatch(finishLoading());
  }
};

export const deleteContact = (contactId) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await companyService.DeleteContact(contactId);
    if (!response.Processed) {
      dispatch(setMessageSnackbar(response.message, 'error'));
    } else {
      dispatch(setMessageSnackbar(response.message, 'success'));
      dispatch(setContactsOfCompany(response.data));
    }
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
  } finally {
    dispatch(finishLoading());
  }
};

// Banks
const setBanksOfCompany = (banks) => ({
  type: SET_GENERAL_INFORMATION_BANKS,
  banks,
});
export const getBanksOfCompany = (failed) => async (dispatch, getState) => {
  try {
    dispatch(startLoading());
    const { id } = getState().company;
    const response = await companyService.GetBanksOfCompany(id);
    dispatch(setBanksOfCompany(response.data));
    dispatch(finishLoading());
  } catch (e) {
    dispatch(setBanksOfCompany([]));
    failed(e);
    dispatch(finishLoading());
  }
};
export const updateBankssOfCompany = (banks, success, failed) => async (dispatch, getState) => {
    try {
      dispatch(startLoading());
      const { id } = getState().company;
      const banksDTO = banks.map((bank) => ({
        CompanyId: id,
        Id: bank.id,
        accountNumber: bank.accountNumber,
        bankName: bank.bankName,
        accountType: bank.accountType,
      }));
      const response = await companyService.UpdateBanksOfCompany(banksDTO);
      success(response);
      dispatch(getBanksOfCompany());
    } catch (e) {
      failed(e);
      dispatch(finishLoading());
    }
  };

export const deleteBank = (bankId) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await companyService.DeleteBank(bankId);
    dispatch(setBanksOfCompany(response.data));
    dispatch(setMessageSnackbar(response, 'success'));
    dispatch(finishLoading());
  } catch (e) {
    dispatch(setBanksOfCompany([]));
    dispatch(finishLoading());
  }
};

/// Financial Information
// Loan Proceeds
const setLoanProceeds = (loanProceeds) => ({
  type: SET_FINANCIAL_INFO_LOAN_PROCEEDS,
  loanProceeds,
});
export const getLoanProceeds = (failed) => async (dispatch, getState) => {
  try {
    dispatch(startLoading());
    const { id } = getState().company;
    const response = await companyService.GetLoanProceeds(id);
    dispatch(setLoanProceeds(response));
    dispatch(finishLoading());
  } catch (e) {
    dispatch(setLoanProceeds({}));
    failed(e);
    dispatch(finishLoading());
  }
};
export const updateLoanProceeds = (loanProceed, success, failed) => async (dispatch, getState) => {
    try {
      dispatch(startLoading());
      const loanProceedDTO = {
        Id: getState().company.financialInformation.loanProceeds.id,
        DebtRefinance: loanProceed.debtRefinance,
        LeaseholdImprovements: loanProceed.leaseholdImprovements,
        MachineryAcquisition: loanProceed.acquisitionOfMachineryAndEquipment,
        FurnitureAcquisition: loanProceed.acquisitionOfFornitureAndFixures,
        InventoryPurchase: loanProceed.inventoryPurchase,
        WorkingCapital: loanProceed.workingCapital,
        BusinessAcquisition: loanProceed.businessAquisitionPartnerBuyOut,
        Other: loanProceed.other,
        CompanyId: getState().company.id,
      };
      const response = await companyService.UpdateLoanProceeds(loanProceedDTO);
      dispatch(getLoanProceeds());
      success(response);
    } catch (e) {
      failed(e);
      dispatch(finishLoading());
    }
  };

/// Business Debts
const setBusinessDebts = (businessDebts) => ({
  type: SET_FINANCIAL_INFO_BUSINESS_DEBT,
  businessDebts,
});
export const getBusinessDebts = (failed) => async (dispatch, getState) => {
  try {
    dispatch(startLoading());
    const { id } = getState().company;
    const response = await companyService.GetBusinessDebts(id);
    dispatch(setBusinessDebts(response));
    dispatch(finishLoading());
  } catch (e) {
    dispatch(setBusinessDebts([]));
    failed(e);
    dispatch(finishLoading());
  }
};
export const updateBusinessDebt = (businessDebt, success, failed) => async (dispatch, getState) => {
    try {
      dispatch(startLoading());
      const businessDebtDTO = {
        Id: businessDebt.id,
        CompanyId: getState().company.id,
        CreditorName: businessDebt.creditorName,
        OriginalDate: businessDebt.originalDate,
        OriginalAmount: businessDebt.originalAmount,
        PresentBalance: businessDebt.presentBalance,
        InterestRate: businessDebt.interestRate,
        PaymentFrequency: businessDebt.paymentFrequency ?? 0,
        PaymentAmount: businessDebt.monthlyPayment,
        MaturityDate: businessDebt.maturityDate,
        Collateral: businessDebt.collateral,
      };
      const response = await companyService.UpdateBusinessDebt(businessDebtDTO);
      dispatch(getBusinessDebts());
      success(response);
    } catch (e) {
      failed(e);
      dispatch(finishLoading());
    }
  };

/// Assets - Company
const setGeneralAssetsOfCompany = (assets) => ({
  type: SET_FINANCIAL_INFO_GENERAL_ASSETS,
  assets: {
    id: assets.id,
    cashHandBanks: assets.cashAmount,
    savingsAccounts: assets.savingAccountsBalance,
    otherRetirementAccount: assets.retirementAccountsBalance,
    accountNotesReceivable: assets.receivableAmount,
  },
});
export const updateGeneralAssetsOfCompany = (assets) => async (dispatch, getState) => {
    try {
      dispatch(startLoading());
      const companyId = getState().company.id;
      const { id } = getState().company.financialInformation.assets;
      const assetsDTO = {
        Id: id,
        CompanyId: companyId,
        CashAmount: assets.cashHandBanks,
        SavingAccountsBalance: assets.savingsAccounts,
        RetirementAccountsBalance: assets.otherRetirementAccount,
        ReceivableAmount: assets.accountNotesReceivable,
      };
      const response = await companyService.UpdateAssets(assetsDTO);
      dispatch(setGeneralAssetsOfCompany(response.data));
      dispatch(setMessageSnackbar(response, 'success'));
      dispatch(finishLoading());
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
      dispatch(finishLoading());
    }
  };

const setTotalAssetsOfCompany = (totalAssets) => ({
  type: SET_TOTAL_ASSETS_OF_COMPANY,
  totalAssets,
});
const getTotalAssetsOfCompany = () => async (dispatch, getState) => {
  try {
    dispatch(startLoading());
    const { assets } = getState().company.financialInformation;
    const totalAssets = (assets?.savingsAccounts ?? 0)
      + (assets?.cashHandBanks ?? 0)
      + (assets?.otherRetirementAccount ?? 0)
      + (assets?.accountNotesReceivable ?? 0)
      + (assets?.totalAutomobiles ?? 0)
      + (assets?.totalStocksAndBonds ?? 0)
      + (assets?.totalProperty ?? 0)
      + (assets?.lifeInsurance?.lifeInsuranceCashSurrender ?? 0)
      + (assets?.totalOther ?? 0);
    dispatch(setTotalAssetsOfCompany(totalAssets));
    dispatch(finishLoading());
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
    dispatch(setTotalAssetsOfCompany(0));
    dispatch(finishLoading());
  }
};

const setAllAssetsOfCompany = (assets) => {
  const {
    id,
    savingAccountsBalance,
    cashAmount,
    retirementAccountsBalance,
    receivableAmount,
    lifeInsurance,
    automobiles,
    others,
    stocksAndBonds,
    realState,
    loaded,
  } = assets;

  return {
    type: SET_FINANCIAL_INFO_ASSETS,
    assets: {
      id,
      loaded,
      savingsAccounts: savingAccountsBalance,
      cashHandBanks: cashAmount,
      otherRetirementAccount: retirementAccountsBalance,
      accountNotesReceivable: receivableAmount,
      lifeInsurance,
      automobiles,
      others,
      stocksAndBonds,
      realState,
    },
  };
};
export const getAllAssetsOfCompany = () => async (dispatch, getState) => {
  try {
    dispatch(startLoading());
    const companyId = getState().company.id;
    const response = await companyService.GetAssets(companyId);
    dispatch(setAllAssetsOfCompany({ loaded: true, ...response }));
    dispatch(getTotalAssetsOfCompany());
    dispatch(finishLoading());
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
    dispatch(setAllAssetsOfCompany({ loaded: true }));
    dispatch(finishLoading());
  }
};

// Automobiles
const setAutomobilesOfCompany = (automobiles) => ({
  type: SET_ASSETS_AUTOMOBILES,
  automobiles,
});
export const deleteAutomobileOfCompany = (automobileId) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await companyService.DeleteAutomobileOfCompany(
      automobileId,
    );
    dispatch(setAutomobilesOfCompany(response.data));
    dispatch(setMessageSnackbar(response, 'success'));
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
  } finally {
    dispatch(finishLoading());
  }
};
export const updateAutomobilesOfCompany = (automobiles) => async (dispatch, getState) => {
    try {
      dispatch(startLoading());
      const CompanyId = getState().company.id;
      const automobilesDTO = automobiles.map((auto) => ({
        Id: auto.id,
        CompanyId,
        PresentValue: auto.presentValue,
        Year: auto.year,
        Make: auto.make,
        Model: auto.model,
        Value: auto.value,
        AutoLoan: auto.autoLoan,
        LenderName: auto.lenderName,
        LoanCurrentBalance: auto.loanCurrentBalance,
        LoanMonthlyPayment: auto.loanMonthlyPayment,
        AutoLease: auto.autoLease,
        LessorName: auto.lessorName,
        LeaseRemainingMonths: auto.leaseRemainingMonths,
        LeaseMonthlyPayment: auto.leaseMonthlyPayment,
      }));
      const response = await companyService.UpdateAutomobilesOfCompany(
        automobilesDTO,
      );
      dispatch(setAutomobilesOfCompany(response.data));
      dispatch(setMessageSnackbar(response, 'success'));
      dispatch(finishLoading());
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
      dispatch(finishLoading());
    }
  };

// Other
const setOtherAssetsOfCompany = (others) => ({
  type: SET_ASSETS_OTHERS,
  others,
});
export const deleteOtherAssetsOfCompany = (otherId) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await companyService.DeleteOtherAssetsOfCompany(otherId);
    dispatch(setOtherAssetsOfCompany(response.data));
    dispatch(setMessageSnackbar(response, 'success'));
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
  } finally {
    dispatch(finishLoading());
  }
};
export const updateOtherAssetsOfCompany = (otherAssets) => async (dispatch, getState) => {
    try {
      dispatch(startLoading());
      const CompanyId = getState().company.id;
      const othersDTO = otherAssets.map((asset) => ({
        CompanyId,
        Id: asset.id,
        Type: asset.type,
        Value: asset.value,
        LoanSecuredByAsset: asset.loanSecuredByAsset,
        LenderName: asset.lenderName,
        LoanCurrentBalance: asset.loanCurrentBalance,
        LoanMonthlyPayment: asset.loanMonthlyPayment,
      }));
      const response = await companyService.UpdateOtherAssetsOfCompany(
        CompanyId,
        othersDTO,
      );
      dispatch(setOtherAssetsOfCompany(response.data));
      dispatch(setMessageSnackbar(response, 'success'));
      dispatch(finishLoading());
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
      dispatch(finishLoading());
    }
  };

// Life Insurance Of Company
const setAssetsLifeInsuranceOfCompany = (lifeInsurance) => ({
  type: SET_ASSETS_LIFEINSURANCE,
  lifeInsurance,
});
export const updateAssetsLifeInsuranceOfCompany = (lifeInsurance) => async (dispatch, getState) => {
    try {
      dispatch(startLoading());
      const CompanyId = getState().company.id;
      const lifeInsuranceDTO = {
        ...lifeInsurance,
        CompanyId,
      };
      const response = await companyService.UpdateAssetsLifeInsuranceOfCompany(
        lifeInsuranceDTO,
      );
      dispatch(setAssetsLifeInsuranceOfCompany(response.data));
      dispatch(setMessageSnackbar(response, 'success'));
      dispatch(finishLoading());
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
      dispatch(finishLoading());
    }
  };

const setPropertiesOfCompany = (properties) => ({
  type: SET_ASSETS_PROPERTIES,
  realState: properties,
});
export const updatePropertiesOfCompany = (properties) => async (dispatch, getState) => {
    try {
      dispatch(startLoading());
      const CompanyId = getState().company.id;
      const propertiesDTO = properties.map((property) => ({
        CompanyId,
        Id: property.id,
        PropertyType: property.propertyType,
        Address: property.address,
        Address2: property.address2,
        PostalCode: property.postalCode,
        RegionName: property.regionName,
        CityName: property.cityName,
        RegionCode: property.regionCode,
        CityCode: property.cityCode,
        DatePurchased: property.datePurchased,
        OriginalCost: property.originalCost,
        PresentMarketValue: property.presentMarketValue,
        MortgageHolder: property.mortgageHolder,
        MortgageBalance: property.mortgageBalance,
        MortgageMonthlyPayment: property.mortgageMonthlyPayment,
        MortgageStatus: property.mortgageStatus,
        MortgageTaxLiens: property.mortgageTaxLiens,
        MortgagePaymentIncludesTaxesAndInsurance:
          property.mortgagePaymentIncludesTaxesAndInsurance,
        SecondMortgage: false,
        HasHELOC: property.hasHELOC,
        HelocMortgageHolder: property.helocMortgageHolder,
        HelocCreditLimit: property.helocCreditLimit,
        HelocBalance: property.helocBalance,
        HelocMonthlyPayment: property.helocMonthlyPayment,
        HelocStatus: property.helocStatus,
        HelocRentCollectPerMonth: property.helocRentCollectPerMonth,
      }));
      const response = await companyService.UpdatePropertiesOfCompany(
        propertiesDTO,
      );
      dispatch(setPropertiesOfCompany(response.data));
      dispatch(setMessageSnackbar(response, 'success'));
      dispatch(finishLoading());
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
      dispatch(finishLoading());
    }
  };

export const deletePropertyOfCompany = (propertyId) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await companyService.deletePropertyOfCompany(propertyId);
    dispatch(setPropertiesOfCompany(response.data));
    dispatch(setMessageSnackbar(response, 'success'));
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
  } finally {
    dispatch(finishLoading());
  }
};

// stock Bonds Of Company
const setStockBondsOfCompanyAssets = (stocksAndBonds) => ({
  type: SET_ASSETS_STOCKBONDS,
  stocksAndBonds,
});
export const deleteStockBondOfCompany = (stockBondId) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await companyService.DeleteStockBondOfCompany(stockBondId);
    dispatch(setStockBondsOfCompanyAssets(response.data));
    dispatch(setMessageSnackbar(response, 'success'));
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
  } finally {
    dispatch(finishLoading());
  }
};

export const updateStocksBondsOfCompany = (stocksBonds) => async (dispatch, getState) => {
    try {
      dispatch(startLoading());
      const CompanyId = getState().company.id;
      const stockDTO = stocksBonds.map((item) => ({
        CompanyId,
        Id: item.id,
        Securities: item.securities,
        SharesNumber: item.sharesNumber,
        Cost: item.cost,
        MarketValue: item.marketValue,
        QuotationDate: item.quotationDate,
        TotalValue: item.totalValue,
      }));
      const response = await companyService.UpdateStocksBondsOfCompany(
        stockDTO,
      );
      dispatch(setStockBondsOfCompanyAssets(response.data));
      dispatch(setMessageSnackbar(response, 'success'));
    } catch (error) {
      dispatch(setMessageSnackbar(error, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };

/// SourceIncome
const setSourceIncomes = (sourceIncome) => ({
  type: SET_FINANCIAL_INFO_SOURCE_INCOMES,
  sourceIncome,
});
export const getSourceIncomes = (companyId) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await companyService.getSourceIncomeCompany(companyId);
    const sourcesIncomeDTO = {
      isSetted: true,
      sourcesIncome: response?.processedObject?.processedObject,
    };
    dispatch(setSourceIncomes(sourcesIncomeDTO));
    dispatch(finishLoading());
  } catch (e) {
    const sourcesIncomeDTO = {
      isSetted: true,
      sourcesIncome: [],
    };
    dispatch(setSourceIncomes(sourcesIncomeDTO));
    dispatch(finishLoading());
  }
};

export const updateSourceIncome = (sourceIncomes, success, failed) => async (dispatch, getState) => {
    try {
      dispatch(startLoading());
      const companyId = getState().company.id;
      const sourcesOfIncomeDTOs = [];

      sourceIncomes.map((sourceIncome) => sourcesOfIncomeDTOs.push({
          Id: sourceIncome.id ?? 0,
          CompanyId: companyId ?? 0,
          AnnualSalary: sourceIncome.annualSalary ?? 0,
          EmployerName: sourceIncome.employerName ?? '',
          PropertyRental: sourceIncome.realStateProperty === 'YES',
          PropertyRentalIncome: sourceIncome.propertyRentalIncome ?? 0,
        }));
      await companyService.updateSourceIncomeCompany(
        companyId,
        sourcesOfIncomeDTOs,
      );
      dispatch(getSourceIncomes(companyId));
      success('sourceIncomeUpdated');
    } catch (e) {
      failed(e);
      dispatch(finishLoading());
    }
  };

// Legal Questions
const setLegalQuestions = (legalQuestions) => ({
  type: SET_LEGAL_QUESTIONS,
  legalQuestions,
});
export const setGuarentors = (data) => ({
  type: SET_GUARANTORS,
  payload: data,
});

export const getLegalQuestions = (companyId, failed) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await companyService.GetLegalQuestions(companyId);
    const legalQuestions = {
      isSetted: true,
      liensOrJudgments: {
        hasLiensOrJudgments:
          response.companyLegalQuestion?.liensOrJudgments ?? false,
        liensOrJudgmentsAgainstWhom:
          response.companyLegalQuestion?.liensOrJudgmentsAgainstTo ?? '',
        liensOrJudgmentsAmount:
          response.companyLegalQuestion?.liensOrJudgmentsAmount ?? 0,
        liensOrJudgmentsLien:
          response.companyLegalQuestion?.liensOrJudgmentsType ?? '',
        liensOrJudgmentsDescription:
          response.companyLegalQuestion?.liensOrJudgmentsDescription ?? '',
      },
      lawsuits: {
        hasLawsuits: response.companyLegalQuestion?.lawsuits ?? false,
        lawsuitsAgainstWhom:
          response.companyLegalQuestion?.lawsuitsAgainstTo ?? '',
        lawsuitsDescription:
          response.companyLegalQuestion?.lawsuitsDescription ?? '',
      },
      bankruptcy: {
        hasFieldBankruptcy: response.companyLegalQuestion?.bankruptcy ?? false,
        bankruptcyOutcome:
          response.companyLegalQuestion?.bankruptcyOutcome ?? '',
        bankruptcyDescription:
          response.companyLegalQuestion?.bankruptcyOutcomeDesc ?? '',
      },
      subsidiaries: {
        hasSubsidiaries: response.companyLegalQuestion?.subsidiaries ?? false,
        subsidiariesNames:
          response.companyLegalQuestion?.subsidiariesNames ?? '',
      },
      relationship: {
        hasRelationshipWithOriginator:
          response.companyLegalQuestion?.relationshipWithOriginator ?? false,
        relationshipNames:
          response.companyLegalQuestion?.relationshipWithOriginatorPerson ?? '',
      },
    };
    dispatch(setLegalQuestions(legalQuestions));
    dispatch(finishLoading());
  } catch (e) {
    const legalQuestions = {
      isSetted: true,
    };
    dispatch(setLegalQuestions(legalQuestions));
    dispatch(finishLoading());
    failed(e);
  }
};
export const updateQuestions = (companyId, values, checkboxes, success, failed) => async (dispatch) => {
    try {
      dispatch(startLoading());
      const legalQuestionsDTO = {
        LiensOrJudgments: checkboxes.hasLiensOrJudgements,
        LiensOrJudgmentsAgainstTo: values.liensOrJudgmentsAgainstWhom,
        LiensOrJudgmentsAmount: values.liensOrJudgmentsAmount,
        LiensOrJudgmentsType: values.liensOrJudgmentsLien,
        LiensOrJudgmentsDescription: values.liensOrJudgmentsDescription,
        Lawsuits: checkboxes.hasLawsuits,
        LawsuitsAgainstTo: values.lawsuitsAgainstTo,
        LawsuitsDescription: values.lawsuitsDescription,
        Bankruptcy: checkboxes.hasFieldBankruptcy,
        BankruptcyOutcome: values.bankruptcyOutcome,
        BankruptcyOutcomeDesc: values.bankruptcyOutcomeDesc,
        Subsidiaries: checkboxes.hasSubsidiaries,
        SubsidiariesNames: values.subsidiariesNames,
        RelationshipWithOriginator: checkboxes.hasRelationship,
        RelationshipWithOriginatorPerson:
          values.relationshipWithOriginatorPerson,
      };
      await companyService.UpdateLegalQuestions(companyId, legalQuestionsDTO);
      dispatch(getLegalQuestions(companyId));
      success('infoUpdated');
    } catch (e) {
      failed(e);
      dispatch(finishLoading());
    }
  };

// Guarantor - Source Income
const setSourceIncomesGuarantor = (sourceIncomeGuarantor) => ({
  type: SET_GUARANTOR_SOURCE_INCOMES,
  sourceIncomeGuarantor,
});
export function getGuarantors(entityId) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await companyService.GetGuarantorList(entityId);
      dispatch(setGuarentors(response));
    } catch (error) {
      dispatch(setGuarentors({}));
    } finally {
      dispatch(finishLoading());
    }
  };
}
export const getSourceIncomesGuarantor = (relatedPartyId) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await companyService.getSourceIncomeGuarantor(
      relatedPartyId,
    );
    const sourcesIncomeDTO = {
      isSetted: true,
      sourcesIncome: response?.data,
    };
    dispatch(setSourceIncomesGuarantor(sourcesIncomeDTO));
    dispatch(finishLoading());
  } catch (e) {
    const sourcesIncomeDTO = {
      isSetted: true,
      sourcesIncome: [],
    };
    dispatch(setSourceIncomesGuarantor(sourcesIncomeDTO));
    dispatch(finishLoading());
  }
};
export const updateSourceIncomeGuarantor = (sourceIncomeGuarantor, relatedPartyId) => async (dispatch) => {
    try {
      dispatch(startLoading());
      await companyService.updateSourceIncomeGuarantor(
        relatedPartyId,
        sourceIncomeGuarantor,
      );
      dispatch(getSourceIncomesGuarantor(relatedPartyId));
      dispatch(setMessageSnackbar('sourceIncomeUpdated', 'success'));
    } catch (e) {
      dispatch(finishLoading());
    }
  };

/// / Guarantor - Legal Questions
const setGuarantorLegalQuestions = (legalQuestions) => ({
  type: SET_GUARANTOR_LEGAL_QUESTIONS,
  legalQuestions,
});
export const getGuarantorLegalQuestions = (guarantorId) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await companyService.getGuarantorLegalQuestions(
      guarantorId,
    );
    const [legalQuestions] = response.data;
    const guarantorLegalQuestions = {
      id: legalQuestions?.id ?? 0,
      isSetted: true,
      liensOrJudgments: {
        hasLiensOrJudgments: legalQuestions?.liensOrJudgments ?? false,
        liensOrJudgmentsAgainstWhom:
          legalQuestions?.liensOrJudgmentsAgainstTo ?? '',
        liensOrJudgmentsAmount: legalQuestions?.liensOrJudgmentsAmount ?? 0,
        liensOrJudgmentsLien: legalQuestions?.liensOrJudgmentsType ?? '',
        liensOrJudgmentsDescription:
          legalQuestions?.liensOrJudgmentsDescription ?? '',
      },
      lawsuits: {
        hasLawsuits: legalQuestions?.lawsuits ?? false,
        lawsuitsAgainstWhom: legalQuestions?.lawsuitsAgainstTo ?? '',
        lawsuitsDescription: legalQuestions?.lawsuitsDescription ?? '',
      },
      bankruptcy: {
        hasFieldBankruptcy: legalQuestions?.bankruptcy ?? false,
        bankruptcyOutcome: legalQuestions?.bankruptcyOutcome ?? '',
        bankruptcyDescription:
          legalQuestions?.bankruptcyOutcomeDescription ?? '',
      },
    };
    dispatch(setGuarantorLegalQuestions(guarantorLegalQuestions));
    dispatch(finishLoading());
  } catch (e) {
    dispatch(finishLoading());
    dispatch(setGuarantorLegalQuestions({ isSetted: true }));
  }
};
export const updateGuarantorLegalQuestions = (guarantorId, values, checkboxes, success, failed) => async (dispatch, getState) => {
    try {
      dispatch(startLoading());
      const guarantorLegalQuestionsDTO = {
        Id: getState().company?.guarantorSelected?.legalQuestions?.id ?? 0,
        LiensOrJudgments: checkboxes.hasLiensOrJudgements,
        LiensOrJudgmentsAgainstTo: values.liensOrJudgmentsAgainstWhom,
        LiensOrJudgmentsAmount: values.liensOrJudgmentsAmount,
        LiensOrJudgmentsType: values.liensOrJudgmentsLien,
        LiensOrJudgmentsDescription: values.liensOrJudgmentsDescription,
        Lawsuits: checkboxes.hasLawsuits,
        LawsuitsAgainstTo: values.lawsuitsAgainstTo,
        LawsuitsDescription: values.lawsuitsDescription,
        Bankruptcy: checkboxes.hasFieldBankruptcy,
        BankruptcyOutcome: values.bankruptcyOutcome,
        BankruptcyOutcomeDescription: values.bankruptcyOutcomeDesc,
      };
      await companyService.updateGuarantorLegalQuestions(
        guarantorId,
        guarantorLegalQuestionsDTO,
      );
      dispatch(getGuarantorLegalQuestions(guarantorId));
      success('infoUpdated');
    } catch (e) {
      failed(e);
      dispatch(finishLoading());
    }
  };

const setCompanyLoanRequest = (loanRequest) => ({
  type: SET_COMPANY_LOAN_REQUESTS,
  loanRequest,
});
export function getCompanyLoanRequest(entityId, failed) {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await companyService.getCompanyLoanRequest(entityId);
      const loanRequest = {
        isSetted: true,
        loanRequests: response.loanRequests,
      };
      dispatch(setCompanyLoanRequest(loanRequest));
      dispatch(finishLoading());
    } catch (e) {
      failed(e);
      dispatch(finishLoading());
      dispatch(setCompanyLoanRequest({ isSetted: true, loanRequests: [] }));
    }
  };
}

const setCompanyLoans = (loans) => ({
  type: SET_COMPANY_LOANS,
  loans,
});
export function getCompanyLoans(entityId, failed) {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await companyService.getCompanyLoans(entityId);
      const loans = {
        isSetted: true,
        loans: response.loans,
      };
      dispatch(setCompanyLoans(loans));
      dispatch(finishLoading());
    } catch (e) {
      failed(e);
      dispatch(finishLoading());
      dispatch(setCompanyLoans({ isSetted: true, loans: [] }));
    }
  };
}
