// import jwtDecode from 'jwt-decode';
import axios from 'src/utils/axios'; // newaxios
import { API_BASE_URL } from 'src/config/';
import routes from 'src/config/api.routes';
import axiosService from './axiosService';

class CustomerService {
  UpdateAdditionalInfoByEntityId = async (entityId, data) =>
    axiosService.put(`${routes.clients}/additional-info/${entityId}`, data);

  UpdateFinancialInfoByEntityId = async (entityId, data) =>
    axiosService.put(`${routes.clients}/financial-info/${entityId}`, data);

  GetCosigners = async (entityId) =>
    axiosService.get(`${routes.clients}/GetContacts/${entityId}`);

  GetCustomer = (filter) =>
    new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .get(`${API_BASE_URL}/api/Clients${filter}`, {
          headers: {
            Authorization: AuthStr,
            appClientId: localStorage.getItem('appClientId')
          }
        })
        .then((response) => {
          if (response.data) {
            const db = {
              result: {
                registros: response.data.data.elements,
                products: response.data.data.result
              }
            };
            resolve(db);
          } else {
            reject(response.data.err);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });

  GetCompany = (filter) =>
    new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .get(`${API_BASE_URL}/api/Company${filter}`, {
          headers: {
            Authorization: AuthStr,
            appClientId: localStorage.getItem('appClientId')
          }
        })
        .then((response) => {
          if (response.data) {
            const db = {
              result: {
                total: response.data.data.data.elements,
                list: response.data.data.data.result
              }
            };
            resolve(db);
          } else {
            reject(response.data.err);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });

  GetBank = (EntityID) =>
    new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .get(`${API_BASE_URL}/api/Banks/GetByEntityId/${EntityID}`, {
          headers: {
            Authorization: AuthStr,
            appClientId: localStorage.getItem('appClientId')
          }
        })
        .then((response) => {
          if (response.data) {
            const db = {
              result: {
                data: response.data.data,
                message: response.data.message
              }
            };
            resolve(db);
          } else {
            reject(response.data.err);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });

  GetCountries = () =>
    new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .get(`${API_BASE_URL}/api/Countries`, {
          headers: {
            Authorization: AuthStr,
            appClientId: localStorage.getItem('appClientId')
          }
        })
        .then((response) => {
          if (response.data) {
            const db = {
              result: {
                data: response.data.data,
                message: response.data.message
              }
            };
            resolve(db);
          } else {
            reject(response.data.err);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });

  GetRegions = () =>
    new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .get(`${API_BASE_URL}/api/Regions`, {
          headers: {
            Authorization: AuthStr,
            appClientId: localStorage.getItem('appClientId')
          }
        })
        .then((response) => {
          if (response.data) {
            const db = {
              result: {
                data: response.data.data,
                message: response.data.message
              }
            };
            resolve(db);
          } else {
            reject(response.data.err);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });

  GetCities = () =>
    new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .get(`${API_BASE_URL}/api/Cities`, {
          headers: {
            Authorization: AuthStr,
            appClientId: localStorage.getItem('appClientId')
          }
        })
        .then((response) => {
          if (response.data) {
            const db = {
              result: {
                data: response.data.data,
                message: response.data.message
              }
            };
            resolve(db);
          } else {
            reject(response.data.err);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });

  GetCustomerDetails = (EntityID) =>
    new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .get(`${API_BASE_URL}/api/Clients/${EntityID}/details`, {
          headers: {
            Authorization: AuthStr,
            appClientId: localStorage.getItem('appClientId')
          }
        })
        .then((response) => {
          if (response.data) {
            const db = {
              result: {
                data: response.data,
                message: response.data.message
              }
            };
            resolve(db);
          } else {
            reject(response.data.err);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });

  GetCustomerFullDetails = async (EntityID) =>
    axiosService.get(`${routes.clients}/GetFullClientDetails/${EntityID}`);

  GetContact = async (EntityID) =>
    axiosService.get(`/api/Contacts/GetByEntityId/${EntityID}`);

  GetCapturas = async (EntityID) =>
    axiosService.get(`/api/Capture/${EntityID}/captures`);

  GetAgreement = () => axiosService.get('/api/Agreement');

  GetAdvisor = () => axiosService.get('/api/Advisor/GetAllAdvisor');

  GetQuota = (EntityID) =>
    new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .get(`${API_BASE_URL}/api/RevolvingCredits/${EntityID}`, {
          headers: {
            Authorization: AuthStr,
            appClientId: localStorage.getItem('appClientId')
          }
        })
        .then((response) => {
          if (response.data) {
            const db = {
              result: {
                data: response.data,
                message: response.data.message
              }
            };
            resolve(db);
          } else {
            reject(response.data.err);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });

  SetCapturesNewEntity = (capturesDTO) =>
    axiosService.post('/api/Capture', capturesDTO);

  SetCaptures = (capturesDTO) =>
    axiosService.put('/api/Capture/CaptureModify', JSON.stringify(capturesDTO));

  GetCapturesItems = (EntityID, Type) =>
    new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .get(`${API_BASE_URL}/api/Capture/${EntityID}/capturesItems/${Type}`, {
          headers: {
            Authorization: AuthStr,
            appClientId: localStorage.getItem('appClientId')
          }
        })
        .then((response) => {
          if (response.data) {
            const db = {
              result: {
                data: response.data,
                message: response.data.message
              }
            };
            resolve(db);
          } else {
            reject(response.data.err);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });

  SetQuota = (EntityID, cupo) =>
    new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .post(`${API_BASE_URL}/api/RevolvingCredits/${EntityID}`, cupo, {
          headers: {
            Authorization: AuthStr,
            'Content-Type': 'application/json',
            appClientId: localStorage.getItem('appClientId')
          }
        })
        .then((response) => {
          if (response.data) {
            const db = {
              result: {
                data: response.data,
                message: response.data.message
              }
            };
            resolve(db);
          } else {
            reject(response.data.err);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });

  SetCustomerInfo = (customer) =>
    new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .put(`${API_BASE_URL}/api/Clients/`, customer, {
          headers: {
            Authorization: AuthStr,
            'Content-Type': 'application/json',
            appClientId: localStorage.getItem('appClientId')
          }
        })
        .then((response) => {
          if (response.data) {
            const db = {
              result: {
                data: response.data,
                message: response.data.message
              }
            };
            resolve(db);
          } else {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });

  SetCustomerNewInfo = (customer) => axiosService.put('/api/Clients/', customer);

  SetContactInfo = async (contact) => axiosService.put('/api/Contacts/', contact);

  SetContactNewInfo = (contact) =>
    new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      const data = JSON.stringify(contact);
      axios
        .post(`${API_BASE_URL}/api/Contacts/`, data, {
          headers: {
            Authorization: AuthStr,
            'Content-Type': 'application/json',
            appClientId: localStorage.getItem('appClientId')
          }
        })
        .then((response) => {
          if (response.data) {
            const db = {
              result: {
                data: response.data,
                message: response.data.message
              }
            };
            resolve(db);
          } else {
            resolve(response);
          }
        })
        .catch((err) => {
          console.log(`Error response:${err}`);
          reject(err);
        });
    });

  SetBankInfo = (bank) =>
    new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .put(`${API_BASE_URL}/api/Banks/`, bank, {
          headers: {
            Authorization: AuthStr,
            'Content-Type': 'application/json',
            appClientId: localStorage.getItem('appClientId')
          }
        })
        .then((response) => {
          if (response.data) {
            const db = {
              result: {
                data: response.data,
                message: response.data.message
              }
            };
            resolve(db);
          } else {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });

  SetBankNewInfo = (bank) =>
    new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .post(`${API_BASE_URL}/api/Banks/`, bank, {
          headers: {
            Authorization: AuthStr,
            'Content-Type': 'application/json',
            appClientId: localStorage.getItem('appClientId')
          }
        })
        .then((response) => {
          if (response.data) {
            const db = {
              result: {
                data: response.data,
                message: response.data.message
              }
            };
            resolve(db);
          } else {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });

  DeleteCustomer = (EntityID, description) =>
    new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .delete(
          `${API_BASE_URL}/api/Clients/${EntityID}/comment/${description}`,
          {
            headers: {
              Authorization: AuthStr,
              appClientId: localStorage.getItem('appClientId')
            }
          }
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data.message);
          } else {
            reject(response.data.err);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });

  sendFiles = (files, originatorId, loanProductId) =>
    new Promise((resolve, reject) => {
      const file = files[0];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('originatorId', originatorId);
      formData.append('loanProductId', loanProductId);

      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .post(`${API_BASE_URL}/api/clients/ImportClients`, formData, {
          headers: {
            Authorization: AuthStr,
            'Content-Type': 'multipart/form-data',
            appClientId: localStorage.getItem('appClientId')
          }
        })
        .then((response) => {
          if (response) {
            resolve(true);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });

  getCustomerNameByLoanId = (loanId) =>
    axiosService.get(`${routes.customers}/LoanName/${loanId}`);
}

const customerService = new CustomerService();

export default customerService;
