export const customerImportEn = {
  'Customers import logs': 'Customers Import Logs',
  'Import customers': 'Import Customers',
  'Load customers file': 'Load Customers File',
  'Load file description':
    'Select a file with a proper structure in order to upload customers into the system.',
  logs: 'Logs',
  Processed: 'Processed',
  removeAll: 'Remove All',
  Run: 'Run',
  Runs: 'Runs',
  selectFiles: 'Select File(s)',
  selectFilesDesc: 'Drop files here or click to browse your machine',
  success: 'success',
  Successful: 'Successful',
  'Upload logs': 'Upload Logs',
  uploadFiles: 'Upload File(s)',
  warning: 'warning',
};

export const customerImportEs = {
  'Customers import logs': 'Logs de importación de clientes',
  'Import customers': 'Importar clientes',
  'Load customers file': 'Cargar archivo de clientes',
  'Load file description':
    'Seleccione un archivo con la estructura adecuada para cargar los clientes en el sistema.',
  logs: 'Logs',
  Processed: 'Procesados',
  removeAll: 'Eliminar todos',
  Run: 'Ejecución',
  Runs: 'Ejecuciones',
  selectFiles: 'Seleccionar archivo(s)',
  selectFilesDesc: 'Arrastra los archivos aquí o da clic para explorar',
  success: 'exitoso',
  Successful: 'Exitosos',
  'Upload logs': 'Logs de importación',
  uploadFiles: 'Subir archivo(s)',
  warning: 'alarma',
};
