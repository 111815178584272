import i18n from 'i18next';
export const UI_START_LOADING = '@ui/start-loading';

export const UI_FINISH_LOADING = '@ui/finish-loading';

export const UI_SET_MESSAGE_SNACKBAR = '@ui/set-message';


export const startLoading = () => ({
    type: UI_START_LOADING
});

export const finishLoading = () => ({
    type: UI_FINISH_LOADING
});

export const setMessageSnackbar = (msg, variant) => ({
    type: UI_SET_MESSAGE_SNACKBAR,
    payload: { message: i18n.t(String(msg?.message ?? msg)), variant }
});
