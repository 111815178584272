import generalServices from "src/services/generalServices"
import { setMessageSnackbar } from "./uiActions";

export const SET_IDENTIFICATION_TYPES = '@general/set-identification-types';

export const getIdentificationTypes = () => async (dispatch) => {
  try {
    const response = await generalServices.GetIdentificationTypes();
    dispatch(setIdentificationTypes(response));
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
  }
}

const setIdentificationTypes = (identificationTypes) => {
  return {
    type: SET_IDENTIFICATION_TYPES,
    identificationTypes
  }
}