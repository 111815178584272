import React from 'react';
import { OnErrorLogo } from '../utils/urlPath';

function LogoLoading(props) {
  return (
    <img
      alt="Logo"
      height="auto"
      onError={(e) => OnErrorLogo(e)}
      src="/static/logo-grey.svg"
      width="100%"
      {...props}
    />
  );
}

export default LogoLoading;
