export const customerDetailsEn = {
  '% of Ownership': '% of Ownership',
  academicInfoTtl: 'Academic Information',
  'Acquisition of machinery': 'Acquisition of Machinery',
  acquisitionOfFurniture: 'Acquisition of Furniture',
  Address: 'Address',
  againstWhom: 'Against whom?',
  Amount: 'Amount',
  'and equipment': 'and equipment',
  'Any affiliates have a relationship with any employee or board member ':
    'Do any affiliates have a relationship with an employee or board member?',
  appartmentUnit: 'Appartment / Unit',
  Approved: 'Approved',
  'At maturity': 'At Maturity',
  availableSpace: 'Available Space',
  Biweekly: 'Biweekly',
  'Business acquisition /': 'Business acquisition /',
  'Business name': 'Business Name',
  City: 'City',
  cityOriginFunds: 'Source of Funds City',
  Closed: 'Closed',
  codebtorsEmail: "Co-debtor's Email'",
  codebtorsName: "Co-debtor's Name",
  codebtorsPhone: "Co-debtor's Phone",
  'Company loans': 'Company Loans',
  'Company Requests': 'Company Requests',
  companyDirectorsBankruptField:
    'Had the business or the principals any ever field bankruptcy?',
  companyInformation: 'Company Information',
  companyName: 'Company Name',
  countryOriginFunds: 'Source of Funds Country',
  currentQuotaCustomer: 'Current Borrower Credit Limit',
  'Customer details': 'Borrower Details',
  customerLoans: 'Borrower Loans',
  customerQuota: 'Borrower Credit Limit',
  dateEstablished: 'Date Established',
  'Debt refinance': 'Debt Refinance',
  Debts: 'Debts',
  debtsToShow: 'There are no debts to show',
  deleted: 'Deleted',
  Denied: 'Denied',
  'Describe in details': 'Describe in Details',
  Disbursed: 'Disbursed',
  editQuota: 'Edit Credit Limit',
  emailOfReferral: 'Referral Email',
  'Employee information': 'Employee Information',
  Erased: 'Erased',
  Financial: 'Financial',
  financialInformationTtl: 'Financial Information',
  fixedMonthlyIncomes: 'Fixed Monthly Incomes',
  foreignersPendingTrials:
    'Are there any liens or judgments outstanding against the business or the principals?',
  Fortnight: 'Fortnight',
  'full-time employees': 'full-time Employees',
  'General Information': 'General Information',
  guarantor: 'Guarantor',
  Guarantor: 'Guarantor',
  'How many years have': 'How Many Years Have',
  identificationNumber: 'Identification Number',
  identificationType: 'Identification Type',
  'In study': 'In Underwriting',
  Information: 'Information',
  Initiated: 'Initiated',
  inStudy: 'In Study',
  'Inventory purchase': 'Inventory Purchase',
  'Leasehold improvements': 'Leasehold Improvements',
  'Legal Questions': 'Legal Questions',
  Lien: 'Lien',
  'List any business owned': 'List any other business owned',
  'Loan product': 'Loan Product',
  maritalStatus: 'Marital Status',
  Monthly: 'Monthly',
  'Monthly rent/mortage': 'Monthly Rent/Mortage',
  nameOfReferral: 'Referral Name',
  nameOriginatorEmployeeBoardMember:
    'Name of Originator Employee or Board Member',
  Names: 'Names',
  natureofBusiness: 'Nature of Business',
  'new employees as a': 'New Employees As a',
  newQuotaCustomer: 'New Borrower Credit Limit',
  'Number of current': 'Number of Current',
  numberOfChildren: 'Number of Children',
  numberOfDependents: 'Dependent Persons',
  'of the company': 'of the Company',
  Other: 'Other',
  Overdue: 'Overdue',
  Owners: 'Owners',
  'Ownership %': 'Ownership %',
  Paid: 'Paid',
  'partner buy-out': 'Partner Buy-out',
  Pending: 'Pending',
  'Pending amount': 'Pending Amount',
  pendingLawsuits:
    'Have any lawsuits pending against the business or the principals?',
  Phone: 'Phone',
  phoneOfReferral: 'Referral Phone',
  'Projected number of': 'Projected Number of',
  quota: 'Credit Limit',
  referralInfoTtl: 'Referral Information',
  regionOriginFunds: 'Source of Funds Region',
  Rejected: 'Rejected',
  'Residence Address': 'Residence Address',
  'result of this loan': 'Result of This Loan',
  'Send email': 'Send Emails',
  sourceOfFunds: 'Source of Funds',
  'Start of Payment': 'Start of Payment',
  State: 'State',
  Stateless: 'Stateless',
  Status: 'Status',
  subsidiaryAffiliateBusinesses:
    'Does the company have any subsidiaries or affiliates?',
  'There are no business to show': 'There are no business to show',
  'There are not owners to show': 'There are not owners to show',
  Title: 'Title',
  totalMonthlyExpenses: 'Total Monthly Expenses',
  'Type of Owner': 'Type of Owner',
  typeOfBusiness: 'Type of Business',
  UseLoanProceeds: 'Use of Loan Proceeds',
  variableMonthlyIncomes: 'Variable Monthly Incomes',
  website: 'Website',
  Weekly: 'Weekly',
  whatWasTheOutcome: 'What was the outcome?',
  'Who owns 20% or more': 'Who owns 20% or more',
  'Working capital': 'Working principal',
  'you lived at this address': 'you lived at this address',
  zipCode: 'Zip Code',
  alliedEntityReferrer: 'Allied Entity',
  advisorNameAlliedEntityReferrer: 'Allied Entity Advisor',
};

export const customerDetailsEs = {
  '% of Ownership': '% de Propiedad',
  academicInfoTtl: 'Información Académica',
  'Acquisition of machinery': 'Adquisición de maquinaria',
  acquisitionOfFurniture: 'Adquisicion de muebles',
  Address: 'Dirección',
  againstWhom: '¿Contra quién?',
  Amount: 'Monto',
  'and equipment': 'Y equipamiento',
  'Any affiliates have a relationship with any employee or board member ':
    '¿Algún afiliado tiene relación con un empleado o miembro de la junta directiva?',
  appartmentUnit: 'Apartamento / Unidad',
  Approved: 'Aprobado',
  'At maturity': 'Al vencimiento',
  availableSpace: 'Cupo disponible',
  Biweekly: 'Catorcenal',
  'Business acquisition /': 'Adquisición de empresas /',
  'Business name': 'Nombre del negocio',
  City: 'Ciudad',
  cityOriginFunds: 'Ciudad de origen de ingresos',
  Closed: 'Cerrado',
  codebtorsEmail: 'Correo electrónico del codeudor',
  codebtorsName: 'Nombre del codeudor',
  codebtorsPhone: 'Teléfono del codeudor',
  'Company loans': 'Préstamos para empresas',
  'Company Requests': 'Solicitudes de la empresa',
  companyDirectorsBankruptField:
    '¿El negocio o los directores han estado alguna vez en bancarrota?',
  companyInformation: 'Información de la empresa',
  companyName: 'Nombre de la empresa',
  countryOriginFunds: 'País de origen de ingresos',
  currentQuotaCustomer: 'Cupo actual del cliente',
  'Customer details': 'Detalle de cliente',
  customerLoans: 'Préstamos del cliente',
  customerQuota: 'Cupo del cliente',
  dateEstablished: 'Fecha establecida',
  'Debt refinance': 'Refinanciamiento de deuda',
  Debts: 'Deudas',
  debtsToShow: 'No hay deudas que mostrar',
  deleted: 'Borrado',
  Denied: 'Negado',
  'Describe in details': 'Describe en detalle',
  Disbursed: 'Disbursed',
  editQuota: 'Editar cupo',
  emailOfReferral: 'Email de asesor',
  'Employee information': 'Información del empleado',
  Erased: 'Borrado',
  Financial: 'Financiero',
  financialInformationTtl: 'Información Financiera',
  fixedMonthlyIncomes: 'Ingresos mensuales fijos',
  foreignersPendingTrials:
    '¿Hay embargos o juicios pendientes contra la empresa o los directores?',
  Fortnight: 'Quincenal',
  'full-time employees': 'empleados de tiempo completo',
  'General Information': 'Información General',
  guarantor: 'Garante',
  Guarantor: 'Garante',
  'How many years have': 'Cuantos años has',
  identificationNumber: 'Número de identificación',
  identificationType: 'Tipo de identificación',
  'In study': 'En estudio',
  Information: 'Información',
  Initiated: 'Iniciado',
  inStudy: 'En Estudio',
  'Inventory purchase': 'Inventario de compras',
  'Leasehold improvements': 'Mejoras arrendatarias',
  'Legal Questions': 'Preguntas legales',
  Lien: 'Derecho de retención',
  'List any business owned': 'Enumere cualquier negocio de propiedad',
  'Loan product': 'Producto de préstamo',
  maritalStatus: 'Estado civil',
  Monthly: 'Mensual',
  'Monthly rent/mortage': 'Alquiler/Hipoteca mensual',
  nameOfReferral: 'Nombre de asesor',
  nameOriginatorEmployeeBoardMember:
    'Nombre del empleado originador o miembro de la junta',
  Names: 'Nombres',
  natureofBusiness: 'Naturaleza del negocio',
  'new employees as a': 'nuevos empleados como',
  newQuotaCustomer: 'Nuevo cupo del cliente',
  'Number of current': 'Número de corriente',
  numberOfChildren: 'Número de hijos',
  numberOfDependents: 'Personas a cargo',
  'of the company': 'de la empresa',
  Other: 'Otro',
  Overdue: 'Mora',
  Owners: 'Propietarios',
  'Ownership %': '% De propiedad',
  Paid: 'Pagado',
  'partner buy-out': 'compra de socios',
  Pending: 'Pendiente',
  'Pending amount': 'Valor pendiente',
  pendingLawsuits:
    '¿Tiene alguna demanda pendiente contra la empresa o los directores?',
  Phone: 'Teléfono',
  phoneOfReferral: 'Teléfono de asesor',
  'Projected number of': 'Número proyectado de',
  quota: 'Cupo',
  referralInfoTtl: 'Información de Asesor',
  alliedEntityReferrer: 'Entidad aliada',
  advisorNameAlliedEntityReferrer: 'Asesor entidad aliada',
  regionOriginFunds: 'Región de origen de ingresos',
  Rejected: 'Rechazado',
  'Residence Address': 'Dirección de residencia',
  'result of this loan': 'resultado de este préstamo',
  'Send email': 'Enviar correo electrónico',
  sourceOfFunds: 'Origen de los ingresos',
  'Start of Payment': 'Inicio del pago',
  State: 'Estado',
  Stateless: 'Sin estado',
  Status: 'Estado',
  subsidiaryAffiliateBusinesses: '¿Hay empresas subsidiarias o afiliadas?',
  'There are no business to show': 'No hay negocios que mostrar',
  'There are not owners to show': 'No hay propietarios para mostrar',
  Title: 'Titulo',
  totalMonthlyExpenses: 'Gastos mensuales totales',
  'Type of Owner': 'Tipo de dueño',
  typeOfBusiness: 'Tipo de negocio',
  UseLoanProceeds: 'Uso de los recursos del préstamo',
  variableMonthlyIncomes: 'Ingresos mensuales variables',
  website: 'Sitio web',
  Weekly: 'Semanal',
  whatWasTheOutcome: 'Cuál fue el resultado',
  'Who owns 20% or more': 'Quién posee el 20% o más',
  'Working capital': 'Capital de trabajo',
  'you lived at this address': 'vivido en esta dirección',
  zipCode: 'Código postal',
};
