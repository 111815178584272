function urlPath(img, error) {
  const url = 'https://app-logos.s3.amazonaws.com/';
  const presttiHost = 'prestti';
  const host = window.location.hostname;
  const urlpath =
    host === 'localhost'
      ? `${url}${presttiHost}${img}`
      : `${url}${host}${img}`;
  return (urlpath);
}

export default urlPath;

export function OnErrorLogo(e)
{
  e.target.onerror = null
}