import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import {
 Box, Container, Grid, Typography,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 200,
    paddingBottom: 200,
    [theme.breakpoints.down('lg')]: {
      paddingTop: 60,
      paddingBottom: 60,
    },
  },
  image: {
    perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    perspective: 1500,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
      transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      boxShadow: theme.shadows[16],
    },
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
    },
  },
}));

function Hero({ className, ...rest }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item md={5} xs={12}>
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              justifyContent="center"
            >
              <Typography color="secondary" variant="overline">
                {t('Platform')}
              </Typography>
              <Typography color="textPrimary" variant="h1">
                {t('loginDescription')}
              </Typography>
              <Box mt={3}>
                <Typography color="textSecondary" variant="body1">
                  {t('homeViewDescription')}
                </Typography>
              </Box>
              <Box mt={3}>
                <Grid container spacing={3}>
                  <Grid item>
                    <Typography color="secondary" variant="h1">
                      SaaS
                    </Typography>
                    <Typography color="textSecondary" variant="overline">
                      {t('Saas description')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography color="secondary" variant="h1">
                      {t('Commerces')}
                    </Typography>
                    <Typography color="textSecondary" variant="overline">
                      {t('Commerces description')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography color="secondary" variant="h1">
                      {t('Payroll')}
                    </Typography>
                    <Typography color="textSecondary" variant="overline">
                      {t('Payroll description')}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item md={7} xs={12}>
            <Box position="relative">
              <div className={classes.shape}>
                <img alt="Shapes" src="/static/home/shapes.svg" />
              </div>
              <div className={classes.image}>
                <img alt="Presentation" src="/static/home/dark-light.png" />
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

Hero.propTypes = {
  className: PropTypes.string,
};

export default Hero;
