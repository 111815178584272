import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import {
 Box, Button, Paper, Portal, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useSettings from 'src/hooks/useSettings';
import { THEMES } from 'src/constants';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 420,
    position: 'fixed',
    top: 0,
    right: 0,
    margin: theme.spacing(3),
    outline: 'none',
    zIndex: 2000,
    padding: theme.spacing(2),
  },
}));

function SettingsNotification() {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);
  const { saveSettings } = useSettings();
  const { t } = useTranslation();

  const handleSwitch = () => {
    saveSettings({ theme: THEMES.ACELERA });
    Cookies.set('settingsUpdated', 'true');
    setOpen(false);
  };

  const handleClose = () => {
    Cookies.set('settingsUpdated', 'true');
    setOpen(false);
  };

  useEffect(() => {
    const settingsUpdated = Cookies.get('settingsUpdated');

    if (!settingsUpdated) {
      setOpen(true);
    }
  }, []);

  if (!isOpen) {
    return null;
  }

  return (
    <Portal>
      <Paper className={classes.root} elevation={3}>
        <Typography color="textPrimary" gutterBottom variant="h4">
          {t('Settings updated')}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {t('Settings updated description')}
        </Typography>
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button onClick={handleClose}>{t('Close')}</Button>
          <Button color="secondary" onClick={handleSwitch} variant="contained">
            {t('Switch')}
          </Button>
        </Box>
      </Paper>
    </Portal>
  );
}

export default SettingsNotification;
