import { SET_LIST_USERS, SET_SELECTED_USER } from '../actions/usersActions';

const initialState = {
  users: [],
  count: 0,
  selected: {
    id: 0,
    email: ""
  }
};

const UsersReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_LIST_USERS: {
      return { ...state, users: action.users, count: action.count }
    }

    case SET_SELECTED_USER: {
      return { ...state, selected: action.user }
    }

    default: {
      return state;
    }
  }
};

export default UsersReducer;