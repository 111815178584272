import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Page from 'src/components/Page';
import Hero from './Hero';

const useStyles = makeStyles(() => ({
  root: {},
}));

function HomeView() {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Home">
      <Hero />
    </Page>
  );
}

export default HomeView;
