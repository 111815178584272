import {
  CLEAN_LOAN_REQUEST,
  SET_CUSTOMER,
  SET_FORM_CREATE,
  SET_FORM_EDIT,
  SET_PRODUCT_LOAN_REQUEST,
  SET_FORM_CODEBTOR,
  SET_ADDITIONAL_INFO,
  SET_PRODUCTS_INFO,
  SET_LOAN_REQUEST_VALIDATIONS,
  SET_SELECT_LOANS
} from '../actions/loanRequestActions';

const initialState = {
  customer: {
    originatorId: '',
    entityId: '',
    name: '',
    identification: '',
    customerType: '',
    email: ''
  },
  form: {
    id: 0,
    amount: 0,
    term: 0,
    PaymentFrequency: '',
    PaymentStartDate: new Date(),
    PaymentInstallments: 1,
    gracePeriod: 0,
    gracePeriodApplyFor: 0,
    cosignerIdentificationNumber: '',
    DueDate: new Date(),
    rate: 0,
    loanProductId: 0,
    capitalizeGracePeriod: false,
    loanProductDescription: '',
    requestStatus: '',
    requestDate: new Date(),
    downPayment: 0,
  },
  product: {
    id: 0,
    description: '',
    daysForClosure: 0,
    loanProductRiskParameters: [],
    hasGracePeriod: false,
    interestRate: 0,
    defaultPenalGraceDays: 0,
    penalRate: 0
  },
  additionalInfo: {
    loaded: false
  },
  productsInfo:{
    loaded: false,
  },
  validations: {
    loaded: false
  },
  selectLoans: [],
};

const loanRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMER: {
      return {
        ...state,
        customer: action.customer
      };
    }
    case CLEAN_LOAN_REQUEST: {
      return { ...initialState };
    }
    case SET_FORM_CREATE: {
      return {
        ...state,
        form: {
          amount: action.payload.amount,
          term: action.payload.term,
          PaymentFrequency: action.payload.PaymentFrequency,
          PaymentStartDate: action.payload.PaymentStartDate,
          PaymentInstallments: action.payload.PaymentInstallments,
          loanProductId: action.payload.loanProductId,
          gracePeriodApplyFor: action.payload.gracePeriodApplyFor,
          cosignerIdentificationNumber:
            action.payload.cosignerIdentificationNumber,
          rate: action.payload.rate,
          DueDate: action.payload.DueDate,
          requestType: action.payload.requestType,
          associatedRequestId: action.payload.associatedRequestId,
          identification: action.payload.identification,
          name: action.payload.name,
          downPayment: action.payload.downPayment,
          disbursementDate: action.payload.disbursementDate
        }
      };
    }
    case SET_FORM_EDIT: {
      return {
        ...state,
        customer: {
          ...state.customer,
          name: action.payload.name,
          entityId: action.payload.entityId,
          identification: action.payload.identification,
          identificationType: action.payload.identificationType,
          originatorId: action.payload.originatorId,
          customerType: action.payload.customerType,
          email: action.payload.email
        },
        form: {
          ...state.form,
          amount: action.payload.amount,
          term: action.payload.term,
          PaymentFrequency: action.payload.PaymentFrequency,
          PaymentStartDate: action.payload.PaymentStartDate,
          PaymentInstallments: action.payload.PaymentInstallments,
          gracePeriod: action.payload.gracePeriod,
          gracePeriodApplyFor: action.payload.gracePeriodApplyFor,
          rate: action.payload.rate,
          capitalizeGracePeriod: action.payload.capitalizeGracePeriod,
          loanProductDescription: action.payload.loanProductDescription,
          id: action.payload.id,
          requestStatus: action.payload.requestStatus,
          requestDate: action.payload.requestDate,
          requestType: action.payload.requestType,
          associatedRequestId: action.payload.associatedRequestId,
          downPayment: action.payload.downPayment,
          disbursementDate: action.payload.disbursementDate
        },
        product: {
          id: action.payload.loanProductId,
          daysForClosure: action.payload.daysForClosure,
          loanProductRiskParameters: action.payload.loanProductRiskParameters,
          hasGracePeriod: action.payload.hasGracePeriod,
          description: action.payload.description,
          interestRate: action.payload.interestRate,
          defaultPenalGraceDays: action.payload.defaultPenalGraceDays,
          penalRate: action.payload.penalRate
        }
      };
    }
    case SET_PRODUCT_LOAN_REQUEST: {
      return {
        ...state,
        product: {
          id: action.product.id,
          daysForClosure: action.product.daysForClosure,
          loanProductRiskParameters: action.product.loanProductRiskParameters,
          hasGracePeriod: action.product.hasGracePeriod,
          description: action.product.description,
          interestRate: action.product.interestRate,
          defaultPenalGraceDays: action.product.defaultPenalGraceDays,
          penalRate: action.product.penalRate
        }
      };
    }
    case SET_FORM_CODEBTOR: {
      return {
        ...state,
        form: {
          associatedRequestId: action.payload.associatedRequestId,
          requestType: action.payload.requestType,
          amount: action.payload.amount,
          term: action.payload.term,
          PaymentFrequency: action.payload.PaymentFrequency,
          PaymentStartDate: action.payload.PaymentStartDate,
          PaymentInstallments: action.payload.PaymentInstallments,
          loanProductId: action.payload.loanProductId,
          gracePeriod: action.payload.gracePeriod,
          name: action.payload.name,
          identification: action.payload.identification,
          rate: action.payload.rate,
          downPayment: action.payload.downPayment
        },
        product: {
          id: action.payload.idLoanProduct,
          daysForClosure: action.payload.daysForClosureLoanProduct,
          loanProductRiskParameters: action.payload.loanProductRiskParameters,
          hasGracePeriod: action.payload.hasGracePeriodLoanProduct,
          description: action.payload.descriptionLoanProduct,
          interestRate: action.payload.interestRateLoanProduct,
          defaultPenalGraceDays: action.payload.defaultPenalGraceDaysLoanProduct,
          penalRate: action.payload.penalRateLoanProduct
        }
      };
    }
    case SET_ADDITIONAL_INFO: {
      return {
        ...state,
        additionalInfo: action.additionalInfo
      };
    }
    case SET_LOAN_REQUEST_VALIDATIONS: {
      return {
        ...state,
        validations: action.validations
      };
    }
    case SET_PRODUCTS_INFO:{
      return {
        ...state,
        productsInfo: action.productsInfo
      }
    }
    case SET_SELECT_LOANS:{
      return {
        ...state,
        selectLoans: action.selectLoans
      }
    }
    default: {
      return state;
    }
  }
};

export default loanRequestReducer;
