import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Link,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Page from 'src/components/Page';
import { useTranslation } from 'react-i18next';
import LogoTwo from 'src/components/LogoTwo';
import useDashboardByRole from 'src/hooks/useDashboardByRole';
import LoginForm from './LoginForm';
import VerifyEmailRecoveryPassword from '../Components/VerifyEmailRecoveryPassword';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    paddingBottom: 80,
    paddingTop: 80,
  },
  backButton: {
    marginLeft: theme.spacing(2),
  },
  card: {
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%',
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  icon: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 124,
  },
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
}));

function LoginView() {
  const classes = useStyles();
  const { t } = useTranslation();
  useDashboardByRole();

  const [openModal, setOpenModal] = useState(false);

  return (
    <Page className={classes.root} title="Login">
      <Container maxWidth="md">
        <Box alignItems="center" display="flex" mb={8} />
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <Avatar className={classes.icon}>
              <RouterLink to="/">
                <LogoTwo />
              </RouterLink>
            </Avatar>
            <Typography color="textPrimary" variant="h2">
              {t('Welcome')}
            </Typography>
            <Typography color="textSecondary" variant="subtitle1">
              {t('manageYourLoans')}
            </Typography>
            <Box mt={3}>
              <LoginForm />
            </Box>
            <Box my={2}>
              <Divider />
              <Typography color="textSecondary" variant="subtitle1">
                <Link
                  href="#"
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  {t('forgotYourPassword')}
                </Link>
              </Typography>
            </Box>
          </CardContent>
          <CardMedia
            className={classes.media}
            image="/static/images/auth.png"
            title="Cover"
          >
            <Typography color="inherit" variant="subtitle1">
              {t('loginDescription')}
            </Typography>
          </CardMedia>
        </Card>
      </Container>
      <VerifyEmailRecoveryPassword onClose={setOpenModal} open={openModal} />
    </Page>
  );
}

export default LoginView;
