import organizationService from 'src/services/organizationSettingService';
import { setMessageSnackbar } from './uiActions';

export const SET_ORGANIZATION_SETTING =
  '@organization/set-identification-types';

const setOrganizationSetting = (organizationSetting) => {
  return {
    type: SET_ORGANIZATION_SETTING,
    organizationSetting
  };
};

export const getOrganizationSetting = () => async (dispatch) => {
  try {
    const response = await organizationService.GetOrganizationSetting();
    dispatch(setOrganizationSetting(response[0]));
    return response;
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
    return null;
  }
};

export const setUpdateOrganizationSetting = () => async (dispatch, form) => {
  try {
    dispatch(setOrganizationSetting(form));
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
  }
};
