export const reportsEn = {
  'Avales Siigo': 'Siigo guarantees',
  'Desembolsos Siigo': 'Siigo disbursements',
  'EF Customers': 'EF Clients',
  'Informe de Cartera': 'Portfolio Report',
  'Informe de Cartera2': 'Portfolio Two Report',
  'Reporte Cartera con Saldos Pendientes':
    'Portfolio With Outstanding Balance Report',
  'Informe de Clientes': 'Clients Report',
  'Informe de Pagos': 'Payment Report',
  'Reporte de Pagos Ultimos 30 dias': 'Payment Report Last 30 Days',
  'informe de polizas': 'policy report',
  'Informe de Solicitudes': 'Application Report',
  'Reporte de Solicitudes Pendientes': 'Pending Application Report',
  'Informe de vehiculos': 'Vehicle report',
  'Reporte AskRobin': 'AskRobin report',
  'Reporte Datacredito': 'Datacredito Report',
  'Terceros Siigo': 'Third parties Siigo',
  'Informe de Clientes PN': 'Clients PN Report',
  'Reporte de Clientes Ultimos 30 Dias': 'Customer Report Last 30 Days',
  'Informe Cuotas': 'Quotas Report',
  'Informe de causacion de intereses': 'Booking Interest report',
  'Informe de Solicitudes de Aliados': 'Allied Applications Report',
  'Informe de envio de campaña': 'Campaign Mailing Report',
  cutOfDate: 'Cut Of Date',
  reportThirdPartiesSiigo:
    'Customer - supplier report from Siigo, this works without date filters.',
  reportDisbursementsSiigo:
    'Siigo Disbursement Report works without date filters.',
  reportDatacredit:
    'This report is from Datacredito Experian, it has a cut-off date filter.',
  reportRequests:
    'This report refers to loan applications and has a range of dates.',
  reportStudyRequests:
    'This report refers to requests in study status, it has a date range.',
  reportPayments:
    'This report refers to payments made on loans and has a range of dates.'
};

export const reportsEs = {
  'Avales Siigo': 'Avales Siigo',
  'Desembolsos Siigo': 'Desembolsos Siigo',
  'EF Customers': 'EF Clientes',
  'Informe de Cartera': 'Informe de Cartera',
  'Informe de Cartera2': 'Informe de Cartera2',
  'Reporte Cartera con Saldos Pendientes':
    'Reporte Cartera con Saldos Pendientes',
  'Informe de Clientes': 'Informe de Clientes',
  'Informe de Pagos': 'Informe de Pagos',
  'Reporte de Pagos Ultimos 30 dias': 'Reporte de Pagos Últimos 30 días',
  'informe de polizas': 'informe de pólizas',
  'Informe de Solicitudes': 'Informe de Solicitudes',
  'Reporte de Solicitudes Pendientes': 'Reporte de Solicitudes Pendientes',
  'Informe de vehiculos': 'Informe de vehiculos',
  'Reporte AskRobin': 'Reporte AskRobin',
  'Reporte Datacredito': 'Reporte Datacredito',
  'Terceros Siigo': 'Terceros Siigo',
  'Informe de Clientes PN': 'Informe de Clientes PN',
  'Reporte de Clientes Ultimos 30 Dias': 'Reporte de Clientes Últimos 30 Días',
  'Informe Cuotas': 'Informe Cuotas',
  'Informe de causacion de intereses': 'Informe de causación de intereses',
  'Informe de Solicitudes de Aliados': 'Informe de Solicitudes de Aliados',
  'Informe de envio de campaña': 'Informe de Envío de Campaña',
  cutOfDate: 'Fecha de corte',
  reportThirdPartiesSiigo:
    'Informe de clientes - proveedores de siigo, este funciona sin filtros de fechas.',
  reportDisbursementsSiigo:
    'Informe de desembolsos Siigo, este funciona sin filtros de fechas.',
  reportDatacredit:
    'Este informe es de Datacredito Experian, tiene filtro de fecha de corte.',
  reportRequests:
    'Este informe hace referencia a las solicitudes de préstamo, tiene rango de fechas.',
  reportStudyRequests:
    'Este reporte hace referencia a las solicitudes en estado estudio, tiene rango de fechas.',
  reportPayments:
    'Este informe hace referencia a los pagos realizados en los préstamos, tiene rango de fechas.'
};
