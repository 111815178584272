import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import {
 Box, Button, Portal, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    maxWidth: 600,
    position: 'fixed',
    bottom: 0,
    left: 0,
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    outline: 'none',
    zIndex: 2000,
  },
  action: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
}));

function CookiesNotification() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleClose = () => {
    Cookies.set('consent', 'true');
    setOpen(false);
  };

  useEffect(() => {
    const consent = Cookies.get('consent');

    if (!consent) {
      setOpen(true);
    }
  }, []);

  if (!open) {
    return null;
  }

  return (
    <Portal>
      <div className={classes.root}>
        <Typography color="inherit" variant="body1">
          {t('Cookies description')}
{' '}
          {/* <Link
            component="a"
            color="inherit"
            underline="always"
            href="https://devias.io/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </Link>
          . */}
        </Typography>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button
            className={classes.action}
            onClick={handleClose}
            variant="contained"
          >
            {t('I agree')}
          </Button>
        </Box>
      </div>
    </Portal>
  );
}

export default CookiesNotification;
