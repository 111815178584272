import { uniqueId } from 'lodash';
import { UI_FINISH_LOADING, UI_SET_MESSAGE_SNACKBAR, UI_START_LOADING } from '../actions/uiActions';

const initialState = {
  loading: false,
  msgSnackbar: {
    message: '',
    variant: ''
  }
};
export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case UI_START_LOADING:
      return {
        ...state,
        loading: true
      };

    case UI_FINISH_LOADING:
      return {
        ...state,
        loading: false
      };

    case UI_SET_MESSAGE_SNACKBAR:
      return {
        ...state,
        msgSnackbar: {
          ...action.payload, id: uniqueId()
        }
      };
    default:
      return state;
  }
};
