import { setMessageSnackbar } from './uiActions';

export const initialStateLoanFilter = {
  originator: null,
  customerType: null,
  autoPayment: 'all',
  status: [],
  nameOrIdentification: '',
};

export const SET_LOAN_FILTER = '@filter/set-loan-filter';

export function setLoanFilter(loanFilter) {
  return (dispatch) => dispatch({ type: SET_LOAN_FILTER, loanFilter });
}

export const updateKeyLoanFilter = (dispatch, key, value) => async () => {
  try {
    initialStateLoanFilter[key] = value;
    dispatch(setLoanFilter(initialStateLoanFilter));
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
  }
};

export const updateAllLoanFilter = (dispatch, dataFilter) => async () => {
  try {
    dispatch(setLoanFilter(dataFilter));
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
  }
};
