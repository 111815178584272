import loanProductParametersService from 'src/services/loanProductParametersServices';
import loanProductService from 'src/services/loanProductsService';
import { startLoading, finishLoading, setMessageSnackbar } from './uiActions';

export const LOANPRODUCT_ID = '@loanProduct/LOANPRODUCT_ID-success';
export const LOANPRODUCT_REQUEST_FAILURE =
  '@loanProduct/LOANPRODUCT_ID-failure';
export const ORIGINATOR_ID = '@loanProduct/ORIGINATOR_ID-success';
export const ORIGINATOR_REQUEST_FAILURE = '@loanProduct/ORIGINATOR_ID-failure';
export const SET_LOAN_PRODUCT_VALIDATIONS =
  '@loanProduct/set-loan-product-validations';
export const SET_LOAN_PRODUCT_TASKS = '@loanProduct/set-loan-product-tasks';
export const SET_LOAN_PRODUCT_DETAILS = '@loanProduct/set-loan-product-details';
export const SET_LOAN_PRODUCT_TASK_PARAMETER =
  '@loanProduct/set-loan-task-parameter';
export const SET_LOAN_PRODUCT_CONDITIONS_CHARGE =
  '@loanProduct/set-loan-product-conditions';
export const SET_LOAN_PRODUCT_CHARGE_FORM =
  '@loanProduct/set-loan-product-charge-form';

export const sendLoanProductId = (loanProduct) => ({
  type: LOANPRODUCT_ID,
  payload: {
    loanProduct
  }
});
export const setLoanProductDetails = (loanProductDetails) => ({
  type: SET_LOAN_PRODUCT_DETAILS,
  payload: {
    loanProductDetails
  }
});

export const setLoanProductTasksParameter = (loanProductTaskParameter) => ({
  type: SET_LOAN_PRODUCT_TASK_PARAMETER,
  payload: {
    loanProductTaskParameter
  }
});
export const sendOriginatorId = (loanProductOriginatorId) => ({
  type: ORIGINATOR_ID,
  payload: {
    loanProductOriginatorId
  }
});

export const setLoanProductConditions = (loanProductConditionsCharge) => ({
  type: SET_LOAN_PRODUCT_CONDITIONS_CHARGE,
  loanProductConditionsCharge
});

export const addLoanProductConditionsList =
  (loanProductConditionsCharge) => async (dispatch) => {
    try {
      dispatch(startLoading());
      dispatch(setLoanProductConditions(loanProductConditionsCharge));
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };

export const setLoanProductChargeForm = (loanProductChargeForm) => ({
  type: SET_LOAN_PRODUCT_CHARGE_FORM,
  loanProductChargeForm
});

export const setLoanProductValidations = (validations) => ({
  type: SET_LOAN_PRODUCT_VALIDATIONS,
  validations
});

export const getLoanProductValidations = (loanProductId) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await loanProductService.GetLoanProductValidations(
      loanProductId
    );
    dispatch(setLoanProductValidations(response));
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
  } finally {
    dispatch(finishLoading());
  }
};

export const updateOrCreateLoanProductValidation =
  (validation) => async (dispatch) => {
    try {
      dispatch(startLoading());
      const validationDTO = {
        Id: validation.id,
        Name: validation.name,
        Description: validation.description,
        Evidence: validation.evidence,
        ApplyFor: validation.applyFor,
        LoanProductId: validation.loanProductId
      };
      const response =
        await loanProductService.CreateOrUpdateLoanProductValidation(
          validationDTO
        );
      dispatch(setLoanProductValidations(response.data));
      dispatch(setMessageSnackbar(response, 'success'));
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };

export const getLoanProductDetails = (loanProductId) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await loanProductService.GetLoanProductById(loanProductId);
    dispatch(setLoanProductDetails(response));
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
  } finally {
    dispatch(finishLoading());
  }
};

export const CreateOrUpdateLoanProductParameters =
  (parameter) => async (dispatch) => {
    try {
      dispatch(startLoading());
      const response =
        await loanProductParametersService.CreateOrModifyLoanProductParameter(
          parameter
        );
      dispatch(getLoanProductDetails(parameter.loanProductId));
      dispatch(setMessageSnackbar(response, 'success'));
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };

const setLoanProductTasks = (data) => ({
  type: SET_LOAN_PRODUCT_TASKS,
  payload: data
});

export const getLoanProductTasks = (loanProductId) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await loanProductService.GetLoanProductTasks(
      loanProductId
    );
    dispatch(setLoanProductTasks(response?.processedObject));
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
  } finally {
    dispatch(finishLoading());
  }
};

export const updateOrCreateLoanProductParameterTask =
  (task) => async (dispatch) => {
    try {
      dispatch(startLoading());
      const taskDTO = {
        LoanProductId: parseInt(task.loanProductId, 10),
        QuantityLoans: parseInt(task.quantityLoans, 10),
        RoleId: task.roleId
      };

      const response =
        await loanProductService.UpdateOrCreateLoanProductParameterTask(
          taskDTO
        );
      dispatch(setLoanProductTasksParameter(response.processedObject));
      dispatch(setMessageSnackbar(response.message, 'success'));
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };

export const getLoanProductTasksParameter = (loanProductId) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await loanProductService.GetLoanProductTasksParameter(
      loanProductId
    );

    dispatch(setLoanProductTasksParameter(response.processedObject));
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
  } finally {
    dispatch(finishLoading());
  }
};

export const updateOrCreateLoanProductTask = (task) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const taskDTO = {
      Id: task.id,
      Name: task.name,
      Description: task.description,
      Evidence: task.evidence,
      ApplyFor: task.applyFor,
      LoanProductId: task.loanProductId,
      DaysAfterDisbursement: task.daysAfterDisbursement,
      Frequency: task.frequency,
      IsRecurring: task.isRecurring,
      Repeats: task.repeats
    };
    const response = await loanProductService.CreateOrUpdateLoanProductTask(
      taskDTO
    );
    dispatch(setLoanProductTasks(response.processedObject));
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
  } finally {
    dispatch(finishLoading());
  }
};

export const deleteLoanProductValidation =
  (loanProductValidationId) => async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await loanProductService.DeleteLoanProductValidation(
        loanProductValidationId
      );
      dispatch(setLoanProductValidations(response.data));
      dispatch(setMessageSnackbar(response, 'success'));
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };
