/* eslint-disable import/prefer-default-export */
import { applyMiddleware, createStore, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import rootReducer from 'src/store/reducers';
import { ENABLE_REDUX_LOGGER } from 'src/config';

const loggerMiddleware = createLogger();

const middlewares = [thunkMiddleware];

if (ENABLE_REDUX_LOGGER) {
  middlewares.push(loggerMiddleware);
}
const middlewareEnhancer = composeWithDevTools(applyMiddleware(...middlewares));

const enhancers = [middlewareEnhancer];
const composedEnhancers = compose(...enhancers);

export const configureStore = createStore(rootReducer, composedEnhancers);

export default configureStore;