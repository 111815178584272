import routes from 'src/config/api.routes';
import axiosService from './axiosService';

class CompanyService {
  // Old implementation after remove
  UpdateCompanyByEntityId = async (entityId, companyDTO) =>
    axiosService.put(`${routes.entity}/company/${entityId}`, companyDTO);

  /// Request
  getCompanyLoanRequest = async (entityId) =>
    axiosService.get(`${routes.entity}/${entityId}/loan-request`);

  /// Loans
  getCompanyLoans = async (entityId) =>
    axiosService.get(`${routes.entity}/${entityId}/loans`);

  /// General information
  GetCompany = async (entityId) =>
    axiosService.get(`${routes.company}/general-info/company/${entityId}`);

  UpdateCompany = async (company) =>
    axiosService.put(`${routes.company}/general-info/company`, company);

  GetContactsOfCompany = async (companyId) =>
    axiosService.get(`${routes.company}/general-info/contacts/${companyId}`);

  UpdateContactsOfCompany = async (contacts) =>
    axiosService.put(`${routes.company}/general-info/contacts`, contacts);

  GetOwners = async (companyId) =>
    axiosService.get(`${routes.company}/general-info/owners/${companyId}`);

  UpdateOwners = async (companyId, owners) =>
    axiosService.put(
      `${routes.company}/${companyId}/general-info/owners`,
      owners
    );

  DeleteOwner = async (ownerId) =>
    axiosService.delete(`${routes.company}/general-info/owners/${ownerId}`);

  DeleteContact = async (contactId) =>
    axiosService.delete(`${routes.company}/general-info/contact/${contactId}`);

  GetBanksOfCompany = (companyId) =>
    axiosService.get(`${routes.company}/general-info/banks/${companyId}`);

  UpdateBanksOfCompany = async (banks) =>
    axiosService.put(`${routes.company}/general-info/banks`, banks);

  DeleteBank = async (bankId) =>
    axiosService.delete(`${routes.company}/general-info/bank/${bankId}`);

  /// Financial information
  GetLoanProceeds = async (companyId) =>
    axiosService.get(
      `${routes.company}/financial-info/loan-proceeds/${companyId}`
    );

  UpdateLoanProceeds = async (loanProceed) =>
    axiosService.put(
      `${routes.company}/financial-info/loan-proceeds`,
      loanProceed
    );

  GetBusinessDebts = async (companyId) =>
    axiosService.get(
      `${routes.company}/financial-info/business-debts/${companyId}`
    );

  UpdateBusinessDebt = async (debt) =>
    axiosService.put(`${routes.company}/financial-info/business-debts`, debt);

  GetLegalQuestions = async (companyId) =>
    axiosService.get(`${routes.company}/legal-questions/${companyId}`);

  UpdateLegalQuestions = async (companyId, legalQuestionsDTO) =>
    axiosService.put(
      `${routes.company}/legal-questions/${companyId}`,
      legalQuestionsDTO
    );

  updateSourceIncome = async (companyId, body) =>
    axiosService.put(`${routes.sourceIncome}/${companyId}s`, body);

  updateStateCompany = async (companyId, comentario) =>
    axiosService.put(`${routes.company}/${companyId}/comment/${comentario}`);

  updateSourceIncome = async (sourceIncome) =>
    axiosService.put(
      `${routes.sourceIncome}/financial-info/source-incomes`,
      sourceIncome
    );

  UpdateExpensesAndIncome = async (body) =>
    axiosService.post(`${routes.company}/FinancialInfo/`, body);

  GetExpensesAndIncome = async (companyId) =>
    axiosService.get(`${routes.company}/FinancialInfo/${companyId}`);

  // Company Assets
  GetAssets = async (companyId) =>
    axiosService.get(`${routes.company}/financial-info/assets/${companyId}`);

  UpdateAssets = async (asset) =>
    axiosService.put(`${routes.company}/financial-info/assets`, asset);

  DeleteAutomobileOfCompany = async (automobileId) =>
    axiosService.delete(
      `${routes.company}/financial-info/assets/automobiles/${automobileId}`
    );

  UpdateAutomobilesOfCompany = async (automobiles) =>
    axiosService.put(
      `${routes.company}/financial-info/assets/automobiles`,
      automobiles
    );

  DeleteOtherAssetsOfCompany = async (otherId) =>
    axiosService.delete(
      `${routes.company}/financial-info/assets/other/${otherId}`
    );

  UpdateOtherAssetsOfCompany = async (companyId, others) =>
    axiosService.put(
      `${routes.company}/${companyId}/financial-info/assets/others`,
      others
    );

  UpdateAssetsLifeInsuranceOfCompany = async (lifeInsurance) =>
    axiosService.put(
      `${routes.company}/financial-info/assets/lifeInsurance`,
      lifeInsurance
    );

  DeleteStockBondOfCompany = async (stockBondId) =>
    axiosService.delete(
      `${routes.company}/financial-info/assets/stocks-bonds/${stockBondId}`
    );

  UpdateStocksBondsOfCompany = async (stocksBonds) =>
    axiosService.put(
      `${routes.company}/financial-info/assets/stocks-bonds`,
      stocksBonds
    );

  deletePropertyOfCompany = async (propertyId) =>
    axiosService.delete(
      `${routes.company}/financial-info/assets/properties/${propertyId}`
    );

  UpdatePropertiesOfCompany = async (properties) =>
    axiosService.put(
      `${routes.company}/financial-info/assets/properties`,
      properties
    );

  /// Source Income
  getSourceIncomeCompany = async (companyId) =>
    axiosService.get(
      `${routes.company}/${companyId}/financial-info/sources-of-income/`
    );

  updateSourceIncomeCompany = async (companyId, sourceIncome) =>
    axiosService.put(
      `${routes.company}/${companyId}/financial-info/sources-of-income/`,
      sourceIncome
    );

  /// // Guarantor
  GetGuarantorList = async (relatedPartyId) =>
    axiosService.get(
      `${routes.relatedParties}/related-parties-list/${relatedPartyId}`
    );

  /// / Guarantor - Personal Information
  UpdatePersonalInformationRelatedParties = async (relatedPartyId, body) =>
    axiosService.post(
      `${routes.relatedParties}/${relatedPartyId}/personal-info`,
      body
    );

  // Guarantor - Legal Questions
  getGuarantorLegalQuestions = async (relatedPartyId) =>
    axiosService.get(
      `${routes.relatedParties}/${relatedPartyId}/related-party-legal-questions`
    );

  updateGuarantorLegalQuestions = async (relatedPartyId, legalQuestionsDTO) =>
    axiosService.post(
      `${routes.relatedParties}/${relatedPartyId}/legal-questions`,
      legalQuestionsDTO
    );

  /// Guarantor - Source Income
  getSourceIncomeGuarantor = async (relatedPartyId) =>
    axiosService.get(
      `${routes.relatedParties}/${relatedPartyId}/related-party-sources-of-income`
    );

  updateSourceIncomeGuarantor = async (relatedPartyId, sourceIncomeGuarantor) =>
    axiosService.post(
      `${routes.relatedParties}/${relatedPartyId}/sources-of-income`,
      sourceIncomeGuarantor
    );
}

const companyService = new CompanyService();
export default companyService;
