import usersService from 'src/services/usersService';
import { finishLoading, setMessageSnackbar, startLoading } from './uiActions';

export const SET_LIST_USERS = '@user/set-list-users';
export const SET_SELECTED_USER = '@user/set-selected-user';

export function getAllUsers() {
  return async (dispatch) => {
    try {
      const data = await usersService.GetAllUsers();
      dispatch(setListUsers(data.data.result, data.data.elements));
    } catch (error) {
      // throw error;
    }
  };
}
export function setListUsers(users, count) {
  return {
    type: SET_LIST_USERS,
    users,
    count,
  };
}

export function getUserById(id) {
  return async (dispatch) => {
    try {
      const data = await usersService.GetUserById(id);
      const userRedux = {
        ...data.data.personIdentificationDTO,
        phoneNumber: data.data.phoneNumber,
        email: data.data.email,
        originators: data.data.originatorName,
        rol: data.data.personRol,
        id: data.data.Id,
      };
      dispatch(setSelectedUser(userRedux));
    } catch (error) {
      throw error;
    }
  };
}
export function setSelectedUser(user) {
  return {
    type: SET_SELECTED_USER,
    user,
  };
}

export const createUser = (newUser) => async (dispatch) => {
  dispatch(startLoading());
  let isCreate = true;
  try {
    const userDTO = {
      rolName: newUser.roles,
      originatorCodes: newUser.originators,
      personIdentificationDTO: {
        name: newUser.name,
        name2: newUser.name2,
        lastName: newUser.lastName,
        lastName2: newUser.lastName2,
        gender: newUser.gender,
        IdentificationDTO: {
          identificationType: newUser.identificationType,
          identificationNumber: newUser.identificationNumber,
        },
      },
      AccountDTO: {
        email: newUser.email,
        phoneNumber: newUser.phoneNumber,
      },
    };
    const response = await usersService.CreateUser(userDTO);
    dispatch(setMessageSnackbar(response, 'success'));
    dispatch(setSelectedUser({}));
  } catch (error) {
    dispatch(setMessageSnackbar(error, 'error'));
    const userRedux = {
      name: newUser.name,
      name2: newUser.name2,
      lastName: newUser.lastName,
      lastName2: newUser.lastName2,
      gender: newUser.gender,
      identificationDTO: {
        identificationType: newUser.identificationType,
        identificationNumber: newUser.identificationNumber,
      },
      phoneNumber: newUser.phoneNumber,
      email: newUser.email,
      originators: newUser.originators,
      rol: newUser.roles,
    };
    dispatch(setSelectedUser(userRedux));
    isCreate = false;
  } finally {
    dispatch(finishLoading());
    return isCreate;
  }
};

export const updateUser = (newUser, user) => async (dispatch) => {
  dispatch(startLoading());
  let isCreate = true;
  try {
    const userDTO = {
      userId: newUser.id,
      rolName: newUser.roles,
      originatorsName: newUser.originators,
      personIdentificationDTO: {
        id: user.id,
        name: newUser.name,
        name2: newUser.name2,
        lastName: newUser.lastName,
        lastName2: newUser.lastName2,
        gender: newUser.gender,
        IdentificationDTO: {
          ...user.identificationDTO,
          identificationType: newUser.identificationType,
          identificationNumber: newUser.identificationNumber,
        },
      },
      accountDTO: {
        email: newUser.email,
        phoneNumber: newUser.phoneNumber,
      },
    };
    const response = await usersService.UpdateUser(userDTO);
    dispatch(setMessageSnackbar(response, 'success'));
    dispatch(getAllUsers());
  } catch (error) {
    dispatch(setMessageSnackbar(error, 'error'));
    isCreate = false;
  } finally {
    dispatch(finishLoading());
    return isCreate;
  }
};

export const generateCodesPassword = (Email, redirect) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await usersService.GenerateCodesPassword(Email);
    dispatch(setMessageSnackbar(response, 'success'));
    if (redirect) {
 setTimeout(
        () => (window.location.href = `/recovery?email=${ Email}`),
        1000,
      );
}
  } catch (error) {
    dispatch(setMessageSnackbar(error, 'error'));
  } finally {
    dispatch(finishLoading());
  }
};

export const createPassword = (InfoCreatePassword) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await usersService.CreatePassword(InfoCreatePassword);
    dispatch(setMessageSnackbar(response, 'success'));
  } catch (error) {
    dispatch(setMessageSnackbar(error, 'error'));
  } finally {
    dispatch(finishLoading());
  }
};
