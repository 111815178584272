import { SET_TASKS , SET_ASIGNEES , SET_LOAN_USER_FULLNAME,SET_TASK, SET_RECURRING_TASK, SET_PENDING_TASKS, SET_TASK_SAVED } from "../actions/taskActions";


const initialState = {
  tasks:{
    isSetted:false,
    tasks:[],
    taskSaved: false
  },
  task:{
    isSetted:false,
    name:'',
    description:'',
    asigneeId:'',
    loanId:'',
    isRecurring:false,
  },
  recurringTask:{
    startDate:'',
    endDate:'',
    frequency:'',
    dayOfWeek:'',
    dayOfMonth:'',
  },
  asignees:{
    isSetted:false,
    asignees:[]
  },
  pendingTasks:{
    isSetted:false,
    pendingTasks:[]
  },
  loanUserFullName:''
};
const taskReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case SET_TASKS:{
      return {
        ...state,
        tasks:action.payload
      };
    }
    case SET_PENDING_TASKS:{
      return {
        ...state,
        pendingTasks:action.payload
      }
    }
    case SET_TASK:{
      return {
        ...state,
        task:action.payload
      };
    }
    case SET_RECURRING_TASK:{
      return {
        ...state,
        recurringTask:action.payload
      };
    }
    case SET_ASIGNEES:{
      return {
        ...state,
        asignees:action.payload
      };
    }
    case SET_LOAN_USER_FULLNAME:{
      return {
        ...state,
        loanUserFullName:action.payload
      };
    }
    case SET_TASK_SAVED:{      
      return {
        ...state,
        taskSaved:action.payload
      };
    }
    default:
      return state;
  }
};

export default taskReducer;
