import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
 Box, Button, Dialog, TextField, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  CheckSquare as CheckSquareIcon,
  FileText as FileTextIcon,
} from 'react-feather';
import { useTranslation } from 'react-i18next';
import {
  addSingleTaskAttachments,
  completeTask,
} from 'src/store/actions/taskActions';

import ModalEvidences from './ModalEvidences';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  helperText: {
    textAlign: 'rigth',
    marginRight: 0,
  },
  authorBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  completeBtn: {
    color: '#2ED47A',
    backgroundColor: '#e0f1ea',
    borderColor: '#2ED47A',
  },
  actionButton: {
    marginLeft: '12px',
  },
}));

function CompleteTask({
  task, // Task to complete
  title, // Action title
  open, // Open dialog variable
  onClose, // Function executed on dialog close
  setCompleteDialog, // for when the task is completed make the update
}) {
  const classes = useStyles();
  const [comments, setComments] = useState(task?.recuringTask?.comments ?? '');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [filesTask, setFilesTask] = useState([]);
  const handleSubmit = () => {
    if (filesTask.length !== 0) {
      const values = {
        files: filesTask,
        taskId: task?.id ?? 0,
      };
      dispatch(addSingleTaskAttachments(values));
    }
    dispatch(completeTask(task.id, comments));
    setCompleteDialog(true);
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
      <div className={clsx(classes.root)}>
        <Box display="flex" justifyContent="center" width="100%">
          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            p={1}
            style={{
              backgroundColor: '#00408e',
              borderRadius: '25px',
              width: '50px',
              height: '50px',
            }}
          >
            <FileTextIcon color="#fff" size={40} />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="100%"
        >
          <Typography align="center" variant="h5">
            {task?.name}
          </Typography>
          <Typography
            align="center"
            className={classes.textField}
            color="textSecondary"
            variant="h6"
          >
            {task?.description}
          </Typography>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="100%"
        >
          <TextField
            autoFocus
            fullWidth
            label={t('comments')}
            multiline
            name="comments"
            onChange={(e) => setComments(e.target.value)}
            rows={5}
            value={comments}
            variant="outlined"
          />
          <Box mt={2} />
          <ModalEvidences
            filesTask={filesTask}
            setFilesTask={setFilesTask}
            task={task}
          />
        </Box>
        <Box display="flex" justifyContent="space-between" mt={3}>
          <Button onClick={onClose} variant="outlined">
            {t('cancel')}
          </Button>
          <Button
            className={classes.completeBtn}
            onClick={handleSubmit}
            type="submit"
            variant="contained"
          >
            {t('complete')}
            <CheckSquareIcon className={classes.actionButton} />
          </Button>
        </Box>
      </div>
    </Dialog>
  );
}

CompleteTask.propTypes = {
  task: PropTypes.any,
  title: PropTypes.any,
  open: PropTypes.any,
  onClose: PropTypes.func,
};

CompleteTask.defaultProps = {
  onClose: () => {},
};

export default CompleteTask;
