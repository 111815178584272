export const originatorsEn = {
  accountName: 'Account Name',
  accountNumber: 'Account Number',
  accountType: 'Account Type',
  actions: 'Actions',
  activate: 'Activate',
  Add: 'Add',
  'Already registered originator code': 'Already registered originator code',
  bankInformation: 'Bank Information',
  bankName: 'Bank Name',
  city: 'City',
  Code: 'Code',
  collectionChannels: 'Collection Channels',
  'Created By': 'Created By',
  createdBy: 'Created By',
  'Creation Date': 'Creation Date',
  creationDate: 'Creation Date',
  customerSegments: 'Borrowers Segments',
  department: 'Department',
  deleteOriginator: 'Delete Originator',
  Description: 'Description',
  'Error creating originator': 'Error creating originator',
  'Error deleting originator': 'Error deleting originator',
  'Error updating originator': 'Error updating originator',
  financialInformation: {
    accountAndNotes: 'Account & Notes Receivable',
    annual: 'Annual Salary',
    assets: 'Assets',
    cashOn: 'Cash on Hand & in Banks',
    collection: 'Collection per Month',
    creditCard: 'Credit Card Balances',
    employer: 'Employer Name',
    item: 'Item',
    Liabilities: 'Liabilities',
    other: 'Other Assets',
    otherLiablities: 'Other Liabilities',
    outStanding: 'any outstanding personal installaments loans',
    pledge: 'Pledge Collateral',
    realState: 'Real State with Rental Income',
    retirement: 'Retirement Account',
    saving: 'Saving Accounts',
    sourceIncome: 'Source Income',
    total: 'Total Assets',
    totalAutomobile: 'Total Automobile Value',
    totalProperty: 'Total Property Value',
    totalStocks: 'Total Value of Stocks and Bounds',
    totalValue: 'Total Value of Life Insurance',
    unPaid: 'Unpaid Taxes',
    value: 'Value',
    warranty: 'Promised Warranty',
  },
  founder: 'Founder',
  funder: 'Founder',
  Guarantor: {
    accountReiceivable: 'Account & Notes Receivable',
    actions: 'Actions',
    addGuarantor: 'Add Guarantor',
    addProperty: 'ADD PROPERTY',
    addressOne: 'Address line 1',
    addressTwo: 'Address line 2',
    addStock: 'ADD STOCK',
    adevertmentDeleteProperty: 'Are you sure you want to eliminate property?',
    adevertmentDeleteStockAndOrBonds:
      'Are you sure you want to eliminate stocks and/or bonds?',
    amountPaymentPerMonth: 'Amount Payment per month',
    anyTaxLine: 'Does This property any tax liens ?',
    assets: 'Assets',
    authorization: 'Authorization',
    avaliableCashSurrender: 'What is te policy have a cash surrender?',
    bankInformation: 'Bank information',
    cashOnHand: 'Cash on Hand & Banks',
    city: 'City',
    commercialRental: 'Commercial Rental Property',
    company: 'Company',
    contacts: 'Contacts',
    cost: 'Cost',
    creationDate: 'Creation Date',
    current: 'Current',
    currentBalanceSecure: 'What is the current balance?',
    dateOfExchanges: 'Date of quatation/exchanges',
    datePurchased: 'Date Purchased',
    deleteBusiness: 'Delete business',
    deleteProperty: 'Delete stocks Property',
    deleteStockAndOrBonds: 'Delete stocks and/or bonds',
    faceValueAmount: 'What is the face value amount',
    financialInformation: 'FINANCIAL INFORMATION',
    generalInformation: 'General Information',
    Guarantor: 'Guarantor',
    guarantorNotExist: 'Guarantor not exist',
    haveLoanSecureByLifeInsurence:
      'Have a loan secure by lifeinsurence policy?',
    haveOwnStock: 'Have own stocks and/or bonds ?',
    helocBalance: 'HELOC Balance',
    helocCreditLimit: 'HELOC credit limit',
    helocIncludeTax: 'Does this HELOC payment include taxes and any insurance',
    homeEquity: 'Have a home equity of credit(HELOC)',
    howMuchDoYouCollectPerMonth: 'How much do you collect per month ?',
    howOwnProperty: 'How own properties?',
    insurenceBeneficiaries: 'Who is/Are beneficiarie',
    legalQuestion: 'LEGAL QUESTIONS',
    liabilities: 'Liabilities',
    lifeInsurance: 'Total Value of Life Insurance',
    lifeInsurence: 'Life Insurence',
    makeExchanges: 'Market Value Quotation/Exchange',
    marketValue: 'Present Market Value',
    monthlyPayment: 'What is the monthly payment?',
    moreThanOneMonth: 'More Than 1 Month Behind',
    mortagePayment: 'Mortgage payment include taxes and any insurance',
    mortgageBalance: 'Mortgage Balance',
    nameOfHolder: 'Name of Mortgage Holder',
    nameOfInsuranceCompany: 'Name of Insurance Company',
    nameOfMortageHolder: 'Name of Mortgage Holder',
    nameOfSecurity: 'Name Stock/Bond',
    numberOfShare: 'Number of Share',
    oneMonthBehind: '1 Month Behind',
    originalCost: 'Original Cost',
    otherAssets: 'Other Assets',
    otherRetirement: 'Other Retirement Account',
    owners: 'Owners',
    personalInformation: 'PERSONAL INFORMATION',
    personalResindence: 'Personal Residence',
    policyCashSurrender: 'This policy hava a cash surrender?',
    residentialRental: 'Residential Rental Property',
    savingAccount: 'Saving Accounts',
    sourceIncome: 'Source Income',
    state: 'State',
    statusOfHeloc: 'Status of HELOC',
    statusOfMortgage: 'Status of Mortgage',
    stockAndOrBonds: 'Stock and/or Bonds',
    stockBonds: 'Stock and/or Bonds',
    totalAutomobileValue: 'Total Automobile Value',
    totalPropertyValue: 'Total Property Value',
    totalValue: 'Total Value',
    totalValueOfStock: 'Total Value of Stocks and Bonds',
    typeOfProperty: 'Type of Property',
    vacantLand: 'Vacant Land',
    vacationHome: 'Vacation Home',
    warningDeleteBussiness: 'Are you sure you want to eliminate the business?',
    zip: 'Zip code',
  },
  GuarantorDetails: {
    bussinessName: 'Bussiness Name',
    city: 'City',
    email: 'Email',
    listAnyBussiness: 'List Any Bussiness Owned by',
    monthly: 'Monthly Rent/Mortage',
    ownerShip: '% of OwnerShip',
    ownerShipPorcen: 'OwnerShip % ',
    phone: 'Phone',
    residenceAddress: 'Residence Address',
    state: 'State',
    title: 'Title',
    typeofOwner: 'Type of Owner',
    whoOwns: 'Who Owns 20% or More of the Company',
    yearsInAddress: 'How many years have you lived at this address',
    zipCode: 'ZIP Code',
  },
  id: 'Id',
  idNumberAlreadyExists: 'Id number already exists',
  legalQuestion: {
    againsTo: 'Against to?',
    amount: 'Amount',
    bankruptcy: 'Have ever filled bankruptcy?',
    describeDetails: 'Describe in Details',
    description: 'Description',
    hasLawsuits: 'Has lawsuits?',
    hasLiensOrJudgments: 'Has liens or judgments?',
    lawsuits: 'There are lawsuits pending?',
    outStanding:
      'There are liensor Jugdments outstanding against the bussiness or the principals?',
    type: 'Type',
    whatoutCome: 'What was the outcome?',
    whom: 'Against whom?',
  },
  MustBeOnlyAlphabetic: 'Must be only alphabetic',
  // Form
  MustBeOnlyDigits: 'Must be only digits',
  MustBeValidCodeOriginator: 'Must be a valid code originator',
  MustBeValidIdResponsible: 'Must be a valid id responsible',
  notOriginatorsFound: 'No originators found',
  nameOriginator: 'Name',
  nameOriginatorSearch: 'Enter the id or name of the originator',
  'Originator does not exist': 'Originator does not exist',
  'Originator has active loans': 'Originator has active loans',
  'Originator has past due loans': 'Originator has past due loans',
  // Response Messages
  'Originator Registered': 'Originator registered',
  'Originator removed': 'Originator removed',
  'Originator Updated': 'Originator updated',
  originatorId: 'ID Originator',
  OriginatorId: 'Originator Name',
  originators: 'Originators ',
  OriginatorsCreate: 'Create Originator',
  OriginatorsDetail: "Originator's Details",
  OriginatorsList: 'List Originators ',
  OriginatorsUpdate: 'Update originator',
  originatorUpdatedSuccess: 'Originator updated successfully',
  otherLiablities: {
    addLiabilities: 'ADD LIABILITIES',
    currentBalance: 'Current Balance',
    currentBalanceOnLoan: 'Current Balance on Loan',
    monthlyPayment: 'Monthly Payment',
    name: 'Name',
    other: 'Other Liabilities',
    paymentAmount: '  Payment Amount',
    totalOtherLiabilities: 'Total Other Liabilities',
    toWhomPayable: 'To Whom Payable',
  },
  paymentMethod: 'Payment Method',
  paymentMethods: 'Payment Methods',
  relatedPartyCreatedSuccessfully: 'Guarantor created successfully',
  relatedPartyGeneralSuccessfully:
    'Guarantor general assets saved successfully',
  relatedPartyNotFound: 'Guarantor not found',
  relatedPartyRealStatesSuccessfully:
    'Guarantor real states updated successfully',
  Remove: 'Remove',
  Required: 'Required',
  ResponsibleId: 'Identification of the Originator',
  successful: 'Successful',
  userNotFound: 'User not found',
  'Write a short note about why the record should be deleted':
    'Write a short note about why the record should be deleted',
  detailsOriginator: 'Originator Details',
  itHasNot: 'It has not',
  accountNumberLength: 'The account number must be between 7 to 15 characters.',
  glBankAccount: 'GL Bank Account',
  AHO: 'Saving',
  CTE: 'Current',
};

export const originatorsEs = {
  accountName: 'Nombre de la cuenta',
  accountNumber: 'Número de cuenta',
  accountType: 'Tipo de cuenta',
  actions: 'Acciones',
  activate: 'Activar',
  Add: 'Añadir',
  'Already registered originator code': 'Código de originador ya registrado',
  bankInformation: 'Información bancaria',
  bankName: 'Nombre del banco',
  city: 'Ciudad',
  Code: 'Código',
  collectionChannels: 'Canales de recaudo',
  'Created By': 'Creado Por',
  createdBy: 'Creado por',
  'Creation Date': 'Fecha de creación',
  creationDate: 'Fecha de creación',
  customerSegments: 'Segmentos de clientes',
  department: 'Departamento',
  Description: 'Descripción',
  'Error creating originator': 'Error al crear originador',
  'Error deleting originator': 'Error al eliminar originador',
  'Error updating originator': 'Error al Actualizar Originador',
  financialInformation: {
    accountAndNotes: 'Cuenta y pagarés',
    annual: 'Salario Anual',
    assets: 'Activos',
    cashOn: 'Efectivo en mano y en bancos',
    collection: 'Recaudación por mes',
    creditCard: 'Saldo de la tarjeta de crédito',
    employer: 'Nombre del empleador',
    item: 'Artículo',
    Liabilities: 'Pasivo',
    other: 'Otros activos',
    otherLiablities: 'Otros pasivos',
    outStanding: 'cualquier préstamo personal a plazos pendiente',
    pledge: 'Prenda de garantía',
    realState: 'Inmueble con ingresos de alquiler',
    retirement: 'Cuenta de jubilación',
    saving: 'Cuentas de ahorro',
    sourceIncome: 'Origen de ingresos',
    total: 'Total de activos',
    totalAutomobile: 'Valor total del automóvil',
    totalProperty: 'Valor total de la propiedad',
    totalStocks: 'Valor total de las acciones y bonos',
    totalValue: 'Valor total de los seguros de vida',
    unPaid: 'Impuestos no pagados',
    value: 'Valor',
    warranty: 'Garantía prometida',
  },
  founder: 'Fondeador',
  funder: 'Fondeador',
  deleteOriginator: 'Borrar Originador',
  Guarantor: {
    accountReiceivable: 'Cuentas y documentos por cobrar',
    actions: 'Acciones',
    addGuarantor: 'Añadir garante',
    addProperty: 'Añadir propiedad',
    addressOne: 'Dirección línea 1',
    addressTwo: 'Dirección línea 2',
    adevertmentDeleteStockAndOrBonds:
      'Está seguro de eliminar el registro de acciones y/o bonos?',
    amountPaymentPerMonth: 'Importe del pago por mes',
    anyTaxLine: 'Tiene su propiedad algún gravamen de impuestos ?',
    authorization: 'Autorizacion',
    avaliableCashSurrender:
      '¿Qué es lo que la póliza tiene un rescate en efectivo?',
    bankInformation: 'Información bancaria',
    cashOnHand: 'Efectivo en mano y bancos',
    city: 'Ciudad',
    commercialRental: 'Propiedad de alquiler comercial',
    company: 'Empresa',
    contacts: 'Contactos',
    cost: 'Coste',
    creationDate: 'Fecha de creación',
    current: 'Actual',
    currentBalanceSecure: '¿Cuál es el saldo actual?',
    dateOfExchanges: 'Fecha de Cuota/Intercambio de valores',
    datePurchased: 'Fecha de compra',
    deleteBusiness: 'Borrar negocio',
    deleteProperty: 'Eliminar la propiedad de las acciones',
    deleteStockAndOrBonds: 'Eliminar acciones y/o bonos',
    faceValueAmount: 'Cuál es el importe del valor nominal',
    financialInformation: 'INFORMACION FINANCIERA',
    generalInformation: 'Información general',
    Guarantor: 'Garante',
    guarantorNotExist: 'parte relacionada no existe',
    haveLoanSecureByLifeInsurence:
      '¿Tiene un préstamo asegurado por la póliza de seguro de vida?',
    haveOwnStock: '¿Tiene acciones y/o bonos propios?',
    helocBalance: 'Saldo del HELOC',
    helocCreditLimit: 'Límite de crédito HELOC',
    helocIncludeTax:
      'Este pago de HELOC incluye los impuestos y cualquier seguro',
    homeEquity: 'Tiene un crédito hipotecario (HELOC)',
    howMuchDoYouCollectPerMonth: '¿Cuánto cobra al mes?',
    howOwnProperty: 'Cómo posees las propiedades ?',
    insurenceBeneficiaries: 'Quién es/son los beneficiarios',
    legalQuestion: 'PREGUNTAS LEGALES',
    lifeInsurance: 'Valor total de los seguros de vida',
    lifeInsurence: 'Seguro de vida',
    makeExchanges: 'Cotización de valor de mercado/intercambio',
    marketValue: 'Valor actual de mercado',
    monthlyPayment: '¿Cuál es la cuota mensual?',
    moreThanOneMonth: 'Con más de 1 mes de retraso',
    mortagePayment:
      'El pago de la hipoteca incluye los impuestos y cualquier seguro',
    mortgageBalance: 'Saldo de la hipoteca',
    nameOfHolder: 'Nombre del titular de la hipoteca',
    nameOfInsuranceCompany: 'Nombre de la compañía de seguros',
    nameOfMortageHolder: 'Nombre del titular de la hipoteca',
    nameOfSecurity: 'Nombre de acción/bono',
    numberOfShare: 'Número de acciones',
    oneMonthBehind: '1 mes de retraso',
    originalCost: 'Coste original',
    otherAssets: 'Otros activos',
    otherRetirement: 'Otra cuenta de jubilación',
    owners: 'Propietarios',
    personalInformation: 'INFORMACION PERSONAL',
    policyCashSurrender: 'Esta póliza tiene un rescate en efectivo',
    residentialRental: 'Propiedad de alquiler residencial',
    savingAccount: 'Cuentas de ahorro',
    state: 'Estado',
    statusOfHeloc: 'Estado del HELOC',
    statusOfMortgage: 'Estado de la hipoteca',
    stockAndOrBonds: 'Acciones y/o bonos',
    totalAutomobileValue: 'Valor total del automóvil',
    totalPropertyValue: 'Valor total de la propiedad',
    totalValue: 'Valor total',
    totalValueOfStock: 'Valor total de acciones y bonos',
    typeOfProperty: 'Tipo de propiedad',
    vacantLand: 'Terreno vacío',
    vacationHome: 'Casa de vacaciones',
    warningDeleteBussiness: '¿Está seguro de que quiere eliminar el negocio?',
    warningDeleteProperty: '¿Está seguro de que quiere eliminar la propiedad?',
    zip: 'Código postal',
  },
  GuarantorDetails: {
    bussinessName: 'Nombre de la empresa',
    city: 'Ciudad',
    email: 'Correo',
    listAnyBussiness: 'Enumerar cualquier negocio que sea propiedad de',
    monthly: 'Alquiler/morosidad mensual',
    ownerShip: '% de propiedad',
    ownerShipPorcen: 'Propiedad % ',
    phone: 'Telefono',
    residenceAddress: 'Direccion de residencia',
    state: 'Departamento',
    title: 'Titulo',
    typeofOwner: 'Tipo de propietario',
    whoOwns: 'Quién posee el 20% o más de la empresa',
    yearsInAddress: 'Cuántos años ha vivido en esta dirección',
    zipCode: 'Codigo Postal',
  },
  id: 'Id',
  idNumberAlreadyExists: 'Número de identificación ya existe',
  legalQuestion: {
    againsTo: '¿En contra de?',
    amount: 'Monto',
    bankruptcy: '¿Se ha declarado en banca rota?',
    describeDetails: 'Describa en detalle',
    description: 'Description',
    hasLawsuits: '¿Tiene demandas pendientes?',
    hasLiensOrJudgments: '¿Tiene embargos o juicios?',
    lawsuits: '¿Hay demandas pendientes?',
    outStanding:
      '¿Hay embargos o juicios pendientes contra la empresa o los directores?',
    type: 'Tipo',
    whatoutCome: '¿Cúal fue el resultado?',
    whom: '¿Contra quién?',
  },
  MustBeOnlyAlphabetic: 'Debe ser sólo alfabético',
  // Form
  MustBeOnlyDigits: 'Deben ser sólo números',
  MustBeValidCodeOriginator: 'Debe ser un emisor de código válido',
  MustBeValidIdResponsible: 'Debe ser una identificación válida responsable',
  notOriginatorsFound: 'No se encontraron originadores',
  nameOriginator: 'Nombre',
  nameOriginatorSearch: 'Ingrese el id o el nombre del originador',
  'Originator does not exist': 'Originador no existe',
  'Originator has active loans': 'Originador tiene préstamos activos',
  'Originator has past due loans': 'Originador tiene préstamos vencidos',
  // Response Messages
  'Originator Registered': 'Originador Registrado',
  'Originator removed': 'Originador eliminado',
  'Originator Updated': 'Originador Actualizado',
  originatorId: 'ID originador',
  OriginatorId: 'Nombre del originador',
  originators: 'Originadores',
  OriginatorsCreate: 'Crear Originador',
  OriginatorsDetail: 'Detalle de Originador',
  OriginatorsList: 'Lista de Originadores',
  OriginatorsUpdate: 'Editar Originador',
  originatorUpdatedSuccess: 'Originador actualizado con éxito',
  otherLiablities: {
    addLiabilities: 'AGREGAR PASIVOS',
    currentBalance: 'Saldo actual',
    currentBalanceOnLoan: 'Saldo actual del préstamo',
    monthlyPayment: 'Pago mensual',
    name: 'Nombre',
    other: 'Otros pasivos',
    paymentAmount: ' Importe del pago',
    totalOtherLiabilities: 'Total de otros pasivos',
    toWhomPayable: 'A quién debe pagarse',
  },
  paymentMethod: ' Método de pago',
  paymentMethods: ' Métodos de pago',
  relatedPartyCreatedSuccessfully:
    'Información de garante guardado correctamente',
  relatedPartyGeneralSuccessfully:
    'Activos generales de garantes guardado correctamente',
  relatedPartyNotFound: 'Información de activos no encontrada',
  relatedPartyRealStatesSuccessfully:
    'Información de propiedades de garante guardado correctamente',
  Remove: 'Eliminar',
  Required: 'Requerido',
  ResponsibleId: 'Identificación del originador',
  successful: 'Exitoso',
  userNotFound: 'Usuario no encontrado',
  'Write a short note about why the record should be deleted':
    'Escribe una descripción corta de porqué el registro debe ser eliminado',
  detailsOriginator: 'Detalles originador',
  itHasNot: 'No tiene',
  accountNumberLength: 'El número de cuenta debe ser entre 7 a 15 caracteres',
  glBankAccount: 'Cuenta contable del banco',
  AHO: 'Ahorros',
  CTE: 'Corriente',
};
