import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config/';
import routes from 'src/config/api.routes';
import axiosService from './axiosService';

class LoanRequestService {
  GetLoansRequest = (filter) =>
    new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .get(`${API_BASE_URL}/api/loanRequest${filter}`, {
          headers: {
            Authorization: AuthStr,
            appClientId: localStorage.getItem('appClientId')
          }
        })
        .then((response) => {
          if (response.data) {
            const db = {
              result: {
                count: response.data.data.elements,
                data: response.data.data.result
              }
            };
            resolve(db);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  GetLoanRequestById = async (loanRequestId) =>
    axiosService.get(`${routes.loanRequest}/GetInfoRequest/${loanRequestId}`);

  GetLoanRequestAttachmentById = (loanRequestId) =>
    new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .get(`${API_BASE_URL}/api/Annex/${loanRequestId}`, {
          headers: {
            Authorization: AuthStr,
            appClientId: localStorage.getItem('appClientId')
          }
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  DownloadAttachmentByIdAndName = (pathFile, filename) =>
    new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .get(
          `${API_BASE_URL}/api/Files/getFile/${pathFile.replace(
            /[/]/g,
            '%2F'
          )}/${filename}`,
          {
            headers: {
              Authorization: AuthStr,
              appClientId: localStorage.getItem('appClientId')
            }
          }
        )
        .then((response) => {
          if (response?.status === 200) {
            resolve({ response, filename });
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  DownloadAttachmentById = (idfilename) =>
    new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .get(`${API_BASE_URL}/api/Files/GetFileById/${idfilename}`, {
          headers: {
            Authorization: AuthStr,
            appClientId: localStorage.getItem('appClientId')
          }
        })
        .then((response) => {
          if (response?.status === 200) {
            resolve({ response, idfilename });
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  DeleteLoanProductRange = (loanRequestAttachmentId) =>
    new Promise((resolve, reject) => {
      const AuthStr = axios.defaults.headers.common.Authorization;
      axios
        .delete(`${API_BASE_URL}/api/Annex/${loanRequestAttachmentId}`, {
          headers: {
            Authorization: AuthStr,
            'Content-Type': 'application/json',
            appClientId: localStorage.getItem('appClientId')
          }
        })
        .then((response) => {
          if (response.data) {
            resolve(response.data.message);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });

  DeleteLoanRequest = (RequestId, comment) =>
    axiosService.delete(`/api/loanRequest/${RequestId}/${comment}`);

  // Categories and subcategories information
  GetAttachmentsCategories = async (loanRequestId) =>
    axiosService.get(
      `${routes.loanRequest}/GetCategoriesLoanRequest/${loanRequestId}`
    );

  GetAttachmentsSubCategories = async (loanRequestId, categoryName) =>
    axiosService.get(
      `${routes.loanRequest}/GetSubcategoriesLoanRequest/${loanRequestId}/${categoryName}`
    );

  ModifyLoanRequest = async (LoanRequestId, EditRequest) =>
    axiosService.put(`/api/loanRequest/${LoanRequestId}`, EditRequest);

  CreateLoanRequest = async (IdNumber, NewLoanRequestDTO, DoScoring) =>
    axiosService.post(
      `/api/loanRequest/${IdNumber}/${DoScoring}`,
      NewLoanRequestDTO
    );

  GetLoanRequestLogsByIdAndTableName = (id, tableName) =>
    axiosService.get(`/api/EventLog/${id}/${tableName}`);

  UploadAttachmentsLoanRequest = (newAattachments) =>
    axiosService.post(`/api/files/enrollmentFiles/`, newAattachments);

  GetExperianrequest = async (requestId, isDecisionEngine = false) =>
    axiosService.get(
      `${routes.experian}/GetInfoDatacreditoResult/${requestId}/${isDecisionEngine}`
    );

  GetExperianrequestByEntityId = async (entityId, isDecisionEngine = false) =>
    axiosService.get(
      `${routes.experian}/GetInfoDatacreditoResultByEntityId/${entityId}/${isDecisionEngine}`
    );

  CreateExperianRequest = async (datacreditRequest) =>
    axiosService.post(`${routes.experian}/ScoringOnDemand`, datacreditRequest);

  CreateDecisionModelRequest = async (request) =>
    axiosService.post(
      `${routes.experian}/decisionModelRequestWebAmin`,
      request
    );

  CreateTransUnionRequest = async (transUnionRequestDTO) =>
    axiosService.post(
      `${routes.transUnion}/ScoringOnDemandTransUnion`,
      transUnionRequestDTO
    );

  CreateRentingRequest = async (rentingResultDTO) =>
    axiosService.post(
      `${routes.renting}/ServicesOnDemandRenting`,
      rentingResultDTO
    );

  GetTransUnionRequest = async (requestId) =>
    axiosService.get(
      `${routes.transUnion}/GetInfoTransUnionResult/${requestId}`
    );

  GetTransUnionRequestbyEntityId = async (entityId) =>
    axiosService.get(
      `${routes.transUnion}/GetInfoTransUnionResultByEntityId/${entityId}`
    );

  GetRentingServiceRequest = async (requestId) =>
    axiosService.get(`${routes.renting}/GetInfoRentingResult/${requestId}`);

  GetRentingServiceRequestByEntityId = async (entityId) =>
    axiosService.get(
      `${routes.renting}/GetInfoRentingResultByEntityId/${entityId}`
    );

  RejectOrAproveLoan = async (id, editLoanRequestDTO) =>
    axiosService.put(
      `${routes.loanRequest}/${id}`,
      JSON.stringify(editLoanRequestDTO)
    );

  GetPaymentPlan = async (parameters) =>
    axiosService.get(`${routes.loanRequest}/paymentPlan${parameters}`);

  GetPersonByIdentificationNumber = async (
    IdentificationNumber,
    OriginatorId,
    LoanProductId
  ) =>
    axiosService.get(
      `/api/loanRequest/GetPerson/${IdentificationNumber}/${OriginatorId}/${LoanProductId}`
    );

  GetParametersAvailable = async () =>
    axiosService.get(`${routes.renting}/GetParametersAvailable`);

  GetLoanRequestsAssociated = async (loanRequestId) =>
    axiosService.get(
      `${routes.loanRequest}/RequestsAssociated/${loanRequestId}`
    );

  SendNewCodes = async (codes) =>
    axiosService.post('/api/account/newCodes', codes);

  RequestSignatureDocusign = async (loanRequestId) =>
    axiosService.post(`/api/loan-request/RequestSignature/${loanRequestId}`);

  GetPrincipalLoanRequest = async (loanRequestPrincipalId) =>
    axiosService.get(
      `${routes.loanRequest}/RequestsAssociatedPrincipal/${loanRequestPrincipalId}`
    );

  /// Additional Information
  CreateAdditionInfoLoanRequest = async (AdditionalInformationLoanRequest) =>
    axiosService.post(
      `${routes.loanRequest}/CreateAdditionalInfoLoanRequest/`,
      AdditionalInformationLoanRequest
    );

  EditAdditionalInfoLoanRequest = async (
    idAdditionalInfo,
    additionalInfoLoanRequest
  ) =>
    axiosService.put(
      `${routes.loanRequest}/EditAdditionalInfoLoanRequest/${idAdditionalInfo}`,
      additionalInfoLoanRequest
    );

  GetAdditionalInfoLoanRequest = async (loanRequestId) =>
    axiosService.get(
      `${routes.loanRequest}/GetAdditionalInfoLoanRequest/${loanRequestId}`
    );

  DeleteAdditionalInfoLoanRequest = async (idAdditionalInfo) =>
    axiosService.delete(
      `${routes.loanRequest}/DeleteAdditionalInfoLoanRequest/${idAdditionalInfo}`
    );

  CreateCommentLoanRequest = async (commentLogDTO) =>
    axiosService.post(
      `${routes.loanRequest}/CreateCommentLoanRequest`,
      commentLogDTO
    );

  /// Products Information
  CreateProductInfoLoanRequest = async (productInformationLoanRequest) =>
    axiosService.post(
      `${routes.loanRequestProduct}`,
      productInformationLoanRequest
    );

  EditProductInfoLoanRequest = async (idProductInfo, productInfoLoanRequest) =>
    axiosService.put(
      `${routes.loanRequestProduct}/${idProductInfo}`,
      productInfoLoanRequest
    );

  GetProductsInfoLoanRequest = async (loanRequestId) =>
    axiosService.get(`${routes.loanRequestProduct}/${loanRequestId}`);

  DeleteProductInfoLoanRequest = async (idProductInfo) =>
    axiosService.delete(`${routes.loanRequestProduct}/${idProductInfo}`);

  GetLoanRequestValidations = async (loanRequestId) =>
    axiosService.get(`${routes.loanRequest}/validations/${loanRequestId}`);

  UpdateLoanRequestValidation = async (validation) =>
    axiosService.put(`${routes.loanRequest}/validations`, validation);

  DeleteLoanRequestValidationAttachment = async (
    loanValidationId,
    path,
    fileName
  ) =>
    axiosService.delete(
      `${routes.loanRequest}/validations/${loanValidationId}/${path}/${fileName}`
    );
}

const loanRequestService = new LoanRequestService();
export default loanRequestService;
