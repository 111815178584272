import React, { useEffect, useState } from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import SplashScreen from 'src/components/SplashScreen';
import authService from 'src/services/authService';
import { setUserData } from 'src/store/actions/accountActions';
import { useDispatch } from 'react-redux';
// import Error404 from 'src/views/pages/Error404View';

const useStyles = makeStyles(() => createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
      '.MuiBreadcrumbs-root': {
        marginBottom: '12px',
      },
      '.no-records-img': {
        padding: '20px',
        maxWidth: '360px',
      },
    },
  }));

function Auth({ children }) {
  useStyles();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  // const [error, setError] = useState('');

  useEffect(() => {
    const initAuth = async () => {
      authService.handleAuthentication();
      if (authService.isAuthenticated()) {
        // eslint-disable-next-line no-shadow
        const user = await authService
          .loginInWithToken(localStorage.getItem('accessToken'))
          .catch(() => {

            /*
             * console.error("Error response:");
             * setError(error.message);
             */
          });
        await dispatch(setUserData(user));
      }

      setLoading(false);
    };

    initAuth();
  }, [dispatch]);

  if (isLoading) {
    return <SplashScreen />;
  }

  /*
   * if (error === 'Network Error') {
   *   return <Error404 />;
   * }
   */

  return children;
}

Auth.propTypes = {
  children: PropTypes.any,
};

export default Auth;
