export const debtCollectionListEn = {
  customer: 'Borrower',
  debtCollection: 'Debt Collections',
  'Disburment date': 'Disburment Date',
  'Due date': 'Due Date',
  'Factor rule': 'Factor Rule',
  'Factor rules': 'Factor Rules',
  'Loans to manage': 'Loans to Manage',
  loansToFactor: 'Loan Payment',
  'Rules list': 'Rules List',
  thereIsNotDebtCollectionRule: 'There are no debt collection filters',
};

export const debtCollectionListEs = {
  customer: 'Cliente',
  debtCollection: 'Cobranza',
  'Disburment date': 'Fecha de desembolso',
  'Due date': 'Fecha de vencimiento',
  'Factor rule': 'Regla de cobranza',
  'Factor rules': 'Reglas de cobranzas',
  'Loans to manage': 'Préstamos a gestionar',
  loansToFactor: 'Préstamos por cobrar',
  'Rules list': 'Lista de reglas',
  thereIsNotDebtCollectionRule: 'No hay filtros de cobranza',
};
