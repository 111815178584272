import axiosService from './axiosService';
import routes from 'src/config/api.routes';

class TasksService {

  getTasks = async (filter) => await axiosService.get(`${routes.tasks}${filter}`);

  getTask = async (taskId) => await axiosService.get(`${routes.tasks}/${taskId}`);

  updateTask = async (taskDTO,taskId) => await axiosService.put(`${routes.tasks}/${taskId}`,taskDTO);

  getAsignees = async () => await axiosService.get(`${routes.tasks}/Assignees`);

  deleteTask = async (taskId) => await axiosService.delete(`${routes.tasks}/${taskId}`);

  getPendingTasks = async () => await axiosService.get(`${routes.tasks}/pending-tasks`);

  singleTaskAttachments = async (completeTaskDTO) => await axiosService.post(`${routes.tasks}/single-task-attachments`,completeTaskDTO);

  completeTask = async (completeTaskDTO) => await axiosService.post(`${routes.tasks}/complete-task`,completeTaskDTO);

  assignSingleTasks = async (unassignedSingleTaskDTO) => await axiosService.post(`${routes.tasks}/assign-tasks`,unassignedSingleTaskDTO);

}

const tasksService = new TasksService();

export default tasksService;