import { SET_LIST_LOCATIONS } from '../actions/locationsActions';

const initialState = {
  countries: [],
  regions: [],
  cities: []
};

const locationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LIST_LOCATIONS: {
      return {
        ...state,
        [action.index]: [{ id: 0, name: '' }, ...action.locations]
      };
    }
    default: {
      return state;
    }
  }
};

export default locationsReducer;
