/* eslint-disable no-template-curly-in-string */
import { setLocale } from 'yup';
import { useTranslation } from 'react-i18next';

const setYupLocale = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { i18n } = useTranslation();

    if (i18n.language === 'es') {
        setLocale({
            mixed: {
                default: 'El valor no es válido.',
            },
            number: {
                min: 'Debe ser mayor que ${min}',
            },
            date: {
                // ${value}
                max: 'La fecha no puede exceder el valor máximo (${max}).',
            }
        });
    } else {
        setLocale({});
    }
};

export default setYupLocale;
