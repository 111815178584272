import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box, LinearProgress, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Logo from 'src/components/LogoLoading';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '300px',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3),
  },
  logo: {
    justifyContent: 'center',
    width: 200,
    maxWidth: '100%',
  },
  box: {
    justifyContent: 'center',
    textAlign: 'center',
  },
}));

function LoadingScreen() {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div className={classes.root}>
      <Box className={classes.box} width={400}>
        <Logo className={classes.logo} />
        <Typography color="textSecondary" variant="body2">
          {t('waiting')}
        </Typography>
        <LinearProgress />
      </Box>
    </div>
  );
}

export default LoadingScreen;
