export const loanProductsEn = {
  accessibleByCustomer: 'Accessible by Borrower',
  Actions: 'Actions',
  addToLoanAmount: 'Add to Loan Amount',
  Administrative: 'Administrative',
  all: 'All',
  AllInstallments: 'All Installments',
  allowCustomerRequest: 'Allow Borrower Application',
  amount: 'Amount',
  application: 'Application',
  ApplyCityRisk: 'Apply City Risk',
  ApplyPaymentUpToDate: 'Apply Payment Up to Date',
  apToDate: 'Ap to Date',
  Assigned: 'Assigned',
  Attachment: 'Attachment',
  Attachments: 'Attachments',
  BalanceToDate: 'Balance to Date',
  BaseDaysMonth: 'Days in Month',
  BaseDaysYear: 'Days in Year',
  biannual: 'Biannual',
  Business: 'Business',
  CalculatorType: 'Calculator Type',
  capital: 'Principal',
  chargeDetails: 'Charge Details',
  chargeFrequency: 'Charge Frequency',
  chargeIndicator: 'Charge Indicator',
  charges: 'Charges',
  ChargeType: 'Charge Type',
  chargeValue: 'Charge Value',
  collectionManagementDiscount: 'Apply discount in collection management',
  Compound: 'Compound',
  conditions: 'Conditions',
  conditionsApply: 'Conditions Apply',
  Configuration: 'Configuration',
  Configure: 'Configure',
  createDisbursementTasksWithoutUserAssignment:
    'Product tasks were created without assignee',
  createNew: 'Create New',
  CreateNewLoanProducts: 'Create New Loan Products',
  createValidation: 'Create Validation',
  'Credit parameters successfully created':
    'Credit parameters successfully created',
  'Credit parameters successfully updated':
    'Credit parameters successfully updated',
  currentAccounts: 'Current Accounts',
  currentInterest: 'Current Interest',
  currentValue: 'Current Balance',
  customerType: 'Borrower Type',
  daily: 'Daily',
  Daily: 'Daily',
  Dashboard: 'Dashboard',
  'data type': 'Data Type',
  dateInstallment: 'Date of Installment',
  DaysForClosure: 'Days for Closure',
  daysOverdue: 'Days Overdue',
  debtCollectionCharge: 'Collect Payment Charge',
  deductFromDisbursement: 'Deduct from Disbursement',
  DeductFromDisbursement: 'Deduct from Disbursement',
  DefaultGraceDays: 'Grace Period',
  DefaultPaymentType: 'Default Payment Type',
  defaultPenalGraceDays: 'Late Payment Grace Period',
  defaultValue: 'Default Value',
  delete: 'Delete',
  deleteLoanProductValidation:
    'Do you want to eliminate loan product validation?',
  Description: 'Description',
  details: 'Details',
  DisbursedAmount: 'Disbursed Amount',
  InstallmentAmount: 'Installment Amount',
  disbursement: 'Disbursement',
  disbursementCharge: 'Disbursement Charge',
  disbursementTarget: 'Disrbusement Target',
  disbursementTypeCharge: 'Disbursement Type Charge',
  docusignIntegration: 'Docusign Integration',
  doubtfulRecovery: 'Doubtful Recovery',
  EditLoanProduct: 'Edit loan Product',
  everyInstallment: 'Every Installment',
  evidenceRequired: 'Evidence Required',
  expiresAfterDays: 'Maximum Charge Duration Period (In days)',
  expiresAfterLoanOverdueDays: 'Expires After Loan Overdue Days',
  Export: 'Export',
  ExternalBehavior: 'External Behavior',
  Factor: 'Factor',
  factorDiscount: 'Factor Discount',
  false: 'No',
  fastPaymentDiscount: 'Fast Payment Discount',
  fgaIntegration: 'FGA Integration',
  fieldMustBeEqualOrGratherThanZero: 'Field must be equal or grather than zero',
  fieldMustBeEqualOrGratherThanOne: 'Field must be equal or grather than one',
  FirstInstallment: 'First Installment',
  FIX: 'Fixed',
  frequency: 'Frequency',
  frequencyBased: 'Frequency Based',
  fromAmount: 'From Amount',
  fromTerm: 'From Term',
  'General loan product details': 'General Loan Product Details',
  GeneralDetails: 'General Details',
  generalParameters: 'General Parameters',
  gL_CurrentInterestIncomeAccount: 'GL Current Interest Income Account',
  gL_IncomeAccount: 'GL Income Account',
  gL_InstallmentAccount: 'GL Installment Account',
  gL_PenalInterestIncomeAccount: 'GL Penal Interest Income Account',
  gL_PrincipalReceivableAccount: 'GL Principal Receivable Account',
  gL_ReceivableAccountCurrentInterest:
    'Receivable Account for Current Interest',
  gL_ReceivableAccountForCharge: 'Receivable Account for Charge',
  gL_ReceivableAccountForTax: 'Receivable Account for Tax',
  gL_ReceivableAccountPenalInterest: 'Receivable Account for Penal Interest',
  gL_TaxAccount: 'Tax Account',
  glAccounts: 'GL Accounts',
  HasGracePeriod: 'Initial Grace Period',
  HasPenalGraceDays: 'Grace Days',
  headerAuthKey: 'Header Authorization Key',
  Identification: 'Identification',
  IncreasingLimitAmount: 'Increasing Credit Limit Amount',
  InDisbursement: 'In Disbursement',
  InitialMaximumAmount: 'Initial Maximum Amount',
  installment: 'Installment',
  installmentsNumber: 'Installments Number',
  Insurance: 'Insurance',
  integrationKey: 'Integration Key',
  InterestMethod: 'Interest Method',
  InterestRate: 'Interest Rate',
  InternalBehavior: 'Internal Behavior',
  // Fin Loan Product Parameters
  Linear: 'Linear',
  'Loan product saved': 'Loan product saved',
  'Loan Products': 'Loan Products',
  LoanAmount: 'Loan Amount',
  loanProduct: 'loan Products',
  LoanProduct: 'Loan Product',
  loanProductChargeTypeDetails: 'Loan Product Charge Type Details',
  loanProductChargeTypeRange: 'Loan Product Charge Type Range',
  loanProductDetails: 'Details Loan Products',
  LoanProductList: 'Loan Product List',
  loanProductRestructuringParameterInformationUploadedSuccessfully:
    'Loan product restructuring parameter information uploaded successfully.',
  loans: 'Loans',
  mandatory: 'Mandatory',
  MaxAmount: 'Max Amount',
  MaxDefermentsPerInstallment: 'Max Deferments per Installment',
  MaxDefermentsPerLoan: 'Max Deferments per Loan',
  maximumDaysPastDue:
    'Maximum days past due on previous credits for a new application',
  MaximunAge: 'Maximun Age',
  MaxInitialTenor: 'Max Initial Tenor',
  MaxNumberRescheduling: 'Max Number of Reschedulings',
  maxPaymentDate: 'Max Payment Date',
  MaxTenor: 'Max Tenor (In days)',
  MinAmount: 'Min Amount',
  MinimumAge: 'Minimum Age',
  MinimunExperianScore: 'Minimum Experian Score',
  MinimunFICOScore: 'Minimum FICO Score',
  MinimunTransUnionScore: 'Minimum TransUnion score',
  MinScoreDatacreditoApprove: 'Min Experian Score for Pre-approval',
  MinScoreDatacreditoGrayZone: 'Min Experian Score for Gray zone',
  MinScoreLisimApprove: 'Min Lisim Score for Pre-approval',
  minTenor: 'Min Tenor (In days)',
  monthly: 'Monthly',
  multipleDisbursements: 'Multiple Disbursements',
  multipleLoans: 'Multiple Loans',
  multipleRequests: 'Multiple Requests',
  'Must be greater than zero': 'Must be greater than zero',
  'Must be positive': 'Must be positive',
  Name: 'Name',
  NewLoanProductChargeType: 'New Charge Type',
  noMultipleDisbAllowed: 'No multiple disbursements allowed',
  None: 'None',
  numberDaysAfterLastRejection:
    'Number of days after last rejection for new application',
  oauthPath: 'Base address oauth (endpoint)',
  once: 'Once',
  OneInstallment: 'One Installment',
  openDate: 'Open Date',
  Originator: 'Originator',
  OriginatorId: 'Originator',
  others: 'Other',
  overDue: 'Overdue',
  overdueInstallments: 'Overdue Installments',
  overdueValue: 'Overdue Balance',
  ParameterName: 'Name',
  parameters: 'Parameters',
  ParameterValue: 'Value',
  parametrizationDocusign: 'Docusign Parameters',
  agreementPartner: 'Agreement Partner',
  paidInstallment: 'Paid Installments',
  paymentSequence: 'Payment Sequence',
  PaymentSequence: 'Payment Sequence',
  paymentType: 'Payment Type',
  pctCalcBase: 'Percentage Calculation Base',
  penalGraceDays: 'Late Payment Grace Period',
  penalInterest: 'Penal Interest',
  PER: 'Percentage',
  Person: 'Person',
  Phone: 'Phone',
  PreApproveCheckingMonths: 'Number of Months for Pre-qualification Check',
  PreApproveDaysAfterLastNegatedRequest:
    'Days of Blocking After Last Rejected Application',
  PreApproveGraceOverdueDays: 'Grace Overdue Days for Pre-qualification Check',
  PreApproveMaxOverdueDays: 'Pre-appprove Max Overdue Days',
  PreAprovedCreditLimit: 'Pre-approved Credit Limit',
  priority: 'Priority',
  // Tabla Loan Product Parameters
  ProductPolicies: 'Product Policies',
  Products: 'Products',
  quantityLoans: 'Quantity of Loans',
  quarterly: 'Quarterly',
  range: 'Range',
  recurrenceParameters: 'Recurrence Parameters',
  recurrentRequest: 'Parameters apply for recurring requests',
  Remove: 'Remove',
  Renew: 'Renew',
  repeats: 'Repeats',
  requests: 'Applications',
  Required: 'Required',
  ReschechulingParameters: 'Reschechuling',
  ReschedulingParameters: 'Parameter Restructuring',
  RestructuringValue: 'Restructuring Value',
  risks: 'Risks',
  rsaKey: 'RSA Key',
  save: 'Save',
  scopes: 'Scopes',
  Search: 'Search',
  'Search customers': 'Search Leads',
  SeveralInstallments: 'Several Installments',
  status: 'Status',
  successfullyCreatedLoanProductRestructuringParameters:
    'Successfully created loan product restructuring parameters.',
  successfullyUpdatedLoanProductRestructuringParameters:
    'Successfully updated loan product restructuring parameters.',
  taxRate: 'Tax Rate',
  TechPlatform: 'TechPlatform',
  'Tipo de cargo para producto de préstamo creado exitosamente':
    'Charge type for a loan product updated succesfully',
  'Tipos de cargo para un producto de préstamo actualizado exitosamente':
    'Charge type for a loan product updated succesfully',
  toAmount: 'To amount',
  tokenLifetime: 'Token Lifetime',
  totalInstallment: 'Total Installments',
  toTerm: 'To Term',
  true: 'Yes',
  typeOfChargeSuccessfullyCreated:
    'Type of charge for a loan product successfully created',
  typeOfChargeSuccessfullyUpdated:
    'Type of Charge for a        loan product successfully updated',
  'UNREGISTERED IDENTIFICATION': 'UNREGISTERED IDENTIFICATION',
  updateLoanProduct: 'Update Loan Product',
  updateValidation: 'Update Validation',
  userId: 'User ID',
  weekly: 'Weekly',
  PERRANGE: 'Percentage Range',
  fromTermInDays: 'From Term (In days)',
  toTermInDays: 'To Term (In days)',
  maxTerm: 'Maximum Term',
  minimumTerm: 'Minimum Term',
  FIXRANGE: 'Fix Range',
  loadInstallmentsNotFound: 'Loan Installment not found',
  loanIsClosed: 'The loan is closed',
  productCode: 'Product Code',
  productDoesNotHavePaymentSequence:
    'The product does not have a payment sequence, you must parameterize it.',
  prepaymentFee: 'Prepayment Fee',
  automaticallyGenerate: 'Automatically Generate',
  notification: 'Notification',
  notificationDeleted: 'Notification deleted.',
  attachments: 'Attachments',
  notificationCreated: 'Notification created',
  notificationUpdated: 'Notification updated',
  youMustBeSelectAnOption: 'You must be select an option',
};

export const loanProductsEs = {
  accessibleByCustomer: 'Disponible para cliente',
  Actions: 'Acciones',
  addToLoanAmount: 'Agregar al monto del préstamo',
  AddToLoanAmount: 'Agregar al monto del préstamo',
  Administrative: 'Administrativo',
  all: 'Todos',
  AllInstallments: 'Todas las cuotas',
  allowCustomerRequest: 'Recibe solicitudes desde módulo de enrolamiento',
  amount: 'Monto',
  application: 'Solicitudes',
  ApplyCityRisk: 'Aplica riesgo ciudad',
  ApplyPaymentUpToDate: 'Aplicar pago de intereses hasta la fecha',
  apToDate: 'Al dia',
  Assigned: 'Asignado',
  Attachments: 'Adjuntos',
  BalanceToDate: 'Saldo a la fecha',
  BaseDaysMonth: 'Días por mes',
  BaseDaysYear: 'Días por año',
  biannual: 'Semestral',
  Business: 'Empresa',
  CalculatorType: 'Tipo de calculadora',
  capital: 'Capital',
  'Charge type and range': 'Tipo de cargo y rango',
  chargeDetails: 'Detalles de Cargo',
  chargeFrequency: 'Frecuencia de cobro',
  chargeIndicator: 'Indicador de valor de cargo',
  charges: 'Cargos',
  ChargeType: 'Tipos de cargo',
  chargeValue: 'Valor de cargo',
  collectionManagementDiscount: 'Aplica descuento en gestión de cobranza',
  Compound: 'Compuesto',
  conditions: 'Condiciones',
  conditionsApply: 'Aplican condiciones',
  Configuration: 'Configuración',
  Configure: 'Configurar',
  createDisbursementTasksWithoutUserAssignment:
    'Las tareas del producto se crearon sin asignatario',
  createNew: 'Crear nuevo',
  CreateNewLoanProducts: 'Crear nuevo producto de préstamo',
  createValidation: 'Crear validación',
  'Credit parameters successfully created': 'Parámetros de crédito creados',
  'Credit parameters successfully updated':
    'Parámetros de crédito actualizados',
  currentAccounts: 'Cuentas Vigentes',
  currentInterest: 'Interés Corriente',
  currentValue: 'Saldo actual',
  customerType: 'Tipo de cliente',
  daily: 'Diario',
  Daily: 'Diario',
  'data type': 'Tipo de Dato',
  dateInstallment: 'Fecha de pago cuota',
  DaysForClosure: 'Días para el cierre',
  daysOverdue: 'Días de mora',
  debtCollectionCharge: 'Cargo por cobranza',
  deductFromDisbursement: 'Deducir del desembolso',
  DeductFromDisbursement: 'Deducir del desembolso',
  DefaultGraceDays: 'Periodo de gracia',
  DefaultPaymentType: 'Tipo de pago por defecto',
  defaultPenalGraceDays: 'Días de gracia para mora',
  defaultValue: 'Valor por defecto',
  delete: 'Eliminar',
  deleteLoanProductValidation:
    '¿Quiere eliminar la validación del producto de préstamo?',
  Description: 'Descripción ',
  details: 'Detalles',
  DisbursedAmount: 'Valor desembolsado',
  InstallmentAmount: 'Valor de cuota',
  disbursement: 'Desembolso',
  disbursementCharge: 'Cargo de desembolso',
  disbursementTarget: 'Destino de desembolso',
  disbursementTypeCharge: 'Tipo de cobro del desembolso',
  docusignIntegration: 'Integración con Docusign',
  doubtfulRecovery: 'Dudoso recaudo',
  EditLoanProduct: 'Editar producto de préstamo',
  everyInstallment: 'Cada Cuota',
  evidenceRequired: 'Evidencia requerida',
  expiresAfterDays: 'Duración máxima de cobro del cargo (en días)',
  expiresAfterLoanOverdueDays:
    'Expira después de los días de vencimiento del préstamo',
  Export: 'Exportar',
  ExternalBehavior: 'Comportamiento externo',
  Factor: 'Factor',
  factorDiscount: 'Descuento por cobranza',
  false: 'No',
  fastPaymentDiscount: 'Descuento por pago rápido',
  fgaIntegration: 'Integración fga',
  fieldMustBeEqualOrGratherThanZero: 'El valor debe ser mayor o igual a cero',
  fieldMustBeEqualOrGratherThanOne: 'El valor debe ser mayor o igual a uno',
  FirstInstallment: 'Primera cuota',
  FIX: 'Fijo',
  frequency: 'Frecuencia',
  frequencyBased: 'Basado en frecuencia',
  fromAmount: 'Del monto',
  fromTerm: 'Del plazo',
  'General details and rescheduling parameters':
    'Detalles generales y parámetros de reprogramación',
  'General loan product details': 'Detalles generales de productos de préstamo',
  GeneralDetails: 'Detalles generales',
  generalParameters: 'Análisis de riesgo',
  gL_CurrentInterestIncomeAccount: 'Cuenta ingresos intereses corrientes',
  gL_IncomeAccount: 'Cuenta contable de ingreso',
  gL_InstallmentAccount: 'Cuenta cuota crédito',
  gL_PenalInterestIncomeAccount: 'Cuenta ingresos intereses de mora',
  gL_PrincipalReceivableAccount: 'Cuenta por cobrar capital',
  gL_ReceivableAccountCurrentInterest:
    'Cuenta a cobrar por intereses corrientes',
  gL_ReceivableAccountForCharge: 'Cuenta a cobrar por cargos',
  gL_ReceivableAccountForTax: 'Cuenta a cobrar por impuestos',
  gL_ReceivableAccountPenalInterest: 'Cuenta a cobrar por intereses penales',
  gL_TaxAccount: 'Cuenta de impuestos',
  glAccounts: 'Cuentas contables',
  HasGracePeriod: 'Tiene periodo de gracia',
  HasPenalGraceDays: 'Tiene días de gracia para mora',
  headerAuthKey: 'Clave de autorización del encabezado',
  Identification: 'Identificación',
  Import: 'Importar',
  IncreasingLimitAmount: 'Valor de incremento de cupo',
  InDisbursement: 'En desembolso',
  InitialMaximumAmount: 'Monto máximo inicial',
  installment: 'Cuota',
  installmentsNumber: 'Número de cuotas',
  Insurance: 'Seguro',
  integrationKey: 'Clave de integración',
  InterestMethod: 'Método de interés',
  InterestRate: 'Tasa de interés',
  InternalBehavior: 'Comportamiento interno',
  // Fin Loan Product Parameters
  Linear: 'Lineal',
  'Loan product saved': 'Producto de préstamo guardado',
  'Loan Products': 'Productos de préstamo',
  LoanAmount: 'Valor de préstamo',
  loanProduct: 'productos de préstamo',
  loanProductChargeTypeDetails: 'Detalles de Tipo de Cargo de Producto',
  loanProductChargeTypeRange: 'Rango de tipo de cargo del producto de préstamo',
  loanProductDetails: 'Detalles productos de préstamo',
  LoanProductList: 'Lista de productos de préstamo',
  loanProductRestructuringParameterInformationUploadedSuccessfully:
    'Información de parámetros de reestructuración de productos de préstamo cargada exitosamente.',
  loans: 'Préstamos',
  mandatory: 'Obligatorio',
  MaxAmount: 'Monto máximo',
  MaxDefermentsPerInstallment: 'Extensiones máximas por cuota',
  MaxDefermentsPerLoan: 'Extensiones máximas por préstamo',
  maximumDaysPastDue:
    'Días máximos de mora en créditos anteriores para nueva solicitud',
  MaximunAge: 'Edad máxima',
  MaxInitialTenor: 'Plazo máximo primera solicitud',
  MaxNumberRescheduling: 'Número máximo de re-estructuraciones',
  maxPaymentDate: 'Fecha máxima de pago',
  MaxTenor: 'Plazo máximo(En dias)',
  MinAmount: 'Monto mínimo',
  MinimumAge: 'Edad mínima',
  MinimunExperianScore: 'Puntaje mínimo Experian',
  MinimunFICOScore: 'Puntaje mínimo FICO',
  MinimunTransUnionScore: 'Puntaje mínimo TransUnion',
  MinScoreDatacreditoApprove: 'Calificación mínima aprobación Datacrédito',
  MinScoreDatacreditoGrayZone: 'Calificación mínima zona gris Datacrédito',
  MinScoreLisimApprove: 'Calificación mínima aprobación Lisim',
  minTenor: 'Plazo mínimo(En dias)',
  monthly: 'Mensual',
  multipleDisbursements: 'Desembolsos múltiples',
  multipleLoans: 'Múltiples Préstamos',
  multipleRequests: 'Múltiples solicitudes',
  'Must be greater than zero': 'El número debe ser mayor que cero',
  'Must be positive': 'El número debe ser positivo',
  Name: 'Nombre',
  NewLoanProductChargeType: 'Nuevo tipo de cargo',
  noMultipleDisbAllowed: 'No se permiten múltiples desembolsos',
  None: 'Ninguno',
  numberDaysAfterLastRejection:
    'Número de días después de último rechazo para nueva solicitud',
  oauthPath: 'Dirección oauth (URL)',
  once: 'Una vez',
  OneInstallment: 'Una cuota',
  openDate: 'Fecha de apertura',
  Originator: 'Originador',
  OriginatorId: 'Creador',
  others: 'otros',
  overDue: 'Mora',
  overdueInstallments: 'Cuotas atrasadas',
  overdueValue: 'Saldo atrasado',
  ParameterName: 'Nombre',
  parameters: 'Parámetros',
  ParameterValue: 'Valor',
  parametrizationDocusign: 'Parametrización docusign',
  agreementPartner: 'Aliado del convenio',
  paidInstallment: 'Cuotas canceladas',
  paymentSequence: 'Secuencia de pago',
  PaymentSequence: 'Secuencia de pago',
  paymentType: 'Tipo pago',
  pctCalcBase: 'Base de cálculo del porcentaje',
  penalGraceDays: 'Días de gracia para mora',
  penalInterest: 'Interés Mora',
  PER: 'Porcentaje',
  Person: 'Persona',
  Phone: 'Teléfono',
  PreApproveCheckingMonths:
    'Número de meses para validación de pre calificación',
  PreApproveDaysAfterLastNegatedRequest:
    'Días de bloqueo después de última solicitud rechazada',
  PreApproveGraceOverdueDays:
    'Días de gracia de mora para validación de pre calificación',
  PreApproveMaxOverdueDays: 'Máximos días de mora para pre - aprobación',
  PreAprovedCreditLimit: 'Monto máximo primera solicitud',
  priority: 'Prioridad',
  // Tabla Loan Product Parameters
  ProductPolicies: 'Políticas del producto',
  Products: 'Productos',
  quantityLoans: 'Cantidad de préstamos',
  quarterly: 'Trimestral',
  range: 'Rango',
  recurrenceParameters: 'Parámetros de Recurrencia',
  recurrentRequest: 'Aplican parámetros para solicitudes recurrentes',
  Remove: 'Eliminar',
  Renew: 'Renovar',
  repeats: 'Repeticiones',
  requests: 'Solicitudes',
  Required: 'Obligatorio',
  ReschechulingParameters: 'Reestructuración',
  ReschedulingParameters: 'Parámetros de reestructuración',
  RestructuringValue: 'Valor de reestructuración',
  risks: 'Riesgos',
  rsaKey: 'Clave RSA',
  save: 'Guardar',
  scopes: 'Alcances',
  Search: 'Buscar',
  'Search customers': 'Buscar Cliente',
  SeveralInstallments: 'Varias cuotas',
  'Sort By': 'Ordenar por',
  status: 'Estado',
  successfullyCreatedLoanProductRestructuringParameters:
    'Parámetros de reestructuración de productos de préstamo creado exitosamente.',
  successfullyUpdatedLoanProductRestructuringParameters:
    'Parámetros de reestructuración de productos de préstamo actualizado exitosamente.',
  taxRate: 'Tasa de impuestos',
  TechPlatform: 'Plataforma tecnológica',
  'Tipo de cargo para producto de préstamo creado exitosamente':
    'Tipo de cargo para producto de préstamo creado exitosamente',
  'Tipos de cargo para un producto de préstamo actualizado exitosamente':
    'Tipos de cargo para un producto de préstamo actualizado exitosamente',
  toAmount: 'Al monto',
  tokenLifetime: 'Tiempo de vida del token',
  totalInstallment: 'Total de cuotas',
  toTerm: 'Al plazo',
  true: 'Si',
  typeOfChargeSuccessfullyCreated:
    'Tipo de cargo para un producto de préstamo creado exitosamente',
  typeOfChargeSuccessfullyUpdated:
    'Tipos de cargo para un producto de préstamo actualizado exitosamente',
  'UNREGISTERED IDENTIFICATION': 'Identificación no registrada',
  updateLoanProduct: 'Editar producto de préstamo',
  updateValidation: 'Editar validación',
  userId: 'ID de usuario',
  weekly: 'Semanal',
  PERRANGE: 'Rango de porcentaje',
  fromTermInDays: 'Del plazo (En días)',
  toTermInDays: 'Al plazo (En días)',
  maxTerm: 'Plazo máximo',
  minimumTerm: 'Plazo mínimo',
  FIXRANGE: 'Rango fijo',
  loadInstallmentsNotFound: 'La cuota del préstamo no fue encontrada',
  loanIsClosed: 'El préstamo se encuentra cerrado',
  productCode: 'Código de producto',
  productDoesNotHavePaymentSequence:
    'El producto no tiene secuencia de pagos, debe parametrizarlo.',
  prepaymentFee: 'Cargo por pago anticipado',
  automaticallyGenerate: 'Generar automáticamente',
  notification: 'Notificación',
  notificationDeleted: 'Notificación eliminada.',
  attachments: 'Adjuntos',
  notificationCreated: 'Notificación creada',
  notificationUpdated: 'Notificación actualizada',
  youMustBeSelectAnOption: 'Debe seleccionar una opción',
};
