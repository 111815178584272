import jwtDecode from 'jwt-decode';
import axios from 'src/utils/axios'; // newaxios
import { API_BASE_URL, TENANT_URL } from 'src/config/';

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);
          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      },
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  loginWithEmailAndPassword = (email, password, OriginatorId) => new Promise((resolve, reject) => {
      const url = `${API_BASE_URL}/api/account/token`;
      const tenant = TENANT_URL || window.location.hostname;
      axios
        .post(
          url,
          { username: email, password, OriginatorId },
          { headers: { urlTenant: tenant } },
        )
        // axios.post('/api/account/login', { email, password })
        .then((response) => {
          const appClientId = response.data.data.originKey;
          localStorage.setItem('appClientId', appClientId);
          const date = new Date();
          if (response.data) {
            // eslint-disable-next-line eqeqeq
            if (response.data.data.role != undefined) {
              const db = {
                user: {
                  id: response.data.data.id,
                  avatar: '/static/images/avatars/fotoprofile.png',
                  bio: response.data.data.role,
                  canHire: false,
                  country: 'CO',
                  email: response.data.data.email,
                  entityId: response.data.data.entityId,
                  username: response.data.data.email,
                  password: '',
                  firstName: response.data.data.firstName,
                  isPublic: true,
                  lastName: response.data.data.lastName,
                  phone: '+57',
                  role: response.data.data.role,
                  roles: response.data.data.roles,
                  state: 'Medellin',
                  timezone: date,
                },
              };
              if (response.data.data.role === 'Member') {
                throw new Error('Forbidden');
              }
              this.setSession(response.data.data.id);
              resolve(db.user);
              // eslint-disable-next-line no-empty
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              const mensaje = `Rol ${response.data.data.role} no autorizado`;
              reject(new Error(mensaje));
            }
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  loginInWithToken = (datos) => new Promise((resolve, reject) => {
      const accessToken = datos;
      axios
        .post(
          `${API_BASE_URL}/api/account/refreshToken`,
          { token: accessToken },
          { headers: { appClientId: localStorage.getItem('appClientId') } },
        )
        .then((response) => {
          const date = new Date();
          const db = {
            user: {
              id: '5e86809283e28b96d2d38537',
              avatar: '/static/images/avatars/fotoprofile.png',
              bio: response.data.data.role,
              canHire: false,
              country: '',
              email: response.data.data.email,
              entityId: response.data.data.entityId,
              firstName: response.data.data.firstName,
              isPublic: true,
              lastName: response.data.data.lastName,
              phone: '',
              role: response.data.data.role,
              roles: response.data.data.roles,
              state: '',
              timezone: date,
            },
          };

          if (response.data) {
            this.setSession(response.data.data.tokenrefresh);
            resolve(db.user);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(`Error response:${error}`);
          // this.setSession(null);
          reject(error);
        });
    });

  logout = () => {
    this.setSession(null);
  };

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  getAccessToken = () => localStorage.getItem('accessToken');

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  };

  isAuthenticated = () => !!this.getAccessToken();
}

const authService = new AuthService();

export default authService;
