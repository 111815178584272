import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import customerReducer from './customerReducer';
import originatorReducer from './originatorReducer';
import loanProductReducer from './loanProductReducer';
import founderReducer from './funderReducer';
import usersReducer from './usersReducer';
import rolesReducer from './rolesReducer';
import locationsReducer from './locationsReducer';
import loanRequestReducer from './loanRequestReducer';
import companyReducer from './companyReducer';
import guarantorReducer from './guarantorReducer';
import { uiReducer } from './uiReducer';
import { masterReducer } from './masterReducer';
import generalReducer from './generalReducer';
import tasks from './taskReducer';
import loanReducer from './loanReducer';
import debtCollection from './debtCollectionReducer';
import organizationReducer from './organizationReducer';
import campaignReducer from './campaignReducer';
import loanFilterReducer from './loanFilterReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  customer: customerReducer,
  originator: originatorReducer,
  form: formReducer,
  loanProductId: loanProductReducer,
  founder: founderReducer,
  users: usersReducer,
  roles: rolesReducer,
  locations: locationsReducer,
  loanRequest: loanRequestReducer,
  company: companyReducer,
  guarantor: guarantorReducer,
  ui: uiReducer,
  master: masterReducer,
  general: generalReducer,
  tasks,
  loan: loanReducer,
  debtCollection,
  organization: organizationReducer,
  campaign: campaignReducer,
  loanFilter: loanFilterReducer
});

export default rootReducer;
