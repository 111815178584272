import {
  CLEAN_GUARANTOR_REDUCER,
  SET_FINANCIAL_INFO_ASSETS,
  SET_FINANCIAL_INFO_LIABILITIES,
  SET_GUARANTOR_PERSONAL_INFORMATION,
  SET_LIABILITIES_CREDIT_CARDS,
  SET_LIABILITIES_LOANS,
  SET_LIABILITIES_OTHERS,
  SET_LIABILITIES_UNPAID_TAXES,
  SET_TOTAL_LIABILITIES
} from "../actions/guarantorActions";

const initialState = {
  personalInformation: null,
  financialInformation: {
    assets: {
      loaded: false
    },
    liabilities: {
      loaded: false
    }
  },
}

const guarantorReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAN_GUARANTOR_REDUCER: {
      return initialState;
    }
    case SET_GUARANTOR_PERSONAL_INFORMATION: {
      return {
        ...state,
        personalInformation: action.personalInformation
      }
    }
    case SET_FINANCIAL_INFO_ASSETS: {
      return {
        ...state,
        financialInformation: {
          ...state.financialInformation,
          assets: action.assets
        }
      }
    }
    case SET_FINANCIAL_INFO_LIABILITIES: {
      return {
        ...state,
        financialInformation: {
          ...state.financialInformation,
          liabilities: action.liabilities
        }
      }
    }
    case SET_TOTAL_LIABILITIES: {
      return {
        ...state,
        financialInformation: {
          ...state.financialInformation,
          liabilities: {
            ...state.financialInformation.liabilities,
            generalLiabilities: {
              ...state.financialInformation.liabilities.generalLiabilities,
              ...action.generalLiabilities
            }
          }
        }
      }
    }
    case SET_LIABILITIES_CREDIT_CARDS: {
      return {
        ...state,
        financialInformation: {
          ...state.financialInformation,
          liabilities: {
            ...state.financialInformation.liabilities,
            creditCards: action.creditCards
          }
        }
      }
    }
    case SET_LIABILITIES_LOANS: {
      return {
        ...state,
        financialInformation: {
          ...state.financialInformation,
          liabilities: {
            ...state.financialInformation.liabilities,
            outstandingLoans: action.outstandingLoans
          }
        }
      }
    }
    case SET_LIABILITIES_OTHERS: {
      return {
        ...state,
        financialInformation: {
          ...state.financialInformation,
          liabilities: {
            ...state.financialInformation.liabilities,
            otherLiabilities: action.otherLiabilities
          }
        }
      }
    }
    case SET_LIABILITIES_UNPAID_TAXES: {
      return {
        ...state,
        financialInformation: {
          ...state.financialInformation,
          liabilities: {
            ...state.financialInformation.liabilities,
            unpaidTaxes: action.unpaidTaxes
          }
        }
      }
    }
    default:
      return state;
  }
}

export default guarantorReducer;