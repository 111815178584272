import rolesService from 'src/services/rolesService';

export const SET_LIST_ROLES = '@roles/set-roles';

export function getAllRoles() {
  return async (dispatch) => {
    const response = await rolesService.GetRoles();
    dispatch({
      type: SET_LIST_ROLES,
      roles: response.data?.data?.roles ?? [],
      count: response?.data?.data?.elements ?? 0
    });
  };
}
