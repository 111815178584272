/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  CREATE_CUSTOMER,
  CREATE_PROFESSION,
  CREATE_PERSON,
  CREATE_CAPTURES1,
  CREATE_CAPTURES2,
  CREATE_CAPTURES3,
  CREATE_IDENTIFICATION,
  CREATE_CONTACT,
  CREATE_BANK,
  EMAIL,
  PHONE,
  CREATE_PERSONID,
  CREATE_ENTITYID,
  IDENTIFICATION_NUMBER,
  POST_CAPTURES_INFO,
  SET_FORM_CUSTOMER_INFO,
  SET_FINANCIAL_INFO,
  SET_PERSON_FAMILY_INFO,
  CLEAN_CUSTOMER
} from 'src/store/actions/customerActions';

const initialState = {
  user: {
    email: '',
    phone: ''
  },
  captura1: '',
  captura2: '',
  captura3: '',
  personalDto: {
    id: 0,
    name: '',
    name2: '',
    lastName: '',
    lastName2: '',
    gender: '',
    dob: new Date(),
    placeOfBirth: '',
    region: '',
    city: '',
    departament: '',
    regionCode: '',
    cities: '',
    originatorId: '',
    cityCode: '',
    regionCode: ''
  },
  customerDTO: {
    channel: 'WebAdmin',
    dataPolicy: false,
    additionalObservation: '',
    nameOfReferral: '',
    emailOfReferral: '',
    phoneOfReferral: '',
  },
  identificationDTO: {
    identificationType: '',
    identificationNumber: '',
    issueDate: new Date(),
    issueCountry: '',
    issueCity: '',
    region: '',
    issueCityCode: '',
    regionCode: '',
    expirationDate: ''
  },
  professionInfoDto: {
    profession: '',
    occupation: '',
    income: '',
    academicLevel: '',
    academicStatus: '',
    mainEconomicActivity: '',
    ciiuCode: '',
  },
  contactDTO: {
    address: '',
    departament: '',
    cities: '',
    fullnamerp: '',
    phonerp: '',
    fullnamerf: '',
    phonerf: '',
    relationshipf: '',
    fullnamerl: '',
    phonerl: '',
    relationshipl: '',
    company: ''
  },
  bankAccount: {
    id: 0,
    type: '',
    bankCode: '',
    bankName: '',
    accountNumber: '',
    personId: 0,
    regionCode: '',
    cityCode: ''
  },
  entityId: 0,
  lastRequestDataUpdate: '',
  viewEmploymentInfoDTO: {
    companyAddress: '',
    companyName: '',
    companyPhone: '',
    contractType: '',
    employmentSituation: '',
    monthsInJob: 0
  },
  personFamilyInfoDto: {
    permanentMembers: '',
    spouseFullName: '',
    spousePhoneNumber: '',
    housingDeeds: '',
    compensationFund: '',
    maritalStatus: '',
    numberOfDependents: '',
    typeOfHousing: '',
  },
  personFinancialInfoDto: {
    rentAdministration: '',
    foodExpenses: '',
    installmentsTotalCredits: '',
    transportation: '',
    contributions: '',
    otherFrequent: '',
    contributingMembers: '',
    otherIncome: '',
    liabilities: '',
    monthlyPaymentDebts: ''
  }
};

const customerReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case CREATE_CUSTOMER: {
      return produce(state, draft => {
        draft.user = action.payload.values;
      });
    }
    case CLEAN_CUSTOMER: {
      return { ...initialState };
    }
    case EMAIL: {
      return produce(state, draft => {
        draft.user.email = action.payload.values;
      });
    }
    case PHONE: {
      return produce(state, draft => {
        draft.user.phone = action.payload.values;
      });
    }
    case CREATE_CAPTURES1: {
      return produce(state, draft => {
        draft.captura1 = action.payload.values;
      });
    }
    case CREATE_CAPTURES2: {
      return produce(state, draft => {
        draft.captura2 = action.payload.values;
      });
    }
    case CREATE_CAPTURES3: {
      return produce(state, draft => {
        draft.captura3 = action.payload.values;
      });
    }
    case CREATE_PERSON: {
      return produce(state, draft => {
        draft.personalDto = {
          ...draft.personalDto,
          id: action.payload.values.id,
          originatorId: action.payload.values.originatorId
        };
      });
    }
    case CREATE_IDENTIFICATION: {
      return produce(state, draft => {
        draft.identificationDTO = {
          ...draft.identificationDTO,
          ...action.payload.values
        };
      });
    }
    case CREATE_PROFESSION: {
      return produce(state, draft => {
        draft.professionDTO = {
          ...draft.professionDTO,
          ...action.payload.values
        };
      });
    }
    case CREATE_CONTACT: {
      return produce(state, draft => {
        draft.contactDTO = {
          ...draft.contactDTO,
          ...action.payload.values
        };
      });
    }
    case CREATE_BANK: {
      return produce(state, draft => {
        draft.contactDTO = {
          ...draft.contactDTO,
          ...action.payload.values
        };
      });
    }
    case CREATE_PERSONID: {
      return produce(state, draft => {
        draft.personalDto.id = action.payload.values;
      });
    }
    case CREATE_ENTITYID: {
      return produce(state, draft => {
        draft.entityId = action.payload.values;
      });
    }
    case IDENTIFICATION_NUMBER: {
      return produce(state, draft => {
        draft.identificationDTO.identificationNumber = action.payload.values;
      });
    }
    case SET_FINANCIAL_INFO: {
      return produce(state, draft => {
        draft.personFinancialInfoDto = {
          ...draft.personFinancialInfoDto,
          ...action.payload.values
        };
      });
    }
    case SET_PERSON_FAMILY_INFO: {
      return produce(state, draft => {
        draft.personFamilyInfoDto = {
          ...draft.personFamilyInfoDto,
          ...action.payload.values
        };
      });
    }
    case POST_CAPTURES_INFO: {
      return {
        ...state,
        entityId: action.payload.entityId,
        user: {
          ...state.user,
          email: action.payload.email
        },
        personalDto: {
          ...state.personalDto,
          originatorId: action.payload.originatorId
        },
        identificationDTO: {
          ...state.identificationDTO,
          identificationNumber: action.payload.identificationNumber
        }
      };
    }
    case SET_FORM_CUSTOMER_INFO: {
      return {
        ...state,
        lastRequestDataUpdate: action.payload.lastRequestDataUpdate ?? state.lastRequestDataUpdate,
        entityId: action.payload.entityId ?? state.entityId,
        user: {
          ...state.user,
          ...action.payload
        },
        personalDto: {
          ...state.personalDto,
          ...action.payload.personalDto
        },
        identificationDTO: {
          ...state.identificationDTO,
          ...action.payload.identificationDTO
        },
        professionInfoDto: {
          ...state.professionInfoDto,
          ...action.payload.professionInfoDto
        },
        viewEmploymentInfoDTO: {
          ...state.viewEmploymentInfoDTO,
          ...action.payload.viewEmploymentInfoDTO
        },
        personFamilyInfoDto: {
          ...state.personFamilyInfoDto,
          ...action.payload.personFamilyInfoDto
        },
        personFinancialInfoDto: {
          ...state.personFinancialInfoDto,
          ...action.payload.personFinancialInfoDto
        },
        customerDTO: {
          ...state.customerDTO,
          ...action.payload.customerDTO
        }
      };
    }
    default: {
      return state;
    }
  }
};

export default customerReducer;
