import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config/';

class LoanProductParametersService {
  GetLoanProductParametersByLoanProductId = (loanProductId) => new Promise((resolve, reject) => {
    const AuthStr = axios.defaults.headers.common.Authorization;
    axios.get(`${API_BASE_URL}/api/LoanProductRiskParameters/${loanProductId}`,
      { headers: { Authorization: AuthStr, appClientId: localStorage.getItem('appClientId') } })
      .then((response) => {
        if (response.data) {
          resolve(response.data.data);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  })

  DeleteLoanProductParameter = (id) => new Promise((resolve, reject) => {
    const AuthStr = axios.defaults.headers.common.Authorization;
    axios.delete(`${API_BASE_URL}/api/LoanProductRiskParameters/byId/${id}`, { headers: { Authorization: AuthStr, 'Content-Type': 'application/json', appClientId: localStorage.getItem('appClientId') } })
      .then((response) => {
        if (response.data) {
          resolve(response.data.message);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  })

  CreateOrModifyLoanProductParameter = (loanParameterDetails) => new Promise((resolve, reject) => {
    const data = loanParameterDetails;
    const AuthStr = axios.defaults.headers.common.Authorization;
      axios.post(`${API_BASE_URL}/api/LoanProductRiskParameters/`, data, { headers: { Authorization: AuthStr, 'Content-Type': 'application/json', appClientId: localStorage.getItem('appClientId') } })
        .then((response) => {
          if (response.data) {
            resolve(response.data.message);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
  })
}

const loanProductParametersService = new LoanProductParametersService();

export default loanProductParametersService;
