import React from 'react';
import { OnErrorLogo } from '../utils/urlPath';

function Logo(props) {
  return (
    <img
      alt="Logo"
      height="90px"
      onError={(e) => OnErrorLogo(e)}
      src="/static/logo-grey.svg"
      width="120px"
      {...props}
    />
  );
}

export default Logo;
