import debtCollectionService from 'src/services/debtCollectionService';
import { finishLoading, setMessageSnackbar, startLoading } from './uiActions';

export const SET_FACTOR_RULES = '@debtCollection/set-factor-rules';
export const SET_FACTOR_RULE = '@debtCollection/set-factor-rule';
export const SET_LOANS_TO_FACTOR = '@debtCollection/set-loans-to-factor';
export const SET_DEBT_COLLECTION_MANAGEMENTS =
  '@debtCollection/set-debt-collection-managements';

export const setFactorRules = (data) => ({
  type: SET_FACTOR_RULES,
  payload: data
});

export const setFactorRule = (data) => ({
  type: SET_FACTOR_RULE,
  payload: data
});

export const setLoansToFactor = (data) => ({
  type: SET_LOANS_TO_FACTOR,
  payload: data
});

const setDebtCollectionManagements = (data) => ({
  type: SET_DEBT_COLLECTION_MANAGEMENTS,
  payload: data
});

export const getFactorRules = (filters) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await debtCollectionService.GetFactorRules(filters);
      const factorRulesDTO = {
        isSetted: true,
        factorRules: response?.result?.data?.elements ?? [],
        count: response?.result?.data?.count ?? 0
      };
      dispatch(setFactorRules(factorRulesDTO));
    } catch (e) {
      const factorRulesDTO = {
        isSetted: true,
        factorRules: []
      };
      dispatch(setFactorRules(factorRulesDTO));
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };
};
export const GetFactorRulesByOriginatorId = (originatorId) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await debtCollectionService.GetFactorRulesByOriginatorId(
        originatorId
      );
      const factorRulesDTO = {
        isSetted: true,
        factorRules: response?.processedObject ?? []
      };
      dispatch(setFactorRules(factorRulesDTO));
    } catch (e) {
      const factorRulesDTO = {
        isSetted: true,
        factorRules: []
      };
      dispatch(setFactorRules(factorRulesDTO));
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };
};

export const getFactorRule = (factorRuleId) => {
  return async (dispatch) => {
    dispatch(startLoading());
    dispatch(setFactorRule({ isSetted: false, factorRule: {} }));
    try {
      const response = await debtCollectionService.GetFactorRule(factorRuleId);
      const factorRuleDTO = {
        isSetted: true,
        factorRule: {
          ...response?.data
        }
      };
      dispatch(setFactorRule(factorRuleDTO));
    } catch (e) {
      const factorRuleDTO = {
        isSetted: false,
        factorRule: {}
      };
      dispatch(setFactorRule(factorRuleDTO));
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };
};

export const createUpdateFactorRule = (factorRule, factorRuleId, callback) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      await debtCollectionService
        .CreateFactorRule(factorRule, factorRuleId)
        .then((res) => {
          dispatch(setMessageSnackbar(res.message, 'success'));
        })
        .finally(callback);
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };
};
export const deleteFactorRule = (factorRuleId) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await debtCollectionService.DeleteFactorRule(
        factorRuleId
      );
      dispatch(setMessageSnackbar(response.message, 'success'));
      window.location.reload();
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };
};

// loans to factor
export const getLoansToFactor = (filters, factorRuleId) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await debtCollectionService.GetLoansToFactor(
      filters,
      factorRuleId
    );
    const loansToFactorDTO = {
      isSetted: true,
      loansToFactor: response?.data?.result ?? [],
      count: response?.data?.elements ?? 0
    };
    dispatch(setLoansToFactor(loansToFactorDTO));
  } catch (e) {
    const loansToFactorDTO = {
      isSetted: true,
      loansToFactor: []
    };
    dispatch(setLoansToFactor(loansToFactorDTO));
    dispatch(setMessageSnackbar(e, 'error'));
  } finally {
    dispatch(finishLoading());
  }
};
export const getLoansToFactorByIdentification =
  (identificationNumber) => async (dispatch) => {
    dispatch(startLoading());
    try {
      const response =
        await debtCollectionService.GetLoansToFactorByIdentification(
          identificationNumber
        );
      const loansToFactorDTO = {
        isSetted: true,
        loansToFactor: response?.result?.data?.result ?? []
      };
      dispatch(setLoansToFactor(loansToFactorDTO));
    } catch (e) {
      const loansToFactorDTO = {
        isSetted: true,
        loansToFactor: []
      };
      dispatch(setLoansToFactor(loansToFactorDTO));
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };
// collection management
export const getDebtCollectionManagements = (loanId) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await debtCollectionService.GetDebtCollectionManagements(
        loanId
      );
      const loansDebtCollectionManagementDTO = {
        isSetted: true,
        debtCollectionManagements: response?.processedObject ?? []
      };
      dispatch(setDebtCollectionManagements(loansDebtCollectionManagementDTO));
    } catch (e) {
      const loansDebtCollectionManagementDTO = {
        isSetted: true,
        debtCollectionManagements: []
      };
      dispatch(setDebtCollectionManagements(loansDebtCollectionManagementDTO));
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };
};

export const createDebtCollectionManagement = (debtCollectionManagement) => {
  return async (dispatch) => {
    dispatch(startLoading());

    const loansDebtCollectionManagementDTO = {
      isSetted: false,
      debtCollectionManagements: []
    };
    dispatch(setDebtCollectionManagements(loansDebtCollectionManagementDTO));
    try {
      const debtCollectionManagementDTO = {
        Id: 0,
        Result: debtCollectionManagement.result,
        Observations: debtCollectionManagement.observations,
        LoanId: debtCollectionManagement.loanId,
        PhoneId: debtCollectionManagement.phone,
        AgreementDate:
          debtCollectionManagement.result === 3
            ? debtCollectionManagement.agreementDate
            : null
      };

      const response =
        await debtCollectionService.CreateDebtCollectionManagement(
          debtCollectionManagementDTO
        );
      dispatch(setMessageSnackbar(response, 'success'));
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(getDebtCollectionManagements(debtCollectionManagement.loanId));
      dispatch(finishLoading());
    }
  };
};
