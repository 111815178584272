import customerService from 'src/services/customerService';
import { setLocations } from './locationsActions';
import { startLoading, setMessageSnackbar, finishLoading } from './uiActions';

export const REGIONS = '@customer/Regions-success';
export const REGIONS_FAILURE = '@customer/entity-failure';

export const CREATE_CUSTOMER = '@customer/CreateCustomer-success';
export const CREATE_CUSTOMER_FAILURE = '@customer/CreateCustomer-failure';
export const CREATE_CAPTURES1 = '@customer/CreateCaptures1-success';
export const CREATE_CAPTURES2 = '@customer/CreateCaptures2-success';
export const CREATE_CAPTURES3 = '@customer/CreateCaptures3-success';
export const CREATE_CAPTURES_FAILURE = '@customer/CreateCaptures-failure';
export const CREATE_PERSON = '@customer/CreatePerson-success';
export const CREATE_IDENTIFICATION = '@customer/CreateIdentification-success';
export const CREATE_PROFESSION = '@customer/CreateProfession-success';
export const CREATE_CONTACT = '@customer/CreateAddress-success';
export const CREATE_BANK = '@customer/CreateBank-success';
export const CREATE_PERSONID = '@customer/CreatePersonid-success';
export const CREATE_ENTITYID = '@customer/CreateEntityid-success';
export const EMAIL = '@customer/Email-success';
export const PHONE = '@customer/Phone-success';
export const IDENTIFICATION_NUMBER = '@customer/Identification-success';
export const POST_CAPTURES_INFO = '@customer/Post-captures-info';
export const SET_FORM_CUSTOMER_INFO = '@customer/Set-form-customer-info';
export const SET_FINANCIAL_INFO = '@customer/Set-customer-financial-info';
export const SET_PERSON_FAMILY_INFO = '@customer/Set-customer-family-info';
export const CLEAN_CUSTOMER = '@customer/clean-customer-info';


export const getCustomerDetails = customerId => {
  
  return async dispatch => {
    try {
      dispatch(startLoading());
      const response = await customerService.GetCustomerDetails(customerId);
      dispatch(setCustomerDetails(response.result.data.data));
    }
    catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }

  };
};

export const cleanCustomerDetails = () => {
  return dispatch =>
    dispatch({
      type: CLEAN_CUSTOMER,
    });
}

export const setCustomerDetails = data => {
  const {
    entityId,
    email,
  } = data;
  const {
      name,
      name2,
      lastName,
      lastName2,
      gender,
      dob,
      placeOfBirth,
      regionCode,
      cityCode,
      region,
      city,
      originatorId,
      identificationDTO,
      lastRequestDataUpdate,
      viewEmploymentInfoDTO,
      personFamilyInfoDto,
      personFinancialInfoDto,
      customerDTO,
      professionInfoDto,
  } = data?.personIdentificationDTO || {}
  const payload = {
    // Personal
    email,
    customerDTO,
    personalDto: {
      name,
      name2,
      lastName,
      lastName2,
      gender,
      dob,
      placeOfBirth,
      cityCode,
      regionCode,
      region,
      city
    },
    identificationDTO,
    professionInfoDto,
    // Necesary for the logyc view
    originatorId,
    lastRequestDataUpdate,
    entityId,
    // Work Information
    viewEmploymentInfoDTO,
    personFamilyInfoDto,
    personFinancialInfoDto,
    customerDTO
  };
  return setCustomerInfo(payload);
};

export function setCustomerInfo(data) {
  return dispatch =>
    dispatch({
      type: SET_FORM_CUSTOMER_INFO,
      payload: data
    });
}
export function initentity(region) {
  return dispatch =>
    dispatch({
      type: REGIONS,
      payload: {
        region
      }
    });
}

export function createEmailPhone(values) {
  return dispatch => {
    dispatch({
      type: CREATE_CUSTOMER,
      payload: {
        values
      }
    });
  };
}

export function createPerson(values) {
  return dispatch => {
    dispatch({
      type: CREATE_PERSON,
      payload: {
        values
      }
    });
  };
}

export function Email(values) {
  return dispatch => {
    dispatch({
      type: EMAIL,
      payload: {
        values
      }
    });
  };
}

export function IdentificationNumber(values) {
  return dispatch => {
    dispatch({
      type: IDENTIFICATION_NUMBER,
      payload: {
        values
      }
    });
  };
}

export function Phone(values) {
  return dispatch => {
    dispatch({
      type: PHONE,
      payload: {
        values
      }
    });
  };
}

export function createCaptures1(values) {
  return dispatch => {
    dispatch({
      type: CREATE_CAPTURES1,
      payload: {
        values
      }
    });
  };
}

export function createCaptures2(values) {
  return dispatch => {
    dispatch({
      type: CREATE_CAPTURES2,
      payload: {
        values
      }
    });
  };
}

export function createCaptures3(values) {
  return dispatch => {
    dispatch({
      type: CREATE_CAPTURES3,
      payload: {
        values
      }
    });
  };
}

export function createIdentification(values) {
  return dispatch => {
    dispatch({
      type: CREATE_IDENTIFICATION,
      payload: {
        values
      }
    });
  };
}

export function createProfession(values) {
  return dispatch => {
    dispatch({
      type: CREATE_PROFESSION,
      payload: {
        values
      }
    });
  };
}

export function createContact(values) {
  return dispatch => {
    dispatch({
      type: CREATE_CONTACT,
      payload: {
        values
      }
    });
  };
}

export function createBank(values) {
  return dispatch => {
    dispatch({
      type: CREATE_BANK,
      payload: {
        values
      }
    });
  };
}

export function personId(values) {
  return dispatch => {
    dispatch({
      type: CREATE_PERSONID,
      payload: {
        values
      }
    });
  };
}

export function entityId(values) {
  return dispatch => {
    dispatch({
      type: CREATE_ENTITYID,
      payload: {
        values
      }
    });
  };
}

export function Regions() {
  return async dispatch => {
    try {
      dispatch({ type: REGIONS });

      const region = await customerService.GetRegions();
      dispatch({
        type: REGIONS,
        payload: {
          region
        }
      });
    } catch (error) {
      dispatch({ type: REGIONS_FAILURE });
      throw error;
    }
  };
}
export function getAllRegions() {
  return async dispatch => {
    try {
      const region = await customerService.GetRegions();
      dispatch(setLocations("regions", region.result.data));
    } catch (error) {
      throw error;
    }
  };
}
export function getAllCities() {
  return async dispatch => {
    try {
      const city = await customerService.GetCities();
      dispatch(setLocations("cities", city.result.data));
    } catch (error) {
      throw error;
    }
  }
};
export function postCapturesInfo(
  entityId,
  email,
  identificationNumber,
  originatorId
) {
  return {
    type: POST_CAPTURES_INFO,
    payload: {
      entityId,
      email,
      identificationNumber,
      originatorId
    }
  };
}

export function setFinancialInfo(values) {
  return dispatch => {
    dispatch({
      type: SET_FINANCIAL_INFO,
      payload: {
        values
      }
    });
  };
}

export function setPersonFamilyInfo(values) {
  return dispatch => {
    dispatch({
      type: SET_PERSON_FAMILY_INFO,
      payload: {
        values
      }
    });
  };
}
