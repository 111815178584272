import { SET_LIST_ROLES } from '../actions/rolesActions';

const initialState = {
  roles: [],
  count: 0
};

const RolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LIST_ROLES: {
      return { ...state, roles: action.roles, count: action.count };
    }
    default: {
      return state;
    }
  }
};

export default RolesReducer;
