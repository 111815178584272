import moment from 'moment';
import customerService from 'src/services/customerService';
import tasksService from 'src/services/tasksService';
import usersService from 'src/services/usersService';
import { finishLoading, setMessageSnackbar, startLoading } from './uiActions';

export const SET_TASKS = '@task/set-tasks';
export const SET_PENDING_TASKS = '@task/set-pending-tasks';
export const SET_TASK = '@task/set-task';
export const SET_RECURRING_TASK = '@task/set-recurring-task';
export const SET_ASIGNEES = '@task/set-asignees';
export const SET_LOAN_USER_FULLNAME = '@task/set-loan-user-fullname';
export const SET_TASK_SAVED = '@task/set-task-saved';

const setTaskSaved = (data) => ({
  type: SET_TASK_SAVED,
  payload: data
});

const setTasks = (data) => ({
  type: SET_TASKS,
  payload: data
});

const setTask = (data) => ({
  type: SET_TASK,
  payload: data
});

const setRecurringTask = (data) => ({
  type: SET_RECURRING_TASK,
  payload: data
});

export const setTaskSavedCompleted = (completed, taskId) => async (dispatch) => {
  dispatch(setTaskSaved(completed))
  const taskDTO = {
    id: taskId,
    isSetted: false,
    name: '',
    description: '',
    asigneeId: '',
    loanId: '',
    isRecurring: false,
    dueDate: '',
    startDate: ''
  };
  dispatch(setTask(taskDTO));
}

export const getTasks = () => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await tasksService.getTasks();
      dispatch(setTasks(response.data));
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };
};

const setPendingTasks = (data) => ({
  type: SET_PENDING_TASKS,
  payload: data
});

export const getPendingTasks = () => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await tasksService.getPendingTasks();

      const pendingTasksDTO = response?.processed
        ? {
          isSetted: true,
          pendingTasks: response?.processedObject
        }
        : {
          isSetted: true,
          pendingTasks: []
        };
      dispatch(setPendingTasks(pendingTasksDTO));
    } catch (e) {
      const pendingTasksDTO = {
        isSetted: true,
        pendingTasks: []
      };
      dispatch(setPendingTasks(pendingTasksDTO));

      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };
};

export const getTask = (taskId) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await tasksService.getTask(taskId);
      const task = response?.processedObject;
      const taskDTO = {
        id: task?.id,
        isSetted: true,
        name: task?.name ?? '',
        description: task?.description ?? '',
        asigneeId: task?.assigneeId ?? '',
        loanId: task?.loanId ?? '',
        dueDate: task?.dueDate ?? '',
        isRecurring: task?.isRecurring ?? false
      };
      if (task?.isRecurring) {
        const recurringTaskDTO = {
          startDate: task?.recuringTask?.startDate ?? '',
          endDate: task?.recuringTask?.endDate ?? '',
          frequency: task?.recuringTask?.frequency ?? '',
          dayOfWeek: task?.recuringTask?.dayOfWeek ?? '',
          dayOfMonth: task?.recuringTask?.dayOfMonth ?? '',
          repeats: task?.recuringTask?.repeats ?? ''
        };
        dispatch(setRecurringTask(recurringTaskDTO));
      }
      dispatch(setTask(taskDTO));
    } catch (e) {
      const taskDTO = {
        isSetted: true,
        name: '',
        description: '',
        asigneeId: '',
        loanId: '',
        isRecurring: false
      };
      dispatch(setTask(taskDTO));
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };
};



export const createTask = (task, isRecurring, taskId) => {
  return async (dispatch) => {
    dispatch(startLoading());
    const invalidDate = 'Invalid date';
    try {
      const taskDTO = {
        Id: taskId ?? 0,
        Name: task?.name ?? '',
        Description: task?.description ?? '',
        RecurringTaskId: null,
        AssigneeId: task.asignee ?? '00000000-0000-0000-0000-000000000000',
        DueDate:
          moment(task?.dueDate).format('YYYY-MM-DD') !== invalidDate
            ? moment(task?.dueDate).format('YYYY-MM-DD')
            : null,
        IsRecurring: isRecurring ?? false,
        StartDate:
          moment(task?.startDate).format('YYYY-MM-DD') !== invalidDate
            ? moment(task?.startDate).format('YYYY-MM-DD')
            : null,
        EndDate:
          moment(task?.endDate).format('YYYY-MM-DD') !== invalidDate
            ? moment(task?.endDate).format('YYYY-MM-DD')
            : null,
        Frequency: task?.frequency ?? '',
        DayOfWeek: task?.dayOfWeek ?? null,
        DayOfMonth: task?.dayOfMonth ?? null,
        LoanId: task?.loanId ? task?.loanId : null,
        Repeats: task.repeats
      };

      const response = await tasksService.updateTask(taskDTO, taskId);
      dispatch(setMessageSnackbar(response?.message, 'success'));
      dispatch(setTaskSavedCompleted(true));
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };
};

const setAsignees = (data) => ({
  type: SET_ASIGNEES,
  payload: data
});

export const getAsignees = () => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await usersService.getAdministrativeUsers();
      const asigneesDTO = {
        isSetted: true,
        asignees: response?.processedObject ?? []
      };
      dispatch(setAsignees(asigneesDTO));
    } catch (e) {
      const asigneesDTO = {
        isSetted: true,
        asignees: []
      };
      dispatch(setAsignees(asigneesDTO));
    } finally {
      dispatch(finishLoading());
    }
  };
};

const setLoanUserFullName = (data) => ({
  type: SET_LOAN_USER_FULLNAME,
  payload: data
});

export const getLoanUserFullName = (loanId, setTaskLoanId) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await customerService.getCustomerNameByLoanId(loanId);
      if (!response?.processedObject) setTaskLoanId('');
      dispatch(setLoanUserFullName(response?.processedObject ?? ''));
    } catch (e) {
      dispatch(setMessageSnackbar(e, 'error'));
    } finally {
      dispatch(finishLoading());
    }
  };
};


export const addSingleTaskAttachments = (task) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const taskAttachmentDTO = new FormData();
    taskAttachmentDTO.append('Comments', task.comments);
    taskAttachmentDTO.append('TaskId', task.taskId);

    if (task.files?.length > 0) {
      task.files.forEach((file) => {
        taskAttachmentDTO.append('files', file);
        taskAttachmentDTO.append('PathFile', file.path);
      });
    }
    await tasksService.singleTaskAttachments(taskAttachmentDTO);

  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
  } finally {
    dispatch(finishLoading());
  }
};

export const completeTask = (taskId, comments) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const completeTaskDTO = {
      Comments: comments,
      TaskId: taskId,
    }
    const response = await tasksService.completeTask(completeTaskDTO);
    dispatch(setMessageSnackbar(response.message, 'success'));
    dispatch(setTaskSavedCompleted(true))
    dispatch(getPendingTasks());
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
  } finally {
    dispatch(finishLoading());
  }
};

export const assignTasks = (tasks, assigneeId) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const unassignedTasksDTO = {
      UnassignedSingleTasks: tasks,
      AssigneeId: assigneeId,
    }
    const response = await tasksService.assignSingleTasks(unassignedTasksDTO);
    dispatch(setMessageSnackbar(response?.message, 'success'));
  } catch (e) {
    dispatch(setMessageSnackbar(e, 'error'));
  } finally {
    dispatch(finishLoading());
  }
};
