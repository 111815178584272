export const customerEditEn = {
  accountNotesReceivable: 'Receivable Accounts and Notes',
  acquisitionOfFornitureAndFixures: 'Acquisition of Forniture and Fixures',
  acquisitionOfMachineryAndEquipment: 'Acquisition of Machinery and Equipment',
  add: 'Add',
  addContact: 'Add Contact',
  addCreditCard: 'Add Credit Card',
  addDebt: 'Add Debt',
  addInformation: 'Add Information',
  additionalInformation: 'Additional Information',
  addNewContact: 'Add New Contact',
  addNewOwner: 'Add New Owner',
  addOriginator: 'Add Originator',
  addOutstandingLoan: 'Add Outstanding Loan',
  addOwner: 'Add Owner',
  address: 'Address',
  addSource: 'Add Source',
  addSourceIncome: 'Add Source Income',
  addStock: 'Add Stock',
  addUnpaidTaxes: 'Add Unpaid Taxes',
  againstWhom: 'Against Whom',
  amount: 'Amount',
  annualSalary: 'Annual Salary',
  anyAffiliatesHaveRelationship:
    'Do any affiliates have a relationship with any employee or board member?',
  anyOutstandingPersonalInstallmentLoan:
    'Any outstanding personal installment loan',
  appartmentUnit: 'Appartment / Unit',
  asset: 'Asset',
  assets: 'Assets',
  assetsTitle: 'Assets',
  assignNewOriginator: 'Assign new originator to borrower',
  autoLease: 'Do you have an auto lease',
  autoLoan: 'Do you have an auto loan',
  automobile: 'Automobile',
  automobileNotFound: 'Automobile not found',
  automobiles: 'Automobiles',
  bankruptcyAgainstBusiness:
    'Have the business or the principals ever filed for bankruptcy?',
  businessAquisitionPartnerBuyOut: 'Business Aquisition / Partner Buy-out',
  businessDebts: 'Business Debts',
  businessName: 'Business Name',
  bussinessName: 'Business Name',
  cashHandBanks: 'Cash in Banks',
  'Cédula de Ciudadanía': 'Citizenship Card',
  'Cédula de Extranjería': 'Foreigner ID',
  cellPhone: 'Cell Phone',
  citizenshipCard: 'Citizenship Card',
  city: 'City',
  collateral: 'Collateral',
  collectPerMonth: 'How much do you collect per month?',
  company: 'Company',
  companyInformation: 'Company Information',
  companyName: 'Company Name',
  contactPerson: 'Contact Person',
  contacts: 'Contacts',
  contactsInformation: 'Contact Information',
  country: 'Country',
  createCompany: 'Create Company',
  createPerson: 'Register New Borrower',
  creditCard: 'Credit Card',
  creditCardBalances: 'Credit Card Balances',
  creditCardNotFount: 'Credit Card not found',
  creditCardsSuccessfully: 'Credit cards were update successfully',
  creditorName: 'Creditor Name',
  currentBalance: 'Current Balance',
  dateStablished: 'Date Established',
  debt: 'Debt',
  debtRefinance: 'Debt Refinance',
  debts: 'Debts',
  deleteAutomobile: 'Delete Automobile',
  deleteContact: 'Delete Contact',
  deleteCreditCardBalance: 'Delete credit card balance',
  deleteOtherAsset: 'Delete another asset',
  deleteOtherLiability: 'Delete other liability',
  deleteOutstandingPersonalLoan: 'Delete outstanding personal loan',
  deleteOwner: 'Delete Owner',
  deleteProperty: 'Delete Property',
  deleteUnpaidTax: 'Delete unpaid tax',
  describeInDetails: 'Describe in Details',
  'Discounted prices': 'Discounted Prices',
  'Discounted prices description':
    'This will give the user discountedprices for all products',
  edit: 'Edit',
  editCompany: 'Edit Company',
  email: 'Email',
  'Email verified': 'Email verified',
  'Email verified description':
    'Disabling this will automatically send the user a verification email',
  emailRepeat: 'please, no repeated email addresses.',
  employeeInformation: 'Employee Information',
  employees: 'Employees',
  employerName: 'Employer Name',
  entityType: 'Entity Type',
  financialInformation: 'Financial Information',
  formatDate: 'Format required YYYY-MM-DD',
  generalInformation: 'General Information',
  greaterThanZero: 'Must be greater than 0.01',
  Guarantor: 'Guarantor',
  guarantorAssetsLifeInsuranceUpdateSuccessfully:
    'Guarantor assets life insurance update successfully.',
  guarantorLabel: 'Guarantor',
  guarantorNotFound: 'Guarantor not found',
  haveRealEstatePropertyRentalIncomeFrom:
    'Have a real estate property that you collect rental income from?',
  howMuchCollectMont: 'How much do you collect per month?',
  identificationNumber: 'Identification Number',
  identificationNumberRepeat: 'please, no repeat identification numbers.',
  identificationType: 'Identification Type',
  informationSaved: 'Information saved',
  infoUpdated: 'Updated Information',
  interestRate: 'Interest Rate',
  invalidFormat: 'Invalid format',
  invalidIdentification: 'Invalid Identification',
  inventoryPurchase: 'Purchase of Inventory',
  item: 'Item',
  lawsuitsAgainstBusiness:
    'Are there any lawsuits pending against the business or the principals?',
  leaseholdImprovements: 'Leasehold Improvements',
  leaseMonthlyPayment: 'Monthly Payment',
  leaseRemainingMonths: 'Number of months remaining in the lease',
  legalQuestions: 'Legal Questions',
  lenderName: 'Name of Lender',
  lessorName: 'Name of Lessor',
  lien: 'Lien',
  liensOrJudgementsAgainstBusiness:
    'Are there any liens or judgments outstanding against the business or the principals?',
  lifeInsurence: 'Life Insurance',
  listAnyBusinessOwnedBy: 'Other business owned by',
  listBusinessOwned: 'List any other business owned',
  loanCurrentBalance: 'Current Balance',
  loanCurrentBalanceOnLoan: 'Current Balance on Loan',
  loanMonthlyPayment: 'Monthly Payment',
  loanSecuredByThisAssets: 'Do you have a loan secured by this assets?',
  loanSecuredLifeInsurancePolicy:
    'Have a loan secured by this life insurance policy?',
  make: 'Make',
  maturityDate: 'Maturity Date',
  maximumHundred: 'The maximum value is 100',
  model: 'Model',
  monthlyPayment: 'Monthly Payment',
  monthlyRent: ' Monthly Rent / Mortgage',
  name: 'Name',
  nameBankOrCompany: 'Name Bank or Company',
  nameGuarantor: 'Name of Guarantor',
  nameInsuranceCompany: 'Name of Insurance Policy',
  nameOfOwner: 'Name of Owner',
  natureOfBussines: 'Nature of Business',
  no: 'No',
  noContactsToShow: 'No contacts to show',
  None: 'None',
  notBusinessFound: 'Guarantor business not exists',
  numberLivedAddress: 'Number of Years Lived at the Address',
  numberOfCurrentEmployees: 'Number of Current Full-time Employees',
  numberOfYearsOperating: 'Number of Years Operating',
  onlyAlphabetic: 'Alphabetic characters only',
  onlyAlphanumeric: 'Alphanumeric characters only',
  onlyNumbers: 'Numbers only',
  originalAmount: 'Original Amount',
  originalDate: 'Original Date',
  originator: 'Originator',
  originatorsPerCustomer: 'Originators per Borrower',
  other: 'Other',
  otherAssetNotFound: 'Other asset not found',
  otherAssets: 'Other Assets',
  otherLiabilities: 'Other Liabilities',
  otherLiabilitiesSuccessfully: 'Other liabilities were update successfully',
  otherLiabilityNotFount: 'Other liability not found',
  otherRetirementAccount: 'Retirement Account',
  outstandingLoans: 'Outstanding Loans',
  outstandingLoansSuccessfully: 'Out standing Loans were update successfully',
  outstangindLoanNotFount: 'Outstanding personal loan not found',
  ownerNotExists: 'There is no owner with that identification.',
  // Owners
  owners: 'Owners',
  ownership: 'Ownership',
  ownerType: 'Type of Owner',
  ownLifeInsurancePolicy: 'Own a life insurance policy?',
  passport: 'Passport',
  paymentAmount: 'Payment Amount',
  percentageOfOwnership: 'Percentage of Ownership',
  'Personal information was successfully saved':
    'Personal information was successfully saved',
  personalInformation: 'Personal Information',
  personalInstallmentLoan: 'Any outstanding personal installments loan',
  phone: 'Phone',
  phoneNumbersRepeat: 'please, no repeat phone numbers.',
  pledgeCollateral: 'Pledge Collateral',
  policyCashSurrender: 'This policy have a cash surrender?',
  presentBalance: 'Present Balance',
  presentValue: 'Present Value',
  projectNumberNewEmployees:
    'Projected number of new employees as a result of this loan',
  property: 'Property',
  propertyNotFound: 'Property not found',
  realStateProperty:
    'Have a real estate property that you collect rental income from ?',
  region: 'State',
  relatedPartyBusinessDelete: 'Guarantor business was deleted',
  relatedPartyGeneralLiabilitiesSuccessfully:
    'General liabilities were update successfully',
  relatedPartyNotFound: 'Guarantor not found',
  required: 'Required',
  residenceAddress: 'Residence Address',
  save: 'Save',
  savingsAccounts: 'Savings Accounts',
  searchById:
    'The ID search must start with a number and cannot have letters or spaces between numbers.',
  searchResult: 'Search by fisrtname and lastname',
  shareholder: 'Shareholder',
  singleTaxRegister: 'Single Tax Register',
  sourceIncome: 'Source Income',
  sourceIncomeLabel: 'Source Income',
  sourceIncomeUpdated: 'Sources income updated',
  'State/Region': 'State/Region',
  stockBondNotFound: 'Stock bond not found',
  subisidiaresLegalText:
    'Generally, affiliation exists when one business controls or has the power to control another or when a third'
    + ' party(or parties)controls or has the power to control both businesses. Control may arise through ownership, managment, or other relationships or interactions between the parties',
  successUpdateContact:
    'Modification of the contact information was done successfully.',
  sumMaximumHundred:
    'the sum of the percentage of ownership of all the owners must not exceed 100%.',
  sureRemoveAutomobile: 'Are you sure you want to remove automobile?',
  sureRemoveContact: 'Are you sure you want to remove contact?',
  sureRemoveCreditCardBalance:
    'Are you sure you want to delete credit card balance?',
  sureRemoveOtherAsset: 'Are you sure you want to delete another asset?',
  sureRemoveOtherLiability: 'Are you sure you want to delete other liability?',
  sureRemoveOutstandingPersonalLoan:
    'Are you sure you want to delete outstanding personal loan?',
  sureRemoveOwner: 'Are you sure you want to remove owner?',
  sureRemoveProperty: 'Are you sure you want to delete property?',
  sureRemoveUnpaidTax: 'Are you sure you want to delete unpaid tax?',
  'Taxpayer Identification Number': 'Taxpayer Identification Number',
  theCustomerHasNotBeenFound: 'The borrower has not been found.',
  thereAreSubisidiaries:
    'Does the company have any subsidiaries or affiliates?',
  timeLived: 'How many years have you lived at this address',
  title: 'Title',
  tooLong: 'Too long',
  total: 'Total',
  totalAssets: 'Total Assets',
  totalAutomobileValue: 'Total Automobile Value',
  totalCreditCards: 'Total Credit Cards',
  totalLiabilities: 'Total Liabilities',
  totalLifeInsurance: 'Total Life Insurance',
  totalMonthlyPayment: 'Total Monthly Payment',
  totalOutstandingLoans: 'Total Outstanding Loans',
  totalOwnerShipPercentExceedMaxValue:
    'The total percentage of owners exceeds the maximum value',
  totalPropertyValue: 'Total Property Value',
  totalUnpaidTaxes: 'Total Unpaid Taxes',
  totalValueLifeInsurance: 'Total Value of Life Insurance',
  totalValueStocksBonds: 'Total Value of Stocks and Bonds',
  typeOfAsset: 'Type of Asset',
  typeOfBussines: 'Type of Business',
  typeOfCollateral: 'Type of Collateral',
  unpaidTaxes: 'Unpaid Taxes',
  unpaidTaxesSuccessfully: 'Unpaid taxes were update successfully',
  unpaidTaxNotFount: 'Unpaid tax not found',
  updateCustomer: 'Update borrower',
  useOfLoanProceeds: 'Use of Loan Proceeds',
  value: 'Value',
  warning: 'warning',
  website: 'Website',
  whatCurrenceBalance: 'What is the current balance?',
  whatFaceValueAmount: 'What is the face value amount?',
  whatMounthlyPayment: 'What is the monthly payment?',
  whatPolicyCashSurrender: 'What is the policy have a cash surrender?',
  whatWasTheOutcome: 'What was the outcome',
  whoBeneficiaries: 'Who is/are the beneficiaries?',
  whomPayable: 'To Whom Payable',
  workingCapital: 'Working Principal',
  workPhone: 'Work Phone',
  xxx: '% of Ownership',
  xxxx: 'Who Owns 20% or More of the Company',
  year: 'Year',
  yes: 'Yes',
  zipCode: 'Zip Code',
  entityWasNotFound: 'The entity was not found.',
  notHavePermissionToUpdate:
    'You are not allowed to update your personal information at this time. Please contact us.',
  noNewDataWereSentUpdating: 'No new data were sent for updating.',
  problemsSavingTheDocumentCapture:
    'Problems saving the document capture, try again or please contact us.',
  problemsSavingTheDocumentCaptureBackSide:
    'Problems saving the document capture, try again or please contact us.',
  problemsSavingTheSelfieCapture:
    'Problems saving the selfie capture, try again or Please contact us.',
  updatedImages: 'Updated Images',
  updatedOriginator: 'Updated originator',
  deleteCustomerOriginatorSuccessfully:
    'The originator was successfully removed from the client.',
  deleteCustomerOriginatorError:
    'Originator could not be removed from the client',
  originatorIsNotValid: 'Originator is not valid',
  customerSuccessfullyAddedStore:
    'The customer has been successfully added to the store',
};

export const customerEditEs = {
  accountNotesReceivable: 'Cuentas y documentos por cobrar',
  acquisitionOfFornitureAndFixures: 'Adquisición de muebles y equipos',
  acquisitionOfMachineryAndEquipment: 'Adquisición de maquinaria y equipos',
  add: 'Añadir',
  addContact: 'Añadir contacto',
  addCreditCard: 'Añadir tarjeta de crédito',
  addDebt: 'Añadir deuda',
  addInformation: 'Añadir información',
  additionalInformation: 'Información adicional',
  addNewContact: 'Agregar nuevo contacto',
  addNewOwner: 'Agregar nuevo propietario',
  addOriginator: 'Añadir originador',
  addOutstandingLoan: 'Añadir préstamo pendiente',
  addOwner: 'Añadir propietario',
  address: 'Dirección',
  addSourceIncome: 'Añadir ingresos de fuente',
  addStock: 'Añadir Existencia',
  addUnpaidTaxes: 'Agregar impuestos no pagados',
  againstWhom: 'Contra quién',
  amount: 'Monto',
  annualSalary: 'Salario anual',
  anyAffiliatesHaveRelationship:
    '¿Algun afiliado tiene una relación con cualquier empleado o miembro de la junta?',
  anyOutstandingPersonalInstallmentLoan:
    'Cualquier préstamo personal a plazos pendiente',
  appartmentUnit: 'Apartamento / Unidad',
  asset: 'Activo',
  assets: 'Activos',
  assetsTitle: 'Activos',
  assignNewOriginator: 'Asignar nuevo originador al cliente',
  autoLease: 'Tiene un contrato de arrendamiento de automóviles',
  autoLoan: 'Tiene un préstamo de coche',
  automobile: 'Automóvil',
  automobileNotFound: 'Automóvil no encontrado',
  automobiles: 'Automóviles',
  bankruptcyAgainstBusiness:
    '¿El negocio o los directores han estado alguna vez en bancarrota?',
  businessAquisitionPartnerBuyOut: 'Adquisición de empresas / Compra de socios',
  businessDebts: 'Deudas comerciales',
  businessName: 'Nombre del Negocio',
  bussinessName: 'Nombre de la empresa',
  cashHandBanks: 'Efectivo en bancos',
  'Cédula de Ciudadanía': 'Cédula de Ciudadanía',
  'Cédula de Extranjería': 'Cédula de Extranjería',
  cellPhone: 'Teléfono celular',
  citizenshipCard: 'Tarjeta de ciudadanía',
  city: 'Ciudad',
  collateral: 'Garantía',
  collectPerMonth: '¿Cuánto cobra al mes?',
  company: 'Empresa',
  companyInformation: 'Información de la empresa',
  companyName: 'Nombre de la empresa',
  contactPerson: 'Persona de contacto',
  contacts: 'Contactos',
  // Contacts
  contactsInformation: 'Información de contacto',
  country: 'País',
  createCompany: 'Crear empresa',
  createPerson: 'Registrar Nuevo Cliente',
  creditCard: 'Tarjeta de crédito',
  creditCardBalances: 'Saldos de tarjetas de crédito',
  creditCardNotFount: 'Tarjeta de crédito no encontrada',
  creditCardsSuccessfully:
    'Las tarjetas de crédito se han actualizado con éxito',
  creditorName: 'Nombre del acreedor',
  currentBalance: 'Saldo actual',
  dateStablished: 'Fecha de creación',
  debt: 'Deuda',
  debtRefinance: 'Refinanciación de la deuda',
  debts: 'Deudas',
  deleteAutomobile: 'Eliminar automóvil',
  deleteContact: 'Eliminar contacto',
  deleteCreditCardBalance: 'Eliminar tarjeta de crédito',
  deleteOtherAsset: 'Eliminar otro activo',
  deleteOtherLiability: 'Eliminar otro pasivo',
  deleteOutstandingPersonalLoan: 'Eliminar el préstamo personal pendiente',
  deleteOwner: 'Eliminar dueño',
  deleteProperty: 'Eliminar propiedad',
  deleteUnpaidTax: 'Eliminar impuesto no pagado',
  describeInDetails: 'Describe en detalle',
  'Discounted prices': 'Descuentos',
  'Discounted prices description':
    'Esta opción aplicaría descuentos en todos los productos',
  edit: 'Editar',
  editCompany: 'Editar empresa',
  email: 'Correo electrónico',
  'Email verified': 'Correo verificado',
  'Email verified description':
    'Al deshabilitar será enviado automáticamente un correo de verificación',
  emailRepeat: 'Lo sentimos, no pueden haber direcciones de correo repetidos.',
  employeeInformation: 'Información de los empleados',
  employees: 'Empleados',
  employerName: 'Nombre del empleador',
  entityType: 'Tipo de entidad',
  financialInformation: 'Información financiera',
  formatDate: 'Formato requerido YYYY-MM-DD',
  generalInformation: 'Información general',
  greaterThanZero: 'Debe ser mayor de 0.01',
  Guarantor: 'Guarantor',
  guarantorAssetsLifeInsuranceUpdateSuccessfully:
    'El seguro de vida de los activos del garante se actualizó correctamente.',
  guarantorLabel: 'Garante',
  guarantorNotFound: 'Garante no encontrado',
  haveRealEstatePropertyRentalIncomeFrom:
    '¿Tiene una propiedad inmobiliaria de la que obtiene ingresos por alquiler?',
  howMuchCollectMont: '¿Cuánto recauda al mes?',
  identificationNumber: 'Número de identificación',
  identificationNumberRepeat:
    'Lo sentimos, no pueden haber números de identificación repetidos.',
  identificationType: 'Tipo de identificación',
  informationSaved: 'Información guardada',
  infoUpdated: 'Informacion actualizada',
  interestRate: 'Tipo de interés',
  invalidFormat: 'Formato no válido',
  invalidIdentification: 'Identificación inválida',
  inventoryPurchase: 'Compra de inventario',
  item: 'Artículo',
  lawsuitsAgainstBusiness:
    '¿Tiene demandas pendientes contra la empresa o los directores?',
  leaseholdImprovements: 'Mejoras en el arrendamiento',
  leaseMonthlyPayment: 'Pago mensual',
  leaseRemainingMonths: 'Número de meses restantes de arrendamiento',
  legalQuestions: 'Preguntas legales',
  lenderName: 'Nombre del prestamista',
  lessorName: 'Nombre del arrendador',
  lien: 'Derecho de retencion',
  liensOrJudgementsAgainstBusiness:
    '¿Hay embargos o juicios pendientes contra la empresa o los directores?',
  lifeInsurence: 'Seguro de vida',
  listAnyBusinessOwnedBy: 'Negocios propiedad de',
  listBusinessOwned: 'Enumere cualquier negocio propiedad',
  loanCurrentBalance: 'Saldo actual',
  loanCurrentBalanceOnLoan: 'Saldo actual del prestamo',
  loanMonthlyPayment: 'Pago mensual',
  loanSecuredByThisAssets: '¿tiene un préstamo garantizado por estos bienes?',
  loanSecuredLifeInsurancePolicy:
    '¿Tiene un préstamo garantizado por esta póliza de seguro de vida?',
  make: 'Marca',
  maturityDate: 'Fecha de vencimiento',
  maximumHundred: 'El maximo valor es 100',
  model: 'Modelo',
  monthlyPayment: 'Pago mensual',
  monthlyRent: 'Alquiler mensual / cuota del crédito',
  name: 'Nombre',
  nameBankOrCompany: 'Nombre del banco o de la empresa',
  nameGuarantor: 'Nombre del garante',
  nameInsuranceCompany: 'Nombre de la póliza de seguro',
  nameOfOwner: 'Nombre del propietario',
  natureOfBussines: 'Naturaleza del negocio',
  no: 'No',
  noContactsToShow: 'Sin contactos para mostrar',
  None: 'Ninguno',
  not: 'No',
  notBusinessFound: 'No existe empresa relacionada',
  numberLivedAddress: 'Número de años vividos en la dirección',
  numberOfCurrentEmployees: 'Número de empleados actuales a tiempo completo',
  numberOfYearsOperating: 'Número de años de funcionamiento',
  // Validations
  onlyAlphabetic: 'Solo caracteres alfabeticos',
  onlyAlphanumeric: 'Sólo caracteres alfanuméricos',
  onlyNumbers: 'Solo números',
  originalAmount: 'Importe original',
  originalDate: 'Fecha original',
  originator: 'Originador',
  originatorsPerCustomer: 'Originadores por cliente',
  other: 'Otros',
  otherAssetNotFound: 'Otro activo no encontrado',
  otherAssets: 'Otros Activos',
  otherLiabilities: 'Otros pasivos',
  otherLiabilitiesSuccessfully: 'Otros pasivos fueron actualizados con éxito',
  otherLiabilityNotFount: 'Otro pasivo no encontrado',
  otherRetirementAccount: 'Cuenta de jubilación',
  outstandingLoans: 'Préstamos pendientes',
  outstandingLoansSuccessfully:
    'Los préstamos pendientes se actualizaron con éxito',
  outstangindLoanNotFount: 'Préstamo personal pendiente no encontrado',
  ownerNotExists: 'No existe un propietario con esa identificación.',
  owners: 'Propietarios',
  ownership: 'Propiedad',
  ownerType: 'Tipo de propietario',
  ownLifeInsurancePolicy: '¿Tiene una póliza de seguro de vida?',
  passport: 'Pasaporte',
  paymentAmount: 'Importe del pago',
  percentageOfOwnership: 'Porcentaje de propiedad',
  'Personal information was successfully saved':
    'La información personal se ha guardado correctamente',
  personalInformation: 'Información Personal',
  personalInstallmentLoan: 'Cualquier préstamo personal a plazos pendiente',
  phone: 'Teléfono',
  phoneNumbersRepeat:
    'Lo sentimos, no pueden haber números de teléfono repetidos.',
  pledgeCollateral: 'Garantía prendaria',
  policyCashSurrender: '¿Esta póliza tiene un rescate en efectivo?',
  presentBalance: 'Saldo actual',
  presentValue: 'Valor presente',
  projectNumberNewEmployees:
    'Número proyectado de nuevos empleados como resultado de este préstamo',
  property: 'Propiedad',
  propertyNotFound: 'Propiedad no encontrada',
  realStateProperty:
    '¿Tiene una propiedad inmobiliaria de la que obtiene ingresos por alquiler?',
  region: 'Region',
  relatedPartyBusinessDelete:
    'Se ha eliminado el negocio de la parte relacionada',
  relatedPartyGeneralLiabilitiesSuccessfully:
    'El pasivo general se actualizó con éxito',
  relatedPartyNotFound: 'Parte relacionada no encontrada',
  required: 'Requerido',
  residenceAddress: 'Dirección de residencia',
  save: 'Guardar',
  savingsAccounts: 'Cuentas de ahorro',
  searchById:
    'La búsqueda por ID debe comenzar con número y no puede tener letras o espacios entre números',
  searchResult: 'Buscar por nombre y apellido',
  shareholder: 'Accionista',
  singleTaxRegister: 'Registro fiscal único',
  sourceIncome: 'Origen de ingresos',
  sourceIncomeLabel: 'Origen de ingresos',
  sourceIncomeUpdated: 'Origen de ingresos actualizados',
  'State/Region': 'Región',
  stockBondNotFound: 'Bono/acción no encontrado',
  subisidiaresLegalText:
    'Generalmente, la afiliación existe cuando una empresa controla o tiene el poder de controlar a otra o cuando un tercero '
    + '(o terceros) controla o tiene el poder de controlar ambos negocios.El control puede surgir a través de la propiedad, la administración u otras relaciones o interacciones entre los terceros',
  successUpdateContact:
    'La información de contacto fue modificada correctamente.',
  sumMaximumHundred:
    'La suma del porcentaje de propiedad de todos los propitarios no debe ser mayor a 100%',
  sureRemoveAutomobile: '¿Desea eliminar el automóvil?',
  sureRemoveContact: '¿Desea eliminar el contacto?',
  sureRemoveCreditCardBalance:
    '¿Está seguro de que desea eliminar la tarjeta de crédito?',
  sureRemoveOtherAsset: '¿Seguro de que quiere eliminar otro activo?',
  sureRemoveOtherLiability: '¿Está seguro de que desea eliminar otro pasivo?',
  sureRemoveOutstandingPersonalLoan:
    '¿Seguro que quiere eliminar el préstamo personal pendiente?',
  sureRemoveOwner: '¿Desea eliminar el propietario?',
  sureRemoveProperty: '¿Seguro de que quiere eliminar propiedad?',
  sureRemoveUnpaidTax:
    '¿Está seguro de que desea eliminar los impuestos no pagados?',
  'Taxpayer Identification Number': 'Identificación de Contribuyente',
  theCustomerHasNotBeenFound: 'El cliente no ha sido encontrado.',
  thereAreSubisidiaries: '¿Hay empresas subsidiarias o afiliadas?',
  timeLived: '¿Cuántos años ha vivido en esta dirección?',
  title: 'Título',
  tooLong: 'Demasiado largo',
  total: 'Total',
  totalAssets: 'Activos totales',
  totalAutomobileValue: 'Valor total del automóvil',
  totalCreditCards: 'Total tarjetas de crédito',
  totalLiabilities: 'Total del pasivo',
  totalLifeInsurance: 'Total seguro de vida',
  totalMonthlyPayment: 'Pago mensual total',
  totalOutstandingLoans: 'Total préstamos pendientes',
  totalOwnerShipPercentExceedMaxValue:
    'El porcentaje total de los propietarios excede el valor máximo',
  totalPropertyValue: 'Valor total de propiedades',
  totalUnpaidTaxes: 'Total de impuestos no pagados',
  totalValueLifeInsurance: 'Valor total Seguros de vida',
  totalValueStocksBonds: 'Valor total de acciones y bonos',
  typeOfAsset: 'Tipo de activo',
  typeOfBussines: 'Tipo de empresa',
  typeOfCollateral: 'Tipo de garantía',
  unpaidTaxes: 'Impuestos no pagados',
  unpaidTaxesSuccessfully:
    'Los impuestos no pagados se han actualizado con éxito',
  unpaidTaxNotFount: 'Impuesto no pagado no encontrado',
  updateCustomer: 'Actualizar cliente',
  // Financial information
  useOfLoanProceeds: 'Uso de los ingresos del préstamo',
  value: 'Valor',
  warning: 'Advertencia',
  website: 'Website',
  whatCurrenceBalance: '¿Cuál es el saldo actual?',
  whatFaceValueAmount: '¿Cuál es el valor nominal?',
  whatMounthlyPayment: '¿Cuál es el pago mensual?',
  whatPolicyCashSurrender: '¿Cuál es la póliza de rescate en efectivo?',
  whatWasTheOutcome: 'Cuál fue el resultado',
  whoBeneficiaries: '¿Quiénes son los beneficiarios?',
  whomPayable: 'A quien paga',
  workingCapital: 'Capital de trabajo',
  workPhone: 'Teléfono del trabajo',
  xxx: '% de propiedad',
  xxxx: 'Quién posee el 20% o más de la empresa',
  year: 'Año',
  yes: 'Sí',
  zipCode: 'Código postal',
  entityWasNotFound: 'No se encontró la entidad.',
  notHavePermissionToUpdate:
    'No tienes permitido actualizar tu información personal en estos momentos. Por favor comunícate con nosotros.',
  noNewDataWereSentUpdating: 'No se enviaron nuevos datos para actualizar.',
  problemsSavingTheDocumentCapture:
    'Problemas al guardar la captura del documento, intenta nuevamente o por favor comunícate con nosotros.',
  problemsSavingTheDocumentCaptureBackSide:
    'Problemas al guardar la captura del documento , intenta nuevamente o por favor comunícate con nosotros.',
  problemsSavingTheSelfieCapture:
    'Problemas al guardar la captura, intenta nuevamente o por favor comunícate con nosotros.',
  updatedImages: 'Imagenes actualizadas.',
  updatedOriginator: 'Originador actualizado.',
  deleteCustomerOriginatorSuccessfully:
    'El originador se eliminó correctamente del cliente.',
  deleteCustomerOriginatorError:
    'El originador no se pudo eliminar del cliente.',
  originatorIsNotValid: 'El originador no es válido',
  customerSuccessfullyAddedStore:
    'El cliente se ha agregado correctamente al comercio.',
};
