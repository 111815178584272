export const settingsEn = {
  address: 'Address',
  areYouSure: 'Are your sure you want save changes?',
  baseAddress: 'Base Address (endpoint)',
  cityCode: 'City Code',
  cityName: 'City Name',
  client_id: 'Client ID',
  client_name: 'Client Name',
  clientId: 'Client ID',
  confirmation: 'Confirmation',
  countryCode: 'Country COde',
  currency: 'Currency',
  defaultCustomerType: 'Default borrower type',
  endpointAccessToken: 'Endpoint AccessToken',
  endpointLinkToken: 'Endpoint LinkToken',
  identificationNumber: 'Identification Number',
  identificationType: 'Identification Type',
  initialLoadingInformation: 'Load information at start of view',
  integration: 'Integration',
  integrations: 'Integrations',
  organization: 'Organization',
  organizationName: 'Organization Name',
  organizationSettings: 'Organization Settings',
  plaidParameters: 'Plaid Parameters',
  postalCode: 'Postal Code',
  publicKey: 'Public Key',
  regionCode: 'State Code',
  regionName: 'State Name',
  secret: 'Secret Key',
  secretKey: 'Secret Key',
  settings: 'Settings',
  stripeParameters: 'Stripe Parameters',
  successfull: 'Successful',
  timeZone: 'Time Zone',
  linkCustomerPortal: 'Link to customer portal',
  achqParameters: 'ACHQ Parameters',
  merchantId: 'Merchant ID',
  merchantGate: 'Merchant Gate',
  merchantKey: 'Merchant Key',
  transactMethodUrl: 'Transact URL',
  lastConnection: 'Last Connection',
  takePhoto: 'Take Photo',
};

export const settingsEs = {
  address: 'Dirección',
  areYouSure: 'Está seguro de que quiere guardar los cambios?',
  baseAddress: 'Dirección Base (URL)',
  cityCode: 'Código de Ciudad',
  cityName: 'Ciudad',
  client_id: 'ID del Cliente',
  client_name: 'Nombre del Cliente',
  clientId: 'ID del cliente',
  confirmation: 'Confirmación',
  countryCode: 'Código País',
  currency: 'Moneda',
  defaultCustomerType: 'Tipo de cliente predefinido',
  endpointAccessToken: 'Ruta AccessToken',
  endpointLinkToken: 'Ruta LinkToken',
  identificationNumber: 'Número de Identificación',
  identificationType: 'Tipo de Identificación',
  initialLoadingInformation: 'Cargar información al iniciar vista',
  integration: 'Integración',
  integrations: 'Integraciones',
  organization: 'Organización',
  organizationName: 'Nombre de la Organización',
  organizationSettings: 'Configuracion de la Organización',
  plaidParameters: 'Parametrización Plaid',
  postalCode: 'Código Postal',
  publicKey: 'Clave Pública',
  regionCode: 'Código de Región',
  regionName: 'Región',
  secret: 'Clave Secreta',
  secretKey: 'Clave Secreta',
  settings: 'Configuraciones',
  stripeParameters: 'Parametrización Stripe',
  successfull: 'Proceso exitoso',
  timeZone: 'Zona Horaria',
  linkCustomerPortal: 'Enlace a portal del cliente',
  achqParameters: 'Parametros ACHQ',
  merchantId: 'Merchant ID',
  merchantGate: 'Merchant Gate',
  merchantKey: 'Merchant Key (llave API)',
  transactMethodUrl: 'URL de Transacciones',
  lastConnection: 'Última Conexión',
  takePhoto: 'Tomar Foto',
};
