export const tasksEn = {
  asignee: 'Assignee',
  asigneesLoadedSuccesfuly: 'Asignees loaded successfully',
  assigneeMustBeSelected: 'Assignee must be selected',
  assignTasks: 'Assign',
  biannual: 'Biannual',
  canceled: 'Canceled',
  completeAllTasks: 'Complete All Tasks',
  completed: 'Completed',
  createTask: 'Create Task',
  dayOfMonth: 'Day in Month',
  dayOfWeek: 'Day of Week',
  dueDate: 'Due Date',
  editTask: 'Edit Task',
  frequency: 'Frequency',
  isRecurring: 'Recurring ?',
  loanId: 'Loan Id',
  loanNameLoadedSuccesfuly: 'Loan name loaded successfully',
  loanNotFound: 'Loan not found',
  mustSelectAtLeastOneTasks: 'Must select at least one task',
  noLoan: 'No loan associated',
  overdue: 'Overdue',
  selectAssignee: 'Select Assignee',
  selectDayOfWeek: 'Select Day of the Week',
  selectFrequency: 'Select Frequency',
  singleTasksAssignedSuccessfully: 'Single tasks assigned successfully',
  task: 'Task',
  taskCompletedSuccessfully: 'Task completed successfully.',
  taskCreatedSuccessfully: 'Task created successfully',
  taskUpdateSuccessfully: 'Task updated successfully',
  taskDeletedSuccessfully: 'Task deleted successfuly',
  taskId: 'Task Id',
  taskLoadedSuccesfuly: 'Task loaded successfully',
  taskNotFound: 'Task not found',
  tasks: 'Tasks',
  tasksList: 'Tasks List',
  tasksLoadedSuccesfuly: 'Tasks loaded successfully',
  taskUpdatedSuccessfully: 'Task updated successfully',
  unassignedTasks: 'Unassigned Tasks',
  unassignedTasksList: 'Unassigned Tasks List',
  unique: 'Single',
  verifyLoan: 'Verify Loan',
  viewAllTasks: 'View All Tasks',
};

export const tasksEs = {
  asignee: 'Asignatario',
  asigneesLoadedSuccesfuly: 'Asignatarios cargados correctamente',
  assigneeMustBeSelected: 'El asignatario debe estar seleccionado',
  assignTasks: 'Asignar',
  biannual: 'Bianual',
  canceled: 'Cancelado',
  completeAllTasks: 'Completar todas las tareas',
  completed: 'Completado',
  createTask: 'Crear Tarea',
  dayOfMonth: 'Dia del mes',
  dayOfWeek: 'Dia de la semana',
  dueDate: 'Fecha de vencimiento',
  editTask: 'Editar Tarea',
  frequency: 'Frecuencia',
  isRecurring: '¿Es recurrente?',
  loanId: 'Id de prestamo',
  loanNameLoadedSuccesfuly: 'Nombre del prestamista cargado correctamente',
  loanNotFound: 'Prestamo no encontrado',
  mustSelectAtLeastOneTasks: 'Debe seleccionar almenos una tarea',
  noLoan: 'No hay prestamo asociado',
  overdue: 'Atrasado',
  selectAsignee: 'Seleccione un asignatario',
  selectDayOfWeek: 'Seleccione un dia de la semana',
  selectFrequency: 'Seleccione una frecuencia',
  singleTasksAssignedSuccessfully: 'Tareas individuales asignadas con éxito',
  task: 'Tarea',
  taskCompletedSuccessfully: 'Tarea completada con éxito.',
  taskCreatedSuccessfully: 'Tarea creada correctamente.',
  taskUpdateSuccessfully: 'Tarea actualizada con éxito.',
  taskDeletedSuccessfully: 'Tarea eliminada correctamente.',
  taskId: 'Id de tarea',
  taskLoadedSuccesfuly: 'Tarea cargada correctamente',
  taskNotFound: 'Tarea no encontrada',
  tasks: 'Tareas',
  tasksList: 'Lista de tareas',
  tasksLoadedSuccesfuly: 'Tareas cargadas correctamente',
  taskUpdatedSuccessfully: 'Tarea actualizada correctamente',
  unassignedTasks: 'Tareas sin asignar',
  unassignedTasksList: 'Lista de tareas sin asignar',
  unique: 'Única',
  verifyLoan: 'Verificar préstamo',
  viewAllTasks: 'Ver todas las tareas',
};
