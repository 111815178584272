import axios from 'axios';
import { createHashHistory } from 'history'; // or createBrowserHistory

const instance = axios.create();
const history = createHashHistory();


instance.interceptors.request.use((config) => config,
(error) => {
    // console.log(`interceptor de request Api activado: Error${error}`);
    if (error.response && error.response.status === 401) {
        window.location.href='app/login';
    } else if (error.response && error.response.status === 404) {
        // console.log('Error 404');
    } else if (error.response && error.response.status === 404) {
        // console.log('Error 404');
    } else if (error.response && error.response.status === 400) {
        // console.log('Error 400');
    } else if (error.response && error.response.status === 500) {
        // console.log('Error 500');
    }

    return Promise.reject(error);
});

instance.interceptors.response.use(
(response) => response,
(error) => {
    // console.log(error.toString());
    if (error.toString() === 'Error: Network Error') {
        // console.log('Error: Network Error');
    } else if (error.response && error.response.status === 401) {
        history.push('/app/login');
    } else if (error.response && error.response.status === 404) {
        // console.log('Error 403');        
    } else if (error.response && error.response.status === 404) {
        // console.log('Error 404');
    } else if (error.response && error.response.status === 400) {        
        // console.log('Error 400');
    } else if (error.response && error.response.status === 500) {
        // console.log('Error 500');
    }

    return Promise.reject(error);
}
);

export default instance;