import {
  SET_LIST_CAMPAIGNS,
  SET_SELECTED_CAMPAING
} from '../actions/campaignActions';

const initialState = {
  campaigns: [],
  selectedCampaign: null
};
export default function campaignReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_CAMPAING:
      return {
        ...state,
        selectedCampaign: action.payload
      };
    case SET_LIST_CAMPAIGNS:
      return {
        ...state,
        campaigns: action.payload
      };
    default:
      return state;
  }
}
